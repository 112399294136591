import { useEffect } from 'react';
import { currentUser } from '../../common/utils';
import { FRESHDESK_SHARED_SECRET_KEY } from '../../common/constants';
import jwt from 'jwt-simple';

/* eslint-disable spellcheck/spell-checker */
/*eslint no-undef: 0*/

const FreshDeskWidget = () => {

  useEffect(() => {
    if (!window.fwSettings) window.fwSettings = {};

    window.fwSettings['widget_id'] = 1060000001780;

    const script = document.createElement('script');
    script.src = 'https://ind-widget.freshworks.com/widgets/1060000001780.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    const cleanUpWidget = () => {
      const existingIframe = document.querySelector('#launcher-frame');
      const existingScript = document.querySelector('script[src*="freshworks"]');

      if (existingIframe)
        existingIframe.remove();
      if (existingScript)
        existingScript.remove();
      if (window?.fwSettings)
        delete window.fwSettings?.widget_id;
    };

    const applyCustomStyles = () => {
      const launcherFrame = document.querySelector('#launcher-frame');
      if (launcherFrame) {
        let userId  = currentUser()?.id
        let isLoginOrSignup = window.location.hash === '#/' || window.location.hash === '#/sign-up'
        launcherFrame.style.top = '20px'
        launcherFrame.style.visibility = userId || !isLoginOrSignup ? 'hidden' : 'visible'
        launcherFrame.style.setProperty('left', 'auto', 'important');
        launcherFrame.style.setProperty('right', '1vw', 'important');

        const iframeDoc = launcherFrame.contentDocument || launcherFrame.contentWindow.document;
        const button = iframeDoc?.querySelector('.launcher-button');
        if (button)
          button.addEventListener('click', () => applyWidgetBodyStyles());
      } else
        setTimeout(applyCustomStyles, 30);
    };

    const applyWidgetBodyStyles = () => {
      const widgetBody = document.querySelector('#freshworks-frame-wrapper');
      if (widgetBody) {
          widgetBody.style.position = 'absolute';
          widgetBody.style.top = '70px';
          widgetBody.style.setProperty('left', 'auto', 'important');
          widgetBody.style.setProperty('right', '1vw', 'important');
          widgetBody.style.zIndex = '9999';
      }
      else
        setTimeout(applyWidgetBodyStyles, 20);
    }

    const generateToken = (user) => {
      const expiryAt = Math.floor(Date.now() / 1000) + 7200;
      const payload = {
        name: user?.name,
        email: user?.email,
        exp: expiryAt,
      };
      return jwt.encode(payload, FRESHDESK_SHARED_SECRET_KEY);
    };

    const authenticateCallback = () => {
      let token = generateToken(currentUser());
      FreshworksWidget("authenticate", {
        token: token
      });
    };

    const checkAndAuthenticate = () => {
      if (typeof window.FreshworksWidget === 'function') {
        clearInterval(intervalId);
        let user = currentUser();
        if (user) {
          let token = generateToken(user)
          FreshworksWidget('authenticate', {
            token: token,
            callback: authenticateCallback,
          });
        }
      }
    };

    const intervalId = setInterval(checkAndAuthenticate, 500);

    script.onload = () => {
      applyCustomStyles();
    }

    return () => {
      cleanUpWidget();
      clearInterval(intervalId);
    };
  }, []);
  return null;
};

export default FreshDeskWidget;