import React from 'react';
import alertifyjs from 'alertifyjs'
import { TableVirtuoso } from 'react-virtuoso';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { PRIMARY_COLOR_GREEN } from '../common/constants';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
  get, merge, has, map, orderBy, isEmpty, isArray, isObject, some, isEqual,
  filter, includes, isNumber, isFunction, keys, find, isUndefined, isBoolean, size
} from 'lodash';
import moment from 'moment';
import { EMPTY_VALUE, TIME_DISPLAY_FORMAT } from '../common/constants';
import { connect } from 'react-redux';
import { loaded, forceStopLoader, isLoading, isSearchApplied } from '../actions/main';
import OptionMenu from './OptionMenu';
import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CommonTablePagination from './common/CommonTablePagination';
import {
  isAtGlobalFMs, isAtGlobalOrders, isAtGlobalContracts, isAtFarmFMs, isAtContractFMs,
  isAtOrderListing, isAtGlobalCompanies, isAtGlobalFarms, isGlobalInvoicesPath, isAtGlobalVendorDecs,
  isAtOrderFMs, isAtAnyVendorDecsListing, isAtGlobalTitleTransfer, isAtGlobalCashPrices, isAtStocksSiteLoads,
  toPhoneFormat, toDateTimeFormat, getCountryFormats, getCountryCurrency, formatPrice, isAtGlobalContractBids,
  updatePageCache,
  getPageCache,
  isStaff,
  isObserver,
  isAtContractInvoices,
  isAtOrderInvoices
} from '../common/utils';
import NestedOptionMenu from "./NestedOptionMenu";
import Create from '@mui/icons-material/Create';
import { Switch, TextField } from '@mui/material';
import { AppContext } from './main/LayoutContext';
import Facets from './common/Facets'
import { AppliedFacets } from './common/Facets'
import SearchControls from './SearchControls'
const EDIT = 'edit';

const EntityID = ({column, displayIDColumn, item}) => {
  if (
    (isStaff() || isObserver()) &&
      displayIDColumn &&
      [column.key, column.header].includes(displayIDColumn) &&
      get(item, 'id')
  )
    return (
      <>
        <span
          style={{fontSize: "10px", color: "rgba(0, 0, 0, 0.5)", display: 'flex', textAlign: 'left', justifyContent: 'flex-start', width: '100%'}}
        >
          ID = {get(item, 'id')}
        </span>
      </>
    )

  return <></>
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, hasActions, hideActionLabel } = this.props;
    const columnData = map(this.props.columns, column => ({ id: column.key || column.default, numeric: false, disablePadding: false, label: column.header, className: column.className, isSelectAllCheckboxDisabled: column.isSelectAllCheckboxDisabled, orderBy: column.orderBy, checkbox: column.checkbox, selectAll: column.selectAll, indeterminate: column.indeterminate, checked: column.checked, onSelectAll: column.onSelectAll, sortable: column.sortable || isUndefined(column.sortable), align: column.align, headStyle: column.headStyle || {}, toolTipLabel: column.toolTipLabel || '' }));
    if (hasActions)
      columnData.push({ label: hideActionLabel ? "" : "Action", className: 'xxsmall no-click no-svg', align: 'center' });

    return (
      <TableRow
        sx={{
          '.MuiTableCell-root': {
            padding: '2px 4px'
          },
        }}>
        {
          columnData.map((column, index) => {
            const val = isFunction(column.label) ? column.label() : column.label
            return (
              <TableCell
                className={column.className || ''}
                key={index}
                numeric={column.numeric || undefined}
                padding={column.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === column.id ? order : false}
                sx={{fontSize: '0.8125rem'}}
                align={column.align}
                style={column.headStyle || {}}
              >
                {
                  column.checkbox && column.selectAll ? (
                    <FormControlLabel
                      control={<Checkbox size='small' indeterminate={column.indeterminate} checked={column.checked} onChange={column.onSelectAll} disabled={get(column, 'isSelectAllCheckboxDisabled')} />}
                      label={column.label || "All"}
                      sx={{
                        flexDirection: 'column-reverse',
                        '& .MuiTypography-root': {
                          fontSize: '0.8125rem',
                        },}}
                    />
                  ) : (
                    column.label && column.sortable ?
                      <Tooltip title={column.toolTipLabel || 'Sort'} placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                        <TableSortLabel
                          active={column.orderBy ? column.orderBy === orderBy : orderBy === column.id}
                          direction={isArray(order) ? order[0] : order}
                          onClick={this.createSortHandler(column.orderBy ? column.orderBy : column.id)}
                        >
                          { val }
                        </TableSortLabel>
                      </Tooltip>
                    : !column.sortable ? val :
                      <TableSortLabel
                        active={column.orderBy ? column.orderBy === orderBy : orderBy === column.id}
                        direction={isArray(order) ? order[0] : order}
                      >
                        { val }
                      </TableSortLabel>
                  )
                }
              </TableCell>
            );
          })
        }
      </TableRow>
    );
  }
}

const styles = {
  root: {
    color: PRIMARY_COLOR_GREEN,
    '&$checked': {
      color: PRIMARY_COLOR_GREEN,
    },
  },
  checked: {},
};

class GenericTable extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      urlSearchQuery: null,
      order: this.props.order || 'desc',
      orderBy: this.props.orderBy || 'id',
      items: this.props.items || [],
      allItems: this.props.items || [],
      searchText: '',
      pageSize: 10,
      page: 1,
      menuOpen: null,
      includeVoid: false,
      selectedMenu: {
        item: null,
        index: null,
      },
      checkedItem: null,
      paginationData: null,
      setMountedPaginatedData: true,
      totalCount: 0,
      firstDefaultPageLink: undefined,
      nextPageUrl: undefined,
      previousPageUrl: undefined,
      lastPageUrl: undefined,
      defaultPaginatedData: undefined,
      textOpen: false,
      index: undefined,
      value: undefined,
      key: undefined,
      editComponent: undefined,
      editComponentItem: undefined,
      editComponentUpdateDataMethod: undefined,
    };
    this.wrapperRef = React.createRef();
    this.handleCellClick = this.handleCellClick.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.search = this.search.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.setSearchText = this.setSearchText.bind(this);
    this.onChangePageSize = this.onChangePageSize.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.getPageUrl = this.getPageUrl.bind(this);
    this.handleSearchEnterKeyPress = this.handleSearchEnterKeyPress.bind(this);
    this.getTextField = this.getTextField.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);

    if (this.props.scrollToTopOnUpdate) this.wrapperRef.current.scrollIntoViewIfNeeded();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.getPageOrderBy() === property && this.getPageOrder() === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy }, () => {
      this.setURLWithQueryParams();
      if (this.getPageOrderBy() !== get(this.state.paginationData, 'orderBy') || this.getPageOrder() !== get(this.state.paginationData, 'order')) {
        this.getSorting();
      }
    });
  };

  getURLSearchQuery() {
    return new URLSearchParams(window.location.hash.split('?')[1]);
  }

  getURLSearchQueryString() {
    return this.getURLSearchQuery().toString();
  }

  componentDidMount() {
    const urlSearchQueryString = this.getURLSearchQueryString();
    this.setState(
      {
        urlSearchQuery: urlSearchQueryString,
        paginationData: this.props.paginationData,
        defaultPaginatedData: this.props.paginationData,
      },
      () => {
        if (urlSearchQueryString) this.searchBasedOnURLParameters();
      },
    );
  }

  searchBasedOnURLParameters() {
    if (this.props.globalSearch && this.state.urlSearchQuery) {
      const queryParams = new URLSearchParams(this.state.urlSearchQuery);
      const searchText = queryParams.get('q') || '';
      const orderBy = queryParams.get('orderBy') || 'name';
      const order = queryParams.get('order') || 'desc';
      const pageSize = parseInt(queryParams.get('pageSize') || '10');
      const page = parseInt(queryParams.get('page') || '1');
      let includeVoid = false
      if(queryParams.get('include_void'))
        includeVoid = queryParams.get('include_void') === true || queryParams.get('include_void') === 'true';
      if (searchText || pageSize || page || orderBy)
        this.setState(
          {
            searchText: searchText.trim(),
            pageSize: pageSize,
            page: page,
            orderBy: orderBy,
            order: order,
            includeVoid: includeVoid
          },
          this.search,
        );
      else if (!queryParams.toString()) {
        this.setState({ searchText: '' }, this.search);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search && !this.props.location.search && this.shouldDoHardClear())
      this.clearSearch();
    if (!isEqual(this.props.paginationData, this.state.paginationData) && !isEmpty(this.props.paginationData)) {
      this.setState({
        paginationData: this.props.paginationData,
        items: get(this.props, 'paginationData.results'),
        pageSize: get(this.props.paginationData, 'pageSize'),
        firstDefaultPageLink: get(this.props.paginationData, 'links.first', this.state.firstDefaultPageLink),
      });
    }
    if (this.props.paginationData && this.props.paginationData.count !== null && this.props.paginationData.count !== this.state.totalCount) {
      this.setState({ totalCount: this.props.paginationData.count });
    }
    if (!this.state.defaultPaginatedData && this.props.paginationData) {
      this.setState({ defaultPaginatedData: this.props.paginationData });
    }
    if (!this.state.firstDefaultPageLink && get(this.props.paginationData, 'links.first')) {
      this.setState({ firstDefaultPageLink: get(this.props.paginationData, 'links.first') });
    }
    if (this.props.items && (!isArray(this.props.items) || some(this.props.items, item => item && !isObject(item)))) {
      setTimeout(() => window.location.reload(), 1000)
    }

    if (this.props.newItems) {
      if (!isEmpty(this.state.searchText)) {
        this.setState({ searchText: null });
      }
    }
    if (isEmpty(this.state.searchText) && !isEqual(this.state.allItems, this.props.items)) {
      this.setState({ allItems: this.props.items });
    }
    if (isEmpty(this.state.searchText) && !isEqual(this.state.items, this.state.allItems)) {
      this.setState({ items: this.state.allItems });
    }
    if (
      this.props.isLoading &&
        !isEmpty(this.state.items) &&
        !includes(['contractDetail', 'editFreightOrderReview', 'orderDetail', 'movementDetail', 'companyStocks'], this.props.waitForComponent)
    ) {
      this.props.dispatch(loaded());
    }
    if (this.props.isLoading && isEmpty(this.state.items) && this.props.triggeredForceStopLoader) {
      this.props.dispatch(forceStopLoader());
    }
  }

  componentWillUnmount() {
    this.setState({ items: [], allItems: [], searchText: '' });
  }

  getGlobalSearchResults(searchText) {
    this.props.dispatch(isLoading('searchedResults'));
    const url = this.getURLForSearchSort(searchText)
    this.props.navigateTo(url, get(this.props, 'match.params'));
    if(this.props.getFacets) {
      this.props.getFacets(url)
    }
  }

  getURLForSearchSort = searchText => {
    if ((searchText && searchText.length > 2) || !searchText) this.props.dispatch(isLoading('searchResults'));
    let url = this.getSearchSortUrl(
      this.state.pageSize || 10,
      this.state.page || 1,
    );
    if (this.state.firstDefaultPageLink && !get(this.props, 'removeContractFromSearch', false)) {
      const queryParams = new URLSearchParams(this.state.firstDefaultPageLink.split('?')[1]);
      queryParams.delete('page');
      queryParams.delete('page_size');
      queryParams.delete('order_by');
      queryParams.delete('order');
      queryParams.delete('search');
      queryParams.delete('type_id');
      queryParams.delete('include_void');
      url += '&' + queryParams.toString();
    }

    return url
  }

  applyFacets = () => {
    if(isEmpty(this.props.appliedFacets))
      this.clearFacets(true)
    else {
      const url = this.getURLForSearchSort(this.sanitizeSearchText())
      this.props.onFacetApply(url, true)
    }
  }

  clearFacets = (force=false) => {
    if(force || isEmpty(this.props.appliedFacets))
      this.props.onFacetClear(this.getURLForSearchSort(this.sanitizeSearchText()), force, true)
    else
      alertifyjs.confirm(
        'Clear Filters',
        'Are you sure you want to remove all filters?',
        () => {
          this.props.onFacetClear(this.getURLForSearchSort(this.sanitizeSearchText()), force, true)
        },
        () => {}
      )
  }

  getSearchSortUrl(pageSize, page) {
    pageSize = pageSize || this.state.paginationData?.pageSize || 10
    page = page || ((this.state.paginationData?.page || 0) + 1)
    const searchText = encodeURIComponent(this.state.searchText)
    if(this.props.getSearchSortUrl)
      return this.props.getSearchSortUrl(
        pageSize,
        page,
        searchText,
        this.getPageOrderBy(),
        this.getPageOrder(),
        this.props.location.pathname,
        this.state.includeVoid
      );

    let url = this.getPageUrl(this.state.paginationData.page)
    const params = new URLSearchParams(url.split('?')[1])
    params.set('page_size', pageSize)
    params.set('page', page)
    params.set('search', searchText)
    params.set('order_by', this.getPageOrderBy())
    params.set('order', this.getPageOrder())
    if(this.props.voidFilter && this.state.includeVoid)
      params.set('include_void', this.state.includeVoid)
    else
      params.delete('include_void')
    return url
  }

  onChangePageSize(pageSize) {
    this.setState({ pageSize: pageSize }, () => {
      this.setURLWithQueryParams();
      this.props.changePageSize(this.getPageUrl(this.state.paginationData.page), pageSize);
    });
  }

  onChangePage(page) {
    this.setState({ page: this.getPageNumber(page) }, () => {
      this.setURLWithQueryParams();
      this.props.navigateTo(this.getPageUrl(page), get(this.props, 'match.params'));
    });
  }

  onVoidChange = event => {
    this.props.dispatch(isLoading('searchedResults'));
    this.setState({ page: 1, includeVoid: event.target.checked }, () => {
      this.setURLWithQueryParams();
      this.props.navigateTo(this.getSearchSortUrl(), get(this.props, 'match.params'));
    });
  }

  getNextPageUrl() {
    if (get(this.state.paginationData, 'links.next')) {
      return this.state.paginationData.links.next;
    } else if (this.state.firstDefaultPageLink) {
      let baseUrl = this.state.firstDefaultPageLink.split('?');
      const queryParams = new URLSearchParams(baseUrl[1]);
      queryParams.set('page_size', this.state.paginationData.pageSize);
      queryParams.set('page', this.state.paginationData.page ? this.state.paginationData.page + 1 : 2);
      return baseUrl[0] + '?' + queryParams.toString();
    }
  }

  getPageNumber(page) {
    if (isNumber(page)) return page;
    if (page === 'previous') return this.state.paginationData.page;
    if (page === 'next') return this.state.paginationData.page + 2;
    if (page === 'first') return 0;
    if (page === 'last') return Math.ceil(this.state.totalCount / this.state.paginationData.pageSize);
  }

  getPageUrl(page) {
    if (get(this.state.paginationData, `links[${page}]`)) {
      return this.state.paginationData.links[page];
    } else {
      let baseUrl = get(this.state.paginationData, 'links.current') || this.state.firstDefaultPageLink;
      if (baseUrl) {
        baseUrl = baseUrl.split('?');
        const queryParams = new URLSearchParams(baseUrl[1]);
        queryParams.set('page_size', this.state.paginationData.pageSize);
        queryParams.set('order_by', this.getPageOrderBy());
        queryParams.set('order', this.getPageOrder());
        if (this.state.searchText) {
          queryParams.set('search', encodeURIComponent(this.state.searchText));
        } else {
          queryParams.delete('search');
        }
        if (page === 'previous') {
          queryParams.set('page', this.state.paginationData.page);
        } else if (page === 'next') {
          queryParams.set('page', this.state.paginationData.page + 2);
        } else if (page === 'first') {
          queryParams.delete('page');
        } else if (page === 'last') {
          const page = Math.ceil(this.state.totalCount / this.state.paginationData.pageSize);
          if (isNumber(page)) {
            queryParams.set('page', page);
          }
        }
        return baseUrl[0] + '?' + queryParams.toString();
      }
      return this.state.firstDefaultPageLink;
    }
  }

  getPrevPageUrl() {
    if (get(this.state.paginationData, 'links.previous')) {
      return this.state.paginationData.links.previous;
    } else if (this.state.firstDefaultPageLink) {
      let baseUrl = this.state.firstDefaultPageLink.split('?');
      const queryParams = new URLSearchParams(baseUrl[1]);
      queryParams.set('page_size', this.state.paginationData.pageSize);
      if (this.state.paginationData.page) {
        queryParams.set('page', this.state.paginationData.page - 1);
      } else {
        queryParams.delete('page');
      }
      return baseUrl[0] + '?' + queryParams.toString();
    }
  }

  getFirstPageUrl() {
    if (this.state.firstDefaultPageLink) {
      let baseUrl = this.state.firstDefaultPageLink.split('?');
      const queryParams = new URLSearchParams(baseUrl[1]);
      queryParams.set('page_size', this.state.paginationData.pageSize);
      return baseUrl[0] + '?' + queryParams.toString();
    }
  }

  setSearchText(event) {
    this.setState({ searchText: event.target.value });
  }

  shouldDoHardClear() {
    return (
      this.props.hardClear ||
        isAtGlobalOrders() ||
        isAtGlobalFMs() ||
        isAtGlobalContracts() ||
        isAtFarmFMs() ||
        isAtContractFMs() ||
        isAtGlobalCompanies() ||
        isAtGlobalFarms() ||
        isGlobalInvoicesPath() ||
        isAtGlobalVendorDecs() ||
        isAtOrderFMs() ||
        isAtAnyVendorDecsListing() ||
        (isAtOrderListing && isAtOrderListing()) ||
        isAtGlobalTitleTransfer() || this.props?.location?.pathname?.includes('/title-transfers') ||
        isAtGlobalCashPrices() ||
        isAtGlobalContractBids() ||
        isAtStocksSiteLoads() || this.props?.location?.pathname?.includes('/trucks') ||
        isAtContractInvoices() ||
        isAtOrderInvoices()
    );

  }

  clearSearch() {
    this.props.dispatch(isSearchApplied(null));
    if (!this.state.searchText)
      return;
    this.clearURLFromQueryParams();
    const newState = { ...this.state };
    if (this.props.globalSearch) {
      newState.allItems = [];
      newState.items = [];
    }
    newState.searchText = '';

    this.setState(newState, () => {
      if (this.props.globalSearch) {
        if (this.props.clearSearch) this.props.dispatch(this.props.clearSearch({ results: [] }));
        const firstPageURL = this.getPageUrl('first');
        if (firstPageURL) {
          let baseURL = firstPageURL;
          if (this.shouldDoHardClear()) {
            const parts = firstPageURL.split('?');
            const queryParams = new URLSearchParams(parts[1]);
            queryParams.delete('page_size');
            queryParams.delete('page');
            queryParams.delete('search');
            queryParams.delete('order_by');
            queryParams.delete('order');
            baseURL = parts[0];
            baseURL = baseURL.replace('search/', '');
            baseURL += `?${queryParams.toString()}`;
          }
          this.props.dispatch(isLoading('searchedResults'));
          this.props.navigateTo(baseURL, get(this.props, 'match.params'));
          if(this.props.getFacets)
            this.props.getFacets(baseURL)
        }
      }
    });
  }

  handleSearchEnterKeyPress(event) {
    if (event.key === 'Enter') {
      this.search();
    }
  }

  clearURLFromQueryParams() {
    window.location.hash = this.props.location.pathname;
  }

  sanitizeSearchText() {
    const { searchText } = this.state;
    return searchText ? searchText.trim() : searchText;
  }

  setURLWithQueryParams() {
    if (!this.props.globalSearch || this.props.noCache) return;
    const { location } = this.props;
    const { pageSize, page, orderBy, order, includeVoid } = this.state;
    const queryParams = new URLSearchParams(location.search);

    queryParams.set('q', this.sanitizeSearchText());
    queryParams.set('pageSize', pageSize);
    queryParams.set('page', this.getPageNumber(page));
    if(!this.props.noCache){
      let pageCacheParams = {orderBy: orderBy, order: order, includeVoid: includeVoid}
      updatePageCache(pageCacheParams);
    }
    queryParams.set('order_by', this.getPageOrderBy());
    queryParams.set('order', this.getPageOrder());
    if(includeVoid)
      queryParams.set('include_void', includeVoid);
    else
      queryParams.delete('include_void')
    let queryString = queryParams.toString();
    window.location.hash = location.pathname + '?' + queryString;
  }

  search() {
    const searchText = this.sanitizeSearchText();
    this.props.dispatch(isSearchApplied(searchText));
    if (this.props.globalSearch && searchText) {
      this.setState({ page: 1 }, () => {
        this.setURLWithQueryParams();
        this.getGlobalSearchResults(searchText);
      });
    } else {
      let searchedResults = [];
      const re = new RegExp(searchText, 'i');
      searchedResults = filter(this.props.items, item => {
        return some(this.props.columns, column => {
          let val = this.getValue(item, column)
          if(React.isValidElement(val))
            val = this.getTextContentFromReactElement(val)
          return re.test(val);
        });
      });
      this.setState({ items: searchedResults });
    }
  }
  getPageOrderBy = () => get(getPageCache(), 'orderBy') || this.state.orderBy;
  getPageOrder = () => get(getPageCache(), 'order') || this.state.order;

  getSorting() {
    if (this.props.globalSearch) {
      this.props.dispatch(isLoading('searchedResults'));
      if (get(this.state.paginationData, 'links.current')) {
        this.props.navigateTo(
          get(this.state.paginationData, 'links.current') + `&order_by=${this.getPageOrderBy()}&order=${this.state.order}`,
          get(this.props, 'match.params')
        );
      } else {
        this.props.navigateTo(this.getSearchSortUrl(), get(this.props, 'match.params'));
      }
    } else {
      const orderByField = this.getPageOrderBy()
      const handleCaseInsensitiveSorting =  item => {
        const value = item[orderByField];
        return typeof value === 'string' ? value.toLowerCase() : value;
      }

      return orderBy(this.state.items, [handleCaseInsensitiveSorting], this.getPageOrder());
    }
  }


  getTextContentFromReactElement = elem => {
    if (!elem) {
      return '';
    }
    if (typeof elem === 'string') {
      return elem;
    }
    const children = elem.props && elem.props.children;
    if (children instanceof Array) {
      return children.map(this.getTextContentFromReactElement).join('');
    }
    return this.getTextContentFromReactElement(children);
  }

  renderMultiValues = (item, column) => {
    const data = this.getValue(item, column);
    return isArray(data) && !isEmpty(data) ?
      (
        <span>
          {data.map((subItem, index) => (
            <span key={index}>
              <a target='_blank'
                 rel='noopener noreferrer'
                 href={subItem.url}
                 onClick={event => this.handleLinkClick(event)} >{subItem.identifier + ' '}</a>
            </span>
          ))}
        </span>
      ) : data
  };

  getValue(item, column, index, raw=false) {
    if (get(column, 'showToggle'))
      return
    if (item) {
      if (column.valueFunction && isFunction(column.valueFunction)) {
        let val = column.valueFunction(item, column, index);
        if (val) {
          return val;
        }
      }
    }

    var value = EMPTY_VALUE;
    if (isFunction(column.default)) {
      value = column.default(item);
    } else if (has(item, column.key)) {
      value = get(item, column.key);
    } else if (has(column, 'default')) {
      value = column.default;
    } else if (has(column, 'secondaryKey')) {
      value = get(item, column.secondaryKey);
      if (isEmpty(value) && has(column, 'tertiaryKey')) {
        value = get(item, column.tertiaryKey);
        if (isEmpty(value) && has(column, 'quaternaryKey')) {
          value = get(item, column.quaternaryKey);
          if (isEmpty(value)) {
            value = this.getValueFromOrder(item, column.key, column.secondaryKey);
          }
        }
      }
    } else if (column.key == 'bought') {
      value = get(item, 'limit') - get(item, 'limitRemaining');
    }
    return this.getValueCommon(value, column.key, column.type, raw, item);
  }

  getValueFromOrder(order, keyPath, contractKeyPath) {
    if (isEmpty(order)) return null;
    contractKeyPath = contractKeyPath || keyPath;
    if (contractKeyPath.startsWith('commodityContract')) {
      contractKeyPath = contractKeyPath.slice(18);
    }
    const value = get(order, keyPath);
    if (isEmpty(value) && !isNumber(value)) {
      if (!isEmpty(order['commodityContract'])) {
        return get(order['commodityContract'], contractKeyPath);
      }
      if (!isEmpty(order['parentOrder'])) {
        return this.getValueFromOrder(order['parentOrder'], keyPath, contractKeyPath);
      } else if (!isEmpty(order['order'])) {
        return this.getValueFromOrder(order['order'], keyPath, contractKeyPath);
      }
    }
    return value;
  }

  getFieldValue(item, field) {
    const value = get(item, field);
    return this.getValueCommon(value, field);
  }

  getValueCommon(value, key, type, raw=false, item) {
    if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)) {
      return moment(value, 'YYYY-MM-DD').format(getCountryFormats().date);
    }

    if (value && type === 'datetime') {
      return toDateTimeFormat(value);
    }

    if (value && type === 'bool') {
      return isBoolean(value) ? (value ? 'Yes' : 'No') : (value && ['yes', 'true'].includes(value) ? 'Yes' : 'No');
    }

    if(value && includes(['phone', 'mobile'], key))
      return toPhoneFormat(value)

    if (/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(value)) {
      return moment(value, 'HH:mm:ss').format(TIME_DISPLAY_FORMAT);
    }

    if (!raw && isNumber(value)) {
      if (includes(['rate', 'subTotal', 'gst', 'total'], key) || type === 'currency') {
        const currency = item?.currency || getCountryCurrency()
        return `${formatPrice(value.toFixed(2), 'N/A', currency)}`;
      }
      return value.toFixed(2);
    }

    if (value && (includes(['startDateTime', 'endDateTime', 'dateTime', 'createdAt'], key) || type === 'dateTime')) {
      return toDateTimeFormat(value);
    }

    return value || EMPTY_VALUE;
  }

  handleCellClick(event, colNum, rowNum) {
    if (event.target.type === 'checkbox') {
      return;
    }
    const clickedCell = this.props.columns[colNum];
    if (has(clickedCell, 'map')) {
      event.preventDefault();
    } else if (has(clickedCell, 'link')) {
      event.preventDefault();
    } else if (has(clickedCell, 'href') && clickedCell['href']) {
      if (clickedCell['external']) {
        const item = this.state.items[rowNum];
        window.location = get(item, clickedCell.key);
      } else {
        const item = this.state.items[rowNum];
        window.location.hash = clickedCell.path + get(item, clickedCell.key);
      }
      event.preventDefault();
    } else if (includes(event.target.closest('td').className, 'options')) {
      event.preventDefault();
    } else {
      if (colNum === this.props.optionsColumnNumber) {
        return;
      }
      if (this.props.handleDefaultCellClick && !isEmpty(this.state.items)) {
        const item = (this.props.ignoreOrder || this.props.globalSearch ? this.state.items : this.getSorting(this.state.items))[rowNum];
        this.props.handleDefaultCellClick(item, this.props.cellClickContext);
      } else if (this.props.handleCellClick) {
        this.props.handleCellClick(rowNum, colNum, event);
      }
    }
  }

  handleMapClick(event, item, column) {
    window.open('http://maps.google.com/maps?t=k&q=loc:' + get(item, column.map.lat) + '+' + get(item, column.map.lng), '_blank');
    event.preventDefault();
  }

  handleLinkClick(event) {
    window.open(event.target.href, '_blank');
    event.preventDefault();
  }

  handleMenuClick = (event, item, index) => {
    this.setState({
      menuOpen: event.currentTarget,
      selectedMenu: {
        item: item,
        index: index,
      },
    });
  };

  handleMenuClose = () => {
    this.setState({
      menuOpen: null,
      selectedMenu: {
        item: null,
        index: null,
      },
    });
  };

  handleCheckboxChange = (event, selectedItem, column) => {
    const { items } = this.state;
    items.forEach(item => {
      if (item.id === selectedItem.id) {
        item.keyContact = event.target.checked;
      } else {
        item.keyContact = false;
      }
    });
    let isChecked = event.target.checked;
    this.setState({ allItems: items, checkedItem: selectedItem }, () => {
      if(isFunction(column.onChange))
        column.onChange(selectedItem, isChecked)
      else
        this.props[column.onChange](selectedItem, isChecked);
    });
    event.stopPropagation();
    event.preventDefault();
  };

  getClassName(item) {
    if(this.props.noHighlight)
      return ''
    let className = 'row-with-data ';
    if (this.props.rowHighlightedMap) {
      const highlightedOnAttr = find(keys(this.props.rowHighlightedMap), attr => {
        return get(item, attr, false);
      });
      if (highlightedOnAttr) {
        className += this.props.rowHighlightedMap[highlightedOnAttr];
      }
    }

    return className;
  }

  handleOptionClick = (event, item, entity) => {
    this.props.handleOptionClick(event, item, entity);
    if (item.sideDrawer) {
      this.props.openAddSideForm(item, event);
    }
  };

  getTextField(index, column, item) {
    if (column.editComponent)
      this.setState({editComponent: column.editComponent, editComponentItem: item, editComponentUpdateDataMethod: column.updateData});
    else
      this.setState({textOpen: true, index: index, key: column.key, editComponent: undefined});
  }

  handleBlur(event, item, key) {
    this.props.handleValueChange(item, event.target.value, key);
    this.setState({ textOpen: false, value: undefined, key: undefined });
  }

  handleTextFieldChange(event) {
    this.setState({value: event.target.value});
  }

  render() {
    const { isMobileDevice } = this.context
    const { classes, headerControls, facets } = this.props;
    const shouldOptionBeEnabled = has(this.props, 'shouldOptionBeEnabled') ? this.props.shouldOptionBeEnabled : null;
    const items = this.props.globalSearch ? this.state.items : this.props.ignoreOrder ? this.state.items : this.getSorting(this.state.items);
    const showHeader = this.props.showHeader ? this.props.showHeaderValue : true;
    const headerStyle = get(this.props, 'alert') ? '75%' : '85%';
    const EditComponent = this.state.editComponent;
    const isAppliedFacets = size(this.props.savedFacets) > 0
    return (
      <div id='generic-table' ref={this.wrapperRef} style={merge({ margin: '0px 0' }, get(this.props, 'mainContainerStyle', {}))}>
        {
          this.props.tableHeader &&
            <div style={{ width: headerStyle, display: 'inline-block', marginTop: '-25px' }}>
              <h3 style={{ fontSize: '1.250rem', float: 'left', marginBottom: '5px' }}>{this.props.tableHeader}</h3>
              {
                get(this.props, 'alert') &&
                  <h3 style={{ fontSize: '1.250rem', float: 'right', color: 'red' }}>{this.props.alert}</h3>
              }
            </div>
        }
        { headerControls }
        {
          showHeader &&
            <SearchControls
              includeVoidChecked={this.state.includeVoid}
              onVoidChange={this.props.voidFilter ? this.onVoidChange : false}
              value={this.state.searchText}
              onChange={this.setSearchText}
              disableUnderline={true}
              onKeyPress={this.handleSearchEnterKeyPress}
              onSearch={this.search}
              onClear={this.clearSearch}
            />
        }
        {Boolean(EditComponent) &&
         <EditComponent invoiceItem={this.state.editComponentItem} updateData={this.state.editComponentUpdateDataMethod} onClose={() => this.setState({editComponent: undefined, editComponentItem: undefined, editComponentUpdateDataMethod: undefined})}/>
        }
        {
          isAppliedFacets &&
            <div id='appliedFilters' className='col-xs-12 padding-reset' style={{overflowX: 'auto', padding: '8px 0', marginTop: '4px', display: 'flex'}}>
              <AppliedFacets saved={this.props.savedFacets} onRemove={this.props.onFacetChange} applyFacets={this.applyFacets} />
            </div>
        }
        <div style={get(this.props, 'applyMargins') ? { overflow: 'auto', marginLeft: '2%', marginRight: '2%', marginTop: isAppliedFacets ? '45px' : '0.5%', display: 'flex', maxHeight: '80vh'} : { overflow: 'auto', marginTop: isAppliedFacets ? '45px' : '0.5%', display: 'flex', maxHeight: 'calc(100vh - 260px'}}>
          {
            this.props.showFacets &&
              <div style={{minWidth: '125px', maxWidth: '200px', float: 'left', marginRight: '8px', borderRight: '1px solid lightgray'}}>
                <Facets applied={this.props.appliedFacets} facets={facets} isOpen={this.props.showFacets} onChange={this.props.onFacetChange} />
              </div>
          }
          <TableVirtuoso
            style={{width: '100%', overflowX: 'auto', float: 'right', height: items?.length > 0 ? '65vh' : '100px'}}
            components={{
              Scroller: React.forwardRef((props, ref) => (
                <TableContainer {...props} ref={ref} />
              )),
              Table: (props) => (
                <Table {...props} stickyHeader size="small" sx={{width: this.props.showFacets ? '120%' : '100%'}} />
              ),
              TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
              TableRow: React.forwardRef(
                ({ 'data-index': index, ...rest }, ref) => {
                  const item = items[index]
                  return (
                    <TableRow
                      data-index={index}
                      {...rest}
                      ref={ref}
                      hover
                      className={this.getClassName(item)}
                      sx={{
                        '.MuiTableCell-root': {
                          padding: '2px 4px'
                        },
                        cursor: 'pointer',
                        borderLeft: this.props.rowBorderLeftColorAttr ? '20px solid ' + get(item, this.props.rowBorderLeftColorAttr, '#fff') : 'none',
                      }}
                    />
                  )
                }
              ),
              TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
              EmptyPlaceholder: React.forwardRef((props, ref) => (
                <TableBody {...props} ref={ref}>
                <TableRow sx={{'.MuiTableCell-root': {padding: '8px'}}}>
                  <TableCell colSpan='100' className='no-record-column' sx={{fontSize: '0.8125rem', ...this.props.noRecordFoundSx}}>
                    {this.props.noRecordFoundText ? this.props.noRecordFoundText : 'No records found.'}
                  </TableCell>
                </TableRow>
                </TableBody>
              ))
            }}
            data={items}
            fixedHeaderContent={() => {
              return has(this.props, 'hideColumnHeaders') ?
                undefined :
                <EnhancedTableHead
                  columns={this.props.columns || []}
                  order={this.getPageOrder()}
                  orderBy={this.getPageOrderBy()}
                  onRequestSort={this.handleRequestSort}
                  hasActions={isMobileDevice ? false : isFunction(this.props.optionsItems) || !isEmpty(this.props.optionsItems) || isFunction(this.props.customColumnGenerator)}
                  hideActionLabel={has(this.props, 'hideActionLabel')}
                />
            }}
            itemContent={(index, item) => {
              return (
                <>
                  {
                    this.props.columns.map((column, colIndex) => {
                      let showValue = !(this.state.textOpen && this.state.key === column.key && this.state.index === index);
                      let isToggleField = get(column, 'showToggle');
                      return (
                        <TableCell
                          onClick={event => column.checkbox ? this.handleCheckboxChange(event, item, column) : this.handleCellClick(event, colIndex, index)}
                          className={column.className}
                          key={`${index}-${colIndex}`}
                          sx={{fontSize: '0.8125rem', whiteSpace: 'normal !important', ...(column.checkbox ? {minWidth: '30px !important', width: '36px'} : {}), ...column.cellStyle}}
                          align={column.align}
                        >
                          {
                            showValue &&
                              (
                                column.icon ?
                                  column.func(item) :
                                  column.checkbox ? (
                                    column.tooltipTextFunc ? (
                                      <Tooltip title={column.tooltipTextFunc(item)} placement='top'>
                                        <Checkbox
                                          size={column.size || 'small'}
                                          sx={{padding: 0, ...column.sx}}
                                          checked={column.func ? column.func(item) : get(item, column.key)}
                                          onChange={event => this.handleCheckboxChange(event, item, column)}
                                          classes={{
                                            root: classes.root,
                                            checked: classes.checked,
                                          }}
                                          disabled={this.props.disableCheckBox ? true : (column.func && column.func(item)) ? true: false}
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Checkbox
                                        size={column.size || 'small'}
                                        sx={{padding: 0, ...column.sx}}
                                        checked={column.func ? column.func(item) : get(item, column.key)}
                                        onChange={event => this.handleCheckboxChange(event, item, column)}
                                        classes={{
                                          root: classes.root,
                                          checked: classes.checked,
                                        }}
                                        disabled={this.props.disableCheckBox || (get(item, 'contractInvoicing') === 'Buyer RCTI' && this.props.isSubscriber) ? true : (column.func && column.func(item)) ? true: false}
                                      />
                                    )
                                  ) : column.inlineMap ? (
                                    <span>
                                      <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        onClick={event => this.handleMapClick(event, item, column)}
                                        style={{cursor: "pointer", color: "black", textDecoration: "none" }}
                                      >
                                        {this.getValue(item, column)}
                                      </a>
                                      <IconButton
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        onClick={event => this.handleMapClick(event, item, column)}
                                        size="small">
                                        <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
                                      </IconButton>
                                    </span>
                                  ):
                                  column.map ? (
                                    <IconButton
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      onClick={event => this.handleMapClick(event, item, column)}
                                      size="large">
                                      <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN }} />
                                    </IconButton>
                                  ) : column['fieldType'] === 'url' && this.getValue(item, column) && this.getValue(item, column) !== '-' ? (
                                    <a target='_blank' rel='noopener noreferrer' href={this.getValue(item, column)}>
                                      DOC
                                    </a>
                                  ) : column['fieldType'] === 'url-conditional' ? (
                                    <>
                                      <span>
                                        {column.urlKey && item[column.urlKey] && get(item, 'canUserView', true) ? (
                                          <a
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            href={item[column.urlKey]}
                                            onClick={event => this.handleLinkClick(event)}
                                          >
                                            {this.getValue(item, column)}
                                          </a>
                                        ) : (
                                          this.getValue(item, column)
                                        )}
                                      </span>
                                      <EntityID column={column} displayIDColumn={this.props.displayIDColumn} item={item}/>
                                    </>
                                  ) : column['fieldType'] === 'url-conditional-multi' ? (
                                    column.urlKey && !isEmpty(item[column.urlKey]) ? (
                                      item[column.urlKey].map((url, index) => (
                                        <div key={get(url, 'url', url)}>
                                          <a
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            href={get(url, 'url', url)}
                                            onClick={event => this.handleLinkClick(event)}
                                          >
                                            {this.getValue(item, column, index)}
                                          </a>
                                        </div>
                                      ))
                                    ) : (
                                      this.getValue(item, column)
                                    )
                                  ) : column['fieldType'] === 'multi-values' && this.getValue(item, column) ? (
                                    this.renderMultiValues(item, column)
                                  ) : column['fieldType'] === 'multi' && column['fields'] ? (
                                    column['fields'].map((field, index) => {
                                      const separator = index < column['fields'].length - 1 && column['fieldSeparator'] ? column['fieldSeparator'] : ' ';
                                      return this.getFieldValue(item, field) + separator;
                                    })
                                  ) : has(column, 'href') && column['href'] ? (
                                    has(column, 'predicateFunc') && this.props[column['predicateFunc']](item) ? (
                                      <a href={column.path + get(item, column.key)}>{column.label || get(item, column.labelKey)}</a>
                                    ) : null
                                  ) : (
                                    <>
                                      <span>
                                        {column['formatter'] ?
                                         column['formatter'](item) :
                                         (this.getValue(item, column, colIndex))}
                                      </span>
                                      <EntityID column={column} displayIDColumn={this.props.displayIDColumn} item={item}/>
                                    </>
                                  )
                              )}
                          {column.isColumnEditable && !this.state.textOpen && has(item, column.key) && get(item, 'isEditable') &&
                           <IconButton
                             onClick={() => this.getTextField(index, column, item)}
                             size="small">
                             <Create fontSize="inherit" />
                           </IconButton>
                          }
                          {isToggleField && has(column, 'func') &&
                           <Switch color='primary' checked={get(item, column.key)} onChange={() => column.func(item)} />
                          }
                          {this.state.textOpen && this.state.index === index && column.isColumnEditable && this.state.key === column.key &&
                           <TextField
                             onBlur={event => this.handleBlur(event, item, column.key)}
                             value={(this.state.value || this.getValue(item, column, undefined, true))}
                             onChange={this.handleTextFieldChange}
                             variant="standard"
                             inputProps={{type: column.editableFieldType}}
                             type={column.editableFieldType}
                           />
                          }
                        </TableCell>
                      );
                    })
                  }
                  {
                    this.props.hasEditColumn ? (
                      <TableCell
                        className={this.props.editColumnClass ? this.props.editColumnClass : 'xxsmall'}
                        sx={{fontSize: '0.8125rem'}}
                      >
                        <ModeEditIcon onClick={event => this.handleCellClick(event, 0, index)} className='material-icons' id={EDIT} />
                      </TableCell>
                    ) : null
                  }
                  {
                    !isMobileDevice && this.props.optionsItems &&
                      get(this.props.optionsItems, 'length') > 0 &&
                      (
                        this.props.useNestedOptionMenu ?
                          <TableCell className={(this.props.actionsClass || 'xxsmall') + ' options'} sx={{fontSize: '0.8125rem'}} align='center'>
                            <NestedOptionMenu
                              optionsItems={this.props.optionsItems}
                              item={item}
                              handleOptionClick={this.handleOptionClick}
                              currentUser={this.props.currentUser}
                              shouldOptionBeEnabled={shouldOptionBeEnabled}
                              shouldOptionBeDisabled={this.props.shouldOptionBeDisabled}
                              clearEntity={this.props.clearEntity}
                              useIconButton={true}
                            />
                          </TableCell> :
                        <TableCell className={(this.props.actionsClass || 'xxsmall') + ' options'} align='center' sx={{fontSize: '0.8125rem', ...(this.props.actionStyles || {})}}>
                          <OptionMenu
                            optionsItems={this.props.optionsItems}
                            item={item}
                            handleOptionClick={this.props.handleOptionClick}
                            currentUser={this.props.currentUser}
                            shouldOptionBeEnabled={shouldOptionBeEnabled}
                            shouldOptionBeDisabled={this.props.shouldOptionBeDisabled}
                          />
                        </TableCell>
                      )
                  }
                  {
                    this.props.customColumnGenerator ? (
                      <TableCell className={this.props.customColumnClass ? this.props.customColumnClass : 'medium'} sx={{fontSize: '0.8125rem'}}>
                        {this.props.customColumnGenerator(item, index, this.props.dispatch)}
                      </TableCell>
                    ) : null
                  }
                </>
              )
            }}
          />
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: this.props.showFacets ? 'space-between' : 'flex-end'}}>
          {
            this.props.showFacets &&
              <div style={{minWidth: '125px', maxWidth: '200px', float: 'left'}}>
                <Button color='primary' size='small' variant='contained' onClick={() => this.applyFacets()}>Apply</Button>
                <Button color='secondary' size='small' variant='text' sx={{ml: 1}} onClick={() => this.clearFacets()}>Clear</Button>
              </div>
          }
          {
            !this.props.isPresentation && this.state.paginationData &&
              <CommonTablePagination
                paginationData={this.state.paginationData}
                count={this.state.totalCount}
                changePage={this.onChangePage}
                nextPageUrl={this.getNextPageUrl()}
                prevPageUrl={this.getPrevPageUrl()}
                firstPageUrl={this.getFirstPageUrl()}
                getPageUrl={this.getPageUrl}
                changePageSize={this.onChangePageSize}
                noCache={this.props.noCache}
              />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.main.isLoading,
  isSearchApplied: state.main.isSearchApplied,
  waitForComponent: state.main.waitForComponent,
  currentUser: state.main.user,
});

export default withStyles(styles)(connect(mapStateToProps)(withRouter(GenericTable)));
