import React, { Component } from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import { applyValidatorsOn, required, selected } from '../../common/validators';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import CommonDatePicker from '../common/CommonDatePicker';
import CustomEmailDialog from '../common/CustomEmailDialog';
import {
  canAccessAny,
  generateIdentifier,
  getAutoSelectFocusField,
  getLabelForEmployeeForm,
  isCurrentUserBelongsToCompany,
  isTransactionParticipated,
  getFreshdeskURL, getCountryLabel,
  getCountryCurrency, formatPrice,
  returnFloatFromString,
  generateUpdatedIdentifier,
  convertTenureFormat
} from '../../common/utils';
import APIService from '../../services/APIService';
import moment from 'moment';
import AddButton from '../common/AddButton';
import { NGR_MISSING_DETAILS_ERROR, NGR_MISSING_DETAILS_ERROR_WITHOUT_ACCESS } from '../../common/constants';
import SideDrawer from '../common/SideDrawer';
import WarehouseInvoiceCustomItemForm from '../invoices/WarehouseInvoiceCustomItemForm';
import InvoiceTable from './InvoiceTable';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import IconButton from '@mui/material/IconButton';
import isNumber from 'lodash/isNumber';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField/TextField';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Dialog from '@mui/material/Dialog/Dialog';
import InvoicePreview from './InvoicePreview';
import './invoice.scss';
import { fetchBanks, isLoading, forceStopLoader, setBreadcrumbs, setHeaderText } from '../../actions/main';
import { clickAddGeneralConditionButton, getGeneralConditions, receiveGeneralConditions } from '../../actions/companies/conditions';
import { getPaymentTerms} from '../../actions/companies/contracts';
import {isFreightContractsFetched } from '../../actions/companies/freights';
import {
  get, mapValues, isEqual, forEach, find, every,
  uniqBy, cloneDeep, some, isArray, reduce, isEmpty,
  remove, omit, set, includes, compact, difference
} from 'lodash';
import CommonSelect from '../common/select/CommonSelect';
import { getMonthYear, getCountryFormats, getCountryConfig } from '../../common/utils';
import AutoComplete from '../common/autocomplete/AutoComplete';
import Button from "@mui/material/Button/Button";
import UpdateCompanyNgr from "../../containers/UpdateCompanyNgr";
import {
  cancelEditCompanyNgr,
  clickEditCompanyNgr,
  receiveCompanyNgrs
} from "../../actions/companies/company-ngrs";
import { cancelEditNgr } from "../../actions/companies/ngrs";
import CreateCompanyNgr from '../../containers/CreateCompanyNgr';
import { clickAddCompanyNgrButton } from '../../actions/companies/company-ngrs';
import { clickAddNgrButton } from '../../actions/companies/ngrs';
import CreateEmployee from "../../containers/CreateEmployee";
import CreateGeneralCondition from "../../containers/CreateGeneralCondition";
import { AddCircleIconWithTooltip } from "../common/icons/AddCircleIconWithTooltip";
import { canCreateEmployee } from "../../actions/api/employees";
import { clickAddEmployeeButton } from "../../actions/company-settings/employees";
import { canCreateNgr } from "../../actions/api/ngrs";
import { getCompanyCompaniesMinimal } from "../../actions/api/companies";
import Notes from "../common/Notes";
import { createAmendInvoice, createInvoice, deleteInvoice, editInvoice, generateInvoice, getInvoiceDetails } from '../../actions/companies/invoices';
import { CUSTOM_ITEM_TYPE, REGRADE_RESEASON_ITEM_TYPE, STOCK_SWAP_ITEM_TYPE, WAREHOUSE_INTLOAD_ITEM_TYPE, WAREHOUSE_OUTLOAD_ITEM_TYPE, WAREHOUSE_STORAGE_ITEM_TYPE, WAREHOUSE_THROUGHPUT_INLOAD_ITEM_TYPE, WAREHOUSE_THROUGHPUT_OUTLOAD_ITEM_TYPE, WAREHOUSE_TRANSFER_ITEM_TYPE } from './Constants';
import CommonWeekSelector from '../common/CommonWeekSelector';

const DEFAULT_SIDE_DRAWERS_STATE = {
  openSideDrawer: false,
  ngrSideDrawerIsOpen: false,
  payeeSideDrawerIsOpen: false,
  payerSideDrawerIsOpen: false,
  invoiceConditionSideDrawerIsOpen: false,
};

class WarehouseInvoiceForm extends Component {
  constructor(props) {
    super(props);
    const invoiceParams = this.getInvoiceParams(props);
    this.isEdit = Boolean(invoiceParams.tenure)
    this.invoicePartyFields = {
      companyId: {
        value: null,
        validators: [],
        errors: [],
      },
      contactId: {
        value: null,
        validators: [],
        errors: [],
      },
      ngrId: {
        value: null,
        validators: [],
        errors: [],
      },
      bankName: {
        value: null,
        id: '',
        validators: [required(), selected()],
        errors: [],
      },
      accountName: {
        value: null,
        validators: [required()],
        errors: [],
      },
      bsbNumber: {
        value: null,
        validators: [required()],
        errors: [],
      },
      accountNumber: {
        value: null,
        validators: [required()],
        errors: [],
      },
    };
    this.payerFields = cloneDeep(this.invoicePartyFields);
    this.payeeFields = cloneDeep(this.invoicePartyFields);
    this.config = getCountryConfig();
    this.state = {
      countryFormats: getCountryFormats(),
      isSettingValuesForEdit: this.isEdit,
      singleValue: { year: 2014, month: 11 },
      subTotal: undefined,
      gst: undefined,
      total: undefined,
      selectedPayerNgr: undefined,
      selectedBilledToParty: undefined,
      displayPayeeBankForm: false,
      displayPayerBankForm: false,
      isConfirmDialogOpen: false,
      selectedCustomItems: [],
      selectedStoragesItems: [],
      selectedOutloadsItems: [],
      selectedInloadsItems: [],
      selectedThroughputOutloadsItems: [],
      selectedThroughputInloadsItems: [],
      selectedTransfersItems: [],
      selectedStockSwapItems: [],
      selectedRegradeReseasonItems: [],
      payeeNgrs: [],
      payerNgrs: [],
      payerParties: [],
      payeeParties: [],
      payerEmployees: [],
      payeeEmployees: [],
      selectedItems: [],
      inloads: [],
      outloads: [],
      throughputInloads: [],
      throughputOutloads: [],
      storages: [],
      transfers: [],
      stockSwaps: [],
      regradeReseasons: [],
      customItems: [],
      ngrSideDrawerIsOpen: false,
      ngrEntity: undefined,
      payeeSideDrawerIsOpen: false,
      payerSideDrawerIsOpen: false,
      isFetchingNGR: false,
      invoiceConditionSideDrawerIsOpen: false,
      customItemId: 1,
      generalConditionsSelector: '',
      selectedGeneralConditionText: '',
      xeroCounterPartyExists: false,
      gstRate: this.config?.invoicing?.gstRate,
      fetchingLoads: false,
      fields: {
        note: {
          description: '',
          attachments: [],
          companyId: this.props.user.companyId,
        },
        paymentDueDate: {
          value: '',
          validators: [required()],
          errors: [],
        },
        identifier: {
          value: generateIdentifier('invoice'),
          validators: [required()],
          errors: [],
        },
        payee: cloneDeep(this.payeeFields),
        payer: cloneDeep(this.payerFields),
        invoiceConditions: {
          value: '',
          validators: [],
          errors: [],
        },
        tenure: {
          value: '',
          validators: [required()],
          errors: [],
        },
      },
    };
    this.fieldsOrder = [
      'identifier',
      'paymentDueDate',
      'tenure',
      'payee.companyId',
      'payer.companyId',
      'payee.ngrId',
      'payee.BankName',
      'payer.BankName',
      'payee.accountName',
      'payer.accountName',
      'payee.accountNumber',
      'payer.accountNumber',
      'payee.bsbNumber',
      'payer.bsbNumber',
    ];


    const hashQueryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    this.isDuplicatingInvoice = Boolean(hashQueryParams.get('copyFrom'))
    this.copyFrom = hashQueryParams.get('copyFrom')
    const pathSegments = location.hash.split('/');
    this.isInEditMode = pathSegments.includes('edit');
    this.selectedInvoiceId = get(this.props, 'match.params.invoice_id');

    this.fieldRef = {};
    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    this.commonOpenSideDrawer = this.commonOpenSideDrawer.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.handlePayerChange = this.handlePayerChange.bind(this);
    this.getPartyNgrs = this.getPartyNgrs.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.appendInvoiceConditions = this.appendInvoiceConditions.bind(this);
    this.handleConditionSelector = this.handleConditionSelector.bind(this);
    this.handleConfirmDialogCancel = this.handleConfirmDialogCancel.bind(this);
    this.handlePreviewClose = this.handlePreviewClose.bind(this);
    this.handlePreviewSubmit = this.handlePreviewSubmit.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.handlePayeeBankChange = this.handlePayeeBankChange.bind(this);
    this.handlePayerBankChange = this.handlePayerBankChange.bind(this);
    this.handleAccountChangeForEntity = this.handleAccountChangeForEntity.bind(this);
    this.openNgrSideDrawer = this.openNgrSideDrawer.bind(this);
    this.closeNgrSideDrawer = this.closeNgrSideDrawer.bind(this);
    this.openInvoiceConditionSideDrawer = this.openInvoiceConditionSideDrawer.bind(this);
    this.closeInvoiceConditionSideDrawer = this.closeInvoiceConditionSideDrawer.bind(this);
    this.openPayeeSideDrawer = this.openPayeeSideDrawer.bind(this);
    this.closePayeeContactSideDrawer = this.closePayeeContactSideDrawer.bind(this);
    this.openBillToContactSideDrawer = this.openBillToContactSideDrawer.bind(this);
    this.closeBillToContactSideDrawer = this.closeBillToContactSideDrawer.bind(this);
    this.updateSelectedItem = this.updateSelectedItem.bind(this);
    this.openAddNgrSideDrawer = this.openAddNgrSideDrawer.bind(this);
    this.closeAddNgrSideDrawer = this.closeAddNgrSideDrawer.bind(this);
    this.getAddBillToPartyContactButton = this.getAddBillToPartyContactButton.bind(this);
    this.getPayeeCompanyDetails = this.getPayeeCompanyDetails.bind(this);
    this.setEmployeesAndContactForParty = this.setEmployeesAndContactForParty.bind(this);
    this.getCompanyEmployees = this.getCompanyEmployees.bind(this);
    this.getPayerPartyCompanyDetails = this.getPayerPartyCompanyDetails.bind(this);
    this.setDefaultGeneralCondition = this.setDefaultGeneralCondition.bind(this);
    this.commonOpenSideDrawer = this.commonOpenSideDrawer.bind(this);
    this.billToContactSideDrawer = this.billToContactSideDrawer.bind(this);
    this.closeCustomEmailDialog = this.closeCustomEmailDialog.bind(this);
    this.openCustomEmailDialog = this.openCustomEmailDialog.bind(this);
    this.tonnageValue = this.tonnageValue.bind(this);
    this.shrunkTonnageValue = this.shrunkTonnageValue.bind(this);
    this.getPayeeCompanyDetails = this.getPayeeCompanyDetails.bind(this);
    this.handlePayerContactChange = this.handlePayerContactChange.bind(this);
    this.handlePayeeContactChange = this.handlePayeeContactChange.bind(this);
    this.setPayeeNgrErrors = this.setPayeeNgrErrors.bind(this);
    this.setPayerNgrErrors = this.setPayerNgrErrors.bind(this);
    this.handlePayeeNgrChange = this.handlePayeeNgrChange.bind(this);
    this.handlePayerNgrChange = this.handlePayerNgrChange.bind(this);
    this.handleNgrCreation = this.handleNgrCreation.bind(this);
    this.defaultPayeeNgr = this.defaultPayeeNgr.bind(this);
  }


  getInvoiceParams = props => {
    const queryParams = new URLSearchParams(this.props.location.search)
    return {
      tenure: props.match.params.tenure,
      payee_company_id: props.match.params.payee_company_id,
      payer_company_id: props.match.params.payer_company_id,
      payer_ngr_id: queryParams.get('ngrId')
    }
  }

  getCopyInvoiceParams = () => {
    const { selectedInvoice } = this.props
    return {
      tenure: convertTenureFormat(selectedInvoice.tenureRaw),
      payee_company_id: selectedInvoice.payee.companyId,
      payer_company_id: selectedInvoice.payer.companyId,
      payer_ngr_id: selectedInvoice.payer.ngrId
    }
  }

  componentWillUnmount() {
    this.props.dispatch(receiveGeneralConditions([]));
    this.props.dispatch(isFreightContractsFetched(false));
    this.closeNgrSideDrawer();
  }

  defaultPayeeNgr = () => {
    APIService.companies(this.props.user.company.id).appendToUrl('ngrs/tags/warehouse_invoice/')
      .get()
      .then(response => {
      const newState = {...this.state};
      set(newState.fields, 'payee.ngrId.value', get(response, 'ngrId'));
      this.setState(newState);
    })
  }

  fetchCurrentUserCompany() {
    const companyId = this.props.user?.companyId
    if(companyId)
      APIService.companies(this.props.user.companyId).get().then(response => this.setState({currentUserCompany: response}))
  }

  componentDidMount() {
    this.fetchCurrentUserCompany();
    const { dispatch } = this.props;
    dispatch(isLoading('nonExistingComponent'));

    if(this.copyFrom && this.props.invoiceId != this.copyFrom)
      dispatch(getInvoiceDetails(this.copyFrom));

    if(this.selectedInvoiceId && this.isInEditMode && this.props.invoiceId != this.selectedInvoiceId) {
      dispatch(getInvoiceDetails(this.selectedInvoiceId));
    }

    if(this.isEdit)
      this.setValuesForEdit()
    const breadcrumbs = [{ text: 'Invoices', route: '/invoices' }, { text: 'New' }];
    dispatch(setBreadcrumbs(breadcrumbs));
    dispatch(setHeaderText('Invoices'));
    if (get(this.props, 'user.company')) {
      this.getCompanyEmployees(get(this.props, 'user.company'), 'payee', get(this.props, 'user.id'));
    }
    this.getCompaniesMinimal(true);
    this.defaultPayeeNgr();
    this.setSelectedItems();
  }

  componentDidUpdate(prevProps) {
    const newState = { ...this.state };
    if (isEmpty(this.state.payerParties)) {
      newState.payerParties = newState.payerParties.concat(this.props.allCompanyParties);
    }
    if (isEmpty(this.state.payeeNgrs) && this.state.isFetchingNGR == false) {
      this.setState({isFetchingNGR: true}, () => {
        this.getPartyNgrs('payee', this.props.user.companyId);
      })
      newState.isFetchingNGR = true;
    }
    this.updateNgrChanges(prevProps, newState);

    let subTotal = reduce(
      this.state.selectedItems,
      function (sum, item) {
        return sum + parseFloat(item.subTotal);
      },
      0,
    );
    let gst = reduce(
      this.state.selectedItems,
      function (sum, item) {
        return sum + (item.gst === 'N/A' ? 0 : parseFloat(item.gst));
      },
      0,
    );
    newState.subTotal = subTotal.toFixed(2);
    newState.gst = gst.toFixed(2);
    let totalFromItems = reduce(
      this.state.selectedItems,
      function (sum, item) {
        return sum + parseFloat(item.total);
      },
      0,
    );
    newState.total = totalFromItems.toFixed(2);

    const selectedInvoiceConditionsValue = (this.props.selectedInvoice && get(this.props.selectedInvoice, 'invoiceConditions')) || ""
    const oldInvoiceConditionsValue = newState.fields.invoiceConditions.value || ""
    if(this.isDuplicatingInvoice){
      if (
        !oldInvoiceConditionsValue &&
        selectedInvoiceConditionsValue &&
        !this.state.isDefaultGeneralConditionApplied
      ) {
        newState.fields.invoiceConditions.value = oldInvoiceConditionsValue + selectedInvoiceConditionsValue;
        newState.isDefaultGeneralConditionApplied = true;
      }
    }

    if (this.props.selectedInvoice && !prevProps.selectedInvoice) {
      newState.fields.identifier.value = get(this.props.selectedInvoice, 'identifier');
      if(this.isDuplicatingInvoice && get(this.props.selectedInvoice, 'status') != 'void')
        newState.fields.identifier.value = generateUpdatedIdentifier(newState.fields.identifier.value);
      newState.fields.note.description = get(this.props.selectedInvoice, 'note.description', '');
    }

    if (!isEqual(this.state, newState)) {
      this.setState(newState);
    }

    if (this.props.selectedInvoice && !prevProps.selectedInvoice && this.copyFrom) {
      this.setValuesForCopy()
    }

    if(
      this.state.payerEmployees.length > 0 &&
      this.state.payeeEmployees.length > 0 &&
      this.props.selectedInvoice &&
      this.isDuplicatingInvoice
    ){
      newState.fields.payer.companyId.value = this.props.selectedInvoice.payer.companyId
      newState.fields.payer.contactId.value = this.props.selectedInvoice.payer.contactId
      newState.fields.payer.ngrId.value = this.props.selectedInvoice.payer.ngrId
      newState.fields.payee.contactId.value = this.props.selectedInvoice.payee.contactId
      this.setState(newState)
      this.isDuplicatingInvoice = false
    }

    if (this.props.selectedInvoice && this.isInEditMode && !prevProps.selectedInvoice){
      this.setValuesForAmend(newState);
    }
  }

  getSelectedInvoiceCustomItems = () => {
    const newState = {...this.state};
    let customItems = [];
    let customItemId = 1;
    forEach(this.props.selectedInvoice.others, (item) => {
      let customItem = {};
      customItem.description = item.description;
      customItem.subTotal = returnFloatFromString(item.totalExGst);
      customItem.gst = returnFloatFromString(item.gst);
      customItem.total = returnFloatFromString(item.total);
      customItem.rate = item.rate ? returnFloatFromString(item.rate) : item.rate ;
      customItem.itemType = CUSTOM_ITEM_TYPE;
      customItem.itemId = customItemId;
      customItem.id = customItemId;
      customItem.invoice = true;
      customItem.date = moment(item.date, 'YYYY-MM-DD').format(this.state.countryFormats.date);
      customItems.push(customItem);
      customItemId += 1;
    });
    newState.customItemId = customItemId;
    newState.customItems = customItems;
    if(this.isInEditMode)
      newState.selectedCustomItems = cloneDeep(customItems);
    else {
      newState.selectedCustomItems = cloneDeep(customItems);
      newState.selectedItems = [...newState.selectedItems, ...customItems];
    }
    newState.customItems.sort((a, b) => new Date(moment(a.date, 'DD/MM/YYYY').format("YYYY/MM/DD")).getTime() - new Date(moment(b.date, 'DD/MM/YYYY').format("YYYY/MM/DD")).getTime());
    this.setState(newState, ()=>this.setSelectedItems());
  }

  async setValuesForEdit() {
    const params = this.copyFrom? this.getCopyInvoiceParams() : this.getInvoiceParams(this.props);
    const service = APIService.invoices().appendToUrl(`warehouse/${params.tenure}/payees/${params.payee_company_id}/payers/${params.payer_company_id}/`)
    const query = params.payer_ngr_id ? {payer_ngr_id: params.payer_ngr_id} : {}
    const invoice = await service.get(null, null, query)
    this.setState({selectedInvoice: invoice}, () => {
      const newState = {...this.state}
      newState.fields.payer.companyId.value = invoice.payer.companyId
      newState.fields.payer.contactId.value = invoice.payer.contactId
      newState.fields.payer.ngrId.value = invoice.payer.ngrId
      newState.fields.payee.contactId.value = invoice.payee.contactId
      newState.fields.paymentDueDate.value = moment(invoice.paymentDueDate).format('YYYY-MM-DD');
      let tenure = invoice.tenureRaw;
      if (this.isWeeklyWarehouseInvoiceFrequency())
        newState.fields.tenure.value = tenure;
      else {
        const [, endDate] = tenure.split(" - ");
        const [, month, year] = endDate.split("/");
        newState.fields.tenure.value = `${year}-${month}`;
      }
      if(invoice.payer.ngrId)
        newState.selectedPayerNgr = {id: invoice.payer.ngrId}
      newState.selectedBilledToParty = {id: invoice.payer.companyId, displayName: invoice.payerDisplayName, typeId: invoice.payer.typeId}
      this.setState(newState, () => {
        this.getCompanyEmployees(newState.selectedBilledToParty, 'payer');
        this.getPartyNgrs('payer', this.state.fields.payer.companyId.value, true, this.state.selectedInvoice.payer.ngrId);
        setTimeout(() => this.setState({isSettingValuesForEdit: false}, this.fetchLoads), 2000)
      })
    })
  }

  setValuesForAmend = (newState) => {
    const inloads = get(this.props.selectedInvoice, 'warehouseInloads');
    const outloads = get(this.props.selectedInvoice, 'warehouseOutloads');
    const customItems = get(this.props.selectedInvoice, 'others');
    const storages = get(this.props.selectedInvoice, 'warehouseStorages');
    const throughputInloads = get(this.props.selectedInvoice, 'warehouseThroughputInloads');
    const throughputOutloads = get(this.props.selectedInvoice, 'warehouseThroughputOutloads');
    const transfers = get(this.props.selectedInvoice, 'warehouseTransfers');
    const stockSwaps = get(this.props.selectedInvoice, 'warehouseStockSwaps');
    const regradeReseasons = get(this.props.selectedInvoice, 'warehouseRegradeReseasons');
    const invoice = this.props.selectedInvoice;
    this.setState({selectedInvoice: invoice}, () => {
      const newState = {...this.state}
      newState.fields.payer.companyId.value = invoice.payer.companyId
      newState.fields.payer.contactId.value = invoice.payer.contactId
      newState.fields.payer.ngrId.value = invoice.payer.ngrId
      newState.fields.payee.contactId.value = invoice.payee.contactId
      newState.fields.paymentDueDate.value = moment(invoice.paymentDueDate).format('YYYY-MM-DD');
      let tenure = invoice.tenureRaw;
      if (this.isWeeklyWarehouseInvoiceFrequency())
        newState.fields.tenure.value = tenure;
      else {
        const [, endDate] = tenure.split(" - ");
        const [, month, year] = endDate.split("/");
        newState.fields.tenure.value = `${year}-${month}`;
      }
      if(invoice.payer.ngrId)
        newState.selectedPayerNgr = {id: invoice.payer.ngrId}
      newState.selectedBilledToParty = {id: invoice.payer.companyId, displayName: invoice.payerDisplayName, typeId: invoice.payer.typeId}
      this.setState(newState, () => {
        this.getCompanyEmployees(newState.selectedBilledToParty, 'payer');
        this.getPartyNgrs('payer', this.state.fields.payer.companyId.value, true, this.state.selectedInvoice.payer.ngrId);
        setTimeout(() => this.setState({isSettingValuesForEdit: false}, this.fetchLoads), 2000)
      })
    })

    this.applyInvoiceItems('stockSwaps', [{'loads':stockSwaps}], 'Stock Swaps', STOCK_SWAP_ITEM_TYPE, newState);
    this.applyInvoiceItems('regradeReseasons', [{'loads':regradeReseasons}], 'Regrade Reseasons', REGRADE_RESEASON_ITEM_TYPE, newState);
    this.applyInvoiceItems('customItems', [{'loads':customItems}], 'Custom Item', 'customitem', newState);
    this.applyInvoiceItems('inloads', [{'loads':inloads}], 'Inloads', 'warehouseinloaditem', newState);
    this.applyInvoiceItems('outloads', [{'loads':outloads}], 'Outloads', 'warehouseoutloaditem', newState);
    this.applyInvoiceItems('throughputInloads', [{'loads':throughputInloads}], 'Throughput Inloads', 'warehousethroughputinloaditem', newState);
    this.applyInvoiceItems('throughputOutloads', [{'loads':throughputOutloads}], 'Throughput Outloads', 'warehousethroughputoutloaditem', newState);
    this.applyInvoiceItems('transfers', [{'loads':transfers}], 'Transfers', 'warehousetransferitem', newState);
    this.applyStorageInvoiceItems('storages', [{'storages':storages}], 'warehousestorageitem', newState);
    this.getSelectedInvoiceCustomItems();
  }

  async setValuesForCopy() {
    const invoice = this.props.selectedInvoice
    const newState = {...this.state}
    newState.fields.payer.companyId.value = invoice.payer.companyId
    newState.fields.payer.ngrId.value = invoice.payer.ngrId
    newState.fields.payee.companyId.value = invoice.payee.companyId
    newState.fields.paymentDueDate.value = moment(invoice.paymentDueDate).format('YYYY-MM-DD');
    let tenure = invoice.tenureRaw;
    if (this.isWeeklyWarehouseInvoiceFrequency())
      newState.fields.tenure.value = tenure;
    else {
      const [, endDate] = tenure.split(" - ");
      const [, month, year] = endDate.split("/");
      newState.fields.tenure.value = `${year}-${month}`;
    }
    if(invoice.payer.ngrId)
      newState.selectedPayerNgr = {id: invoice.payer.ngrId}
      newState.selectedBilledToParty = {id: invoice.payer.companyId, displayName: invoice.payerDisplayName, typeId: invoice.payer.typeId}
      this.setState(newState, () => {
        this.getCompanyEmployees(newState.selectedBilledToParty, 'payer');
        this.getPartyNgrs('payer', newState.fields.payer.companyId.value, false, this.props.selectedInvoice.payer.ngrId);
        this.fetchLoads()
        this.getSelectedInvoiceCustomItems();
      })
  }

  getCompaniesMinimal(stopLoader) {
    const { dispatch } = this.props;
    dispatch(getPaymentTerms());
    dispatch(getGeneralConditions('invoice'));
    dispatch(getCompanyCompaniesMinimal(this.props.user.companyId, { include_parent_company: true }, null, stopLoader));
    dispatch(fetchBanks());
  }

  getCompanyEmployees(party, type = '', contactId = '', representingCompanyId) {
    const queryParams = representingCompanyId ? { representing_company_id: representingCompanyId } : {};
    type = this.getPartyType(type, party);
    APIService.contracts().companies(party.id).contacts().get(this.props.token, {}, queryParams)
      .then(employees => {
        this.setEmployeesAndContactForParty(type, employees, contactId);
      });
  }

  setEmployeesAndContactForParty(type, employees, contactId) {
    if (type) {
      this.setState({ [type + 'Employees']: employees }, () => {
        setTimeout(() => {
          this.updateSelectedContactForParty(contactId, type);
        }, 500);
      });
    }
  }

  updateNgrChanges(prevProps, newState) {
    if (this.props.updatedNgr && prevProps.updatedNgr !== this.props.updatedNgr) {
      this.updatePayeeNgrs(newState, this.props.updatedNgr);
    }

    if (this.props.updatedBillToNgr && prevProps.updatedBillToNgr !== this.props.updatedBillToNgr) {
      this.updatePayersNgrs(newState, this.props.updatedBillToNgr);
    }
  }

  updatePayeeNgrs(newState, updatedNgr) {
    if (updatedNgr.id === this.state.fields.payee.ngrId.value) {
      this.getPartyNgrs('payee', this.props.user.companyId, true, updatedNgr.id );
    }
  }

  updatePayerNgrs(newState, updatedNgr) {
    if (updatedNgr.id === this.state.fields.payer.ngrId.value) {
      this.getPartyNgrs('payer', newState.fields.payer.companyId.value, true, updatedNgr.id);
    }
  }

  updateSelectedContactForParty(contactId, type) {
    if (contactId) {
      if (type === 'payer') {
        this.handlePayerContactChange(contactId);
      } else if (type === 'payee') {
        this.handlePayeeContactChange(contactId);
      }
    }
  }

  handlePayeeContactChange(value, id = 'fields.payee.contactId') {
    this.setFieldValue(find(this.state.payeeEmployees, { id: value }), id);
  }

  handlePayerContactChange(value, id = 'fields.payer.contactId') {
    this.setFieldValue(find(this.state.payerEmployees, { id: value }), id);
  }

  getPartyType(type, party) {
    if (!type) {
      let selectedBilledToParty = get(this.state.fields.payer, 'company') || this.state.selectedBilledToParty;
      type = isEqual(party, selectedBilledToParty) ? 'payer' : 'payee';
    }
    return type;
  }

  handleIdentifierChange(event) {
    var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    this.setFieldValue(value, event.target.id);
  }

  setFieldValue(value, key) {
    const newState = { ...this.state };
    set(newState, `${key}.value`, value);
    this.setState(newState);
    if (key == 'fields.tenure' || key == 'payer.ngrId') {
      this.setFieldsEmpty(newState);
      this.setState(newState, this.fetchLoads)
    }
  }


  fetchLoads = () => {
    if (this.state.fetchingLoads || this.selectedInvoiceId)
      return;
    else
      this.setState({fetchingLoads: true});
    if (!this.state.fields.tenure.value)
      return;
    const partyCompanyId = get(this.state, 'selectedBilledToParty.id')
    let query_params = {party_company_id: partyCompanyId}
    if (this.isWeeklyWarehouseInvoiceFrequency()) {
      let weekDates = get(this.state.fields, 'tenure.value').split(' - ');
      query_params.start_date = weekDates[0];
      query_params.end_date = weekDates[1];
    }
    else {
      const year = get(this.state.fields, 'tenure.value').split('-')[0]
      if(!year || !partyCompanyId)
        return
      query_params.year = year;
      query_params.month = get(this.state.fields, 'tenure.value').split('-')[1];
    }
    if(this.state.fields.payer.ngrId.value)
      query_params['ngr_id'] = this.state.fields.payer.ngrId.value

    this.setState({
      selectedInloadsItems: [],
      selectedOutloadsItems: [],
      selectedThroughputInloadsItems: [],
      selectedThroughputOutloadsItems: [],
      selectedStoragesItems: [],
      selectedTransfersItems: [],
      selectedStockSwapItems: [],
      selectedRegradeReseasonItems: [],
      inloads: [],
      outloads: [],
      throughputInloads: [],
      throughputOutloads: [],
      storages: [],
      transfers: [],
      stockSwaps: [],
      regradeReseasons: [],
    }, () => {
      this.props.dispatch(isLoading('WarehouseInvoiceForm'))
      APIService.loads().appendToUrl('warehouse-invoices/pending/minimal/').get(null, null, query_params)
        .then((res) => {
          this.props.dispatch(forceStopLoader())
          if (res) {
            const newState = { ...this.state };
            newState.selectedInloadsItems = []
            newState.selectedOutloadsItems = []
            newState.selectedThroughputInloadsItems = []
            newState.selectedThroughputOutloadsItems = []
            newState.selectedStoragesItems = []
            newState.selectedTransfersItems = []
            newState.selectedStockSwapItems = []
            newState.selectedRegradeReseasonItems = []
            newState.inloads = []
            newState.outloads = []
            newState.throughputInloads = []
            newState.throughputOutloads = []
            newState.storages = []
            newState.transfers = []
            newState.stockSwaps = []
            newState.regradeReseasons = []
            newState.fetchingLoads = false;
            this.applyInvoiceItems('stockSwaps', res.stockSwaps, 'Stock Swaps', STOCK_SWAP_ITEM_TYPE, newState);
            this.applyInvoiceItems('regradeReseasons', res.regradeReseasons, 'Regrade Reseasons', REGRADE_RESEASON_ITEM_TYPE, newState);
            this.applyInvoiceItems('inloads', res.inloads, 'Inloads', 'warehouseinloaditem', newState);
            this.applyInvoiceItems('outloads', res.outloads, 'Outloads', 'warehouseoutloaditem', newState);
            this.applyInvoiceItems('throughputInloads', res.throughputInloads, 'Throughput Inloads', 'warehousethroughputinloaditem', newState);
            this.applyInvoiceItems('throughputOutloads', res.throughputOutloads, 'Throughput Outloads', 'warehousethroughputoutloaditem', newState);
            this.applyInvoiceItems('transfers', res.transfers, 'Transfers', 'warehousetransferitem', newState);
            this.applyStorageInvoiceItems('storages', res.storages, 'warehousestorageitem', newState);
            this.setState(newState, ()=>this.setSelectedItems());
          }
        });
    })
  }

handleAddItem(item) {
  const newState = { ...this.state };
  if (item.modifier && item.modifier === 'minus') {
    item.rate = -1 * item.rate
    item.subTotal = -1 * item.subTotal;
    if (item.gst) {
      item.gst = -1 * item.gst;
      item.total = -1 * item.total;
    }
  }
  if (item.gst === '') {
    item.gst = 'N/A';
    item.total = item.subTotal;
  }
  item.itemType = 'customitem';
  item.itemId = newState.customItemId;
  item.id = newState.customItemId;
  item.invoice = true;
  newState.customItems.push(item);
  newState.selectedItems.push(item);
  newState.openSideDrawer = false;
  newState.customItemId += 1;
  this.setState(newState);
}

async handlePayerChange(value, targetId, chosenItem) {
  const newState = { ...this.state };
  const isSameAsSelected = get(this.state.selectedBilledToParty, 'id') === get(chosenItem, 'id')
  if (value) {
    if (isEmpty(newState.selectedBilledToParty)) {
      newState.selectedBilledToParty = chosenItem;
      newState.fields.payer.companyId.value = value;
      this.setState(newState);
      this.getCompanyEmployees(chosenItem, 'payer');
      this.getPartyNgrs('payer', chosenItem.id);
    }
    else if(!isSameAsSelected) {
      alertifyjs
        .confirm(
          'Warning',
          'Already filled values in the Invoice will be reset. Do you wish to continue?',
          () => {
            newState.selectedBilledToParty = chosenItem;
            newState.fields.payer.companyId.value = value;
            set(newState, 'fields.tenure.value', '');
            this.setState(newState);
            this.getCompanyEmployees(chosenItem, 'payer');
            this.getPartyNgrs('payer', chosenItem.id);
          },
          () => { },
        )
        .set('labels', { ok: 'Yes', cancel: 'No' });
    }
  }
  else {
    set(newState, 'fields.tenure.value', '');
    set(newState, 'fields.paymentDueDate.value', '');
    set(newState, 'fields.payer', cloneDeep(this.payerFields));
    this.setFieldsEmpty(newState);
    this.setState(newState);
  }
}

setFieldsEmpty(newState){
  set(newState, 'inloads', []);
  set(newState, 'outloads', []);
  set(newState, 'throughputInloads', []);
  set(newState, 'throughputOutloads', []);
  set(newState, 'storages', []);
  set(newState, 'transfers', []);
  set(newState, 'stockSwaps', []);
  set(newState, 'regradeReseasons', []);
  set(newState, 'selectedStoragesItems', []);
  set(newState, 'selectedOutloadsItems', []);
  set(newState, 'selectedInloadsItems', []);
  set(newState, 'selectedThroughputOutloadsItems', []);
  set(newState, 'selectedThroughputInloadsItems', []);
  set(newState, 'selectedTransfersItems', []);
  set(newState, 'selectedStockSwapItems', []);
  set(newState, 'selectedRegradeReseasonItems', []);
}

getCustomItems() {
  return get(this.props.selectedInvoice, 'others') || []
}

applyInvoiceItems(field, results, fees_type, item_type, newState) {
  if (get(results, 'length', 0) > 0) {
    forEach(results, result => {
      let tonnage = 0;
      let description = '';
      let subTotal = 0;
      let commodityName = get(result, 'commodityName');
      let siteName = get(result, 'siteName');
      let loads = get(result, 'loads');
      let rate = get(result, 'rate');
      forEach(loads, load => {
        if(get(load, 'tonnage') != 0) {
          let ngr = get(load, 'ngr');
          if(this.isInEditMode)
            rate = get(load, 'rate', 0);
          tonnage = get(load, 'tonnage');
          subTotal = tonnage * rate;
          description = get(load, 'description')
          let shrunkTonnage = get(load, 'shrunkTonnage') || tonnage;
          if (shrunkTonnage)
            shrunkTonnage = Math.abs(shrunkTonnage)
          if(shrunkTonnage && (item_type === 'warehouseoutloaditem' || item_type === 'warehousethroughputoutloaditem')) {
            subTotal = shrunkTonnage * rate
          }
          let grade = get(load, 'grade')
          let season = get(load, 'season')
          if (subTotal < 0)
            subTotal = subTotal * -1;
          if (tonnage < 0)
            tonnage = tonnage * -1;
          let invoice = true;
          if (includes([STOCK_SWAP_ITEM_TYPE, REGRADE_RESEASON_ITEM_TYPE], item_type)) {
            description = description || get(result, 'description');
            invoice = subTotal != 0;
          }
          else
            description = description || get(result, 'description') || compact([fees_type, siteName, commodityName, grade, season, ngr]).join(' | ');
          let gst = parseFloat(subTotal * this.state.gstRate).toFixed(2);
          let invoiceItem = {
            description: description,
            tonnage: tonnage,
            rate: rate,
            subTotal: subTotal,
            gst: gst,
            shrunk_tonnage: shrunkTonnage,
            total: subTotal + parseFloat(gst),
            itemType: item_type,
            invoice: invoice,
            itemId: get(load, 'itemId') || get(load, 'id'),
            load_ids: get(load, 'loadIds'),
          };
          newState[`${field}`].push(invoiceItem);
          if (field == 'outloads')
            newState[`selectedOutloadsItems`].push(invoiceItem);
          else if (field == 'inloads')
            newState[`selectedInloadsItems`].push(invoiceItem);
          else if (field == 'throughputOutloads'){
            newState[`selectedThroughputOutloadsItems`].push(invoiceItem);
          }
          else if (field == 'throughputInloads')
            newState[`selectedThroughputInloadsItems`].push(invoiceItem);
          else if (field == 'stockSwaps' && invoice)
            newState[`selectedStockSwapItems`].push(invoiceItem);
          else if (field == 'regradeReseasons' && invoice)
            newState[`selectedRegradeReseasonItems`].push(invoiceItem);
          else if (field == 'transfers')
            newState[`selectedTransfersItems`].push(invoiceItem);
        }});
    });
  }
}

getTenureDisplay() {
  let tenureDates = this.state.fields.tenure.value.split(' - ');
  let startDate = tenureDates[0];
  let endDate = tenureDates[1];
  let [day, month, year] = startDate.split('/');
  startDate = new Date(year, month - 1, day);

  [day, month, year] = endDate.split('/');
  endDate = new Date(year, month - 1, day);
  return `${startDate.getDate()} ${startDate.toLocaleString('default', { month: 'short' })}, ${startDate.getFullYear()} - ${endDate.getDate()} ${endDate.toLocaleString('default', { month: 'short' })}, ${endDate.getFullYear()}`
}

applyStorageInvoiceItems(field, results, item_type, newState) {
  if (get(results, 'length', 0) > 0) {
    forEach(results, result => {
      let tonnage = 0;
      let description = '';
      let subTotal = 0;
      let commodityName = get(result, 'commodityName');
      let siteName = get(result, 'siteName');
      let timePeriods = get(result, 'timePeriods');
      let storages = get(result, 'storages');
      let rate = get(result, 'rate');
      forEach(storages, storage => {
        if(get(storage, 'tonnage') > 0)
        {
          tonnage = get(storage, 'tonnage');
          let ngr = get(storage, 'ngr');
          let grade = get(storage, 'grade')
          let season = get(storage, 'season')
          let shrunkTonnage = get(storage, 'shrunkTonnage') || tonnage;
          let actualTonnage = shrunkTonnage || tonnage;
          description = get(storage, 'description');
          if (this.isInEditMode)
            rate = get(storage, 'rate', 0);
          timePeriods = timePeriods || get(storage, 'timePeriod');
          subTotal = actualTonnage * rate * timePeriods;
          if (subTotal < 0)
            subTotal = subTotal * -1;
          if (tonnage < 0)
            tonnage = tonnage * -1;
          const tenure = this.isWeeklyWarehouseInvoiceFrequency() ? this.getTenureDisplay() : getMonthYear(newState.fields.tenure.value);
          description = description || compact(['Storage Fees', siteName, commodityName, grade, season, ngr, tenure]).join(' | ');

          let gst = parseFloat(subTotal * this.state.gstRate).toFixed(2);
          let invoiceItem = {
            description: description,
            tonnage: tonnage,
            shrunk_tonnage: shrunkTonnage,
            time_period:timePeriods,
            rate: rate,
            subTotal: subTotal,
            gst: gst,
            total: subTotal + parseFloat(gst),
            itemType: item_type,
            invoice: true,
            itemId: get(storage, 'itemId') || get(storage, 'id'),
            load_ids: get(storage, 'loadIds'),
          };
          newState[`${field}`].push(invoiceItem);
          newState.selectedStoragesItems.push(invoiceItem);
        }
      });
    });
  }
}

tonnageValue(item) {
  const isStrictQuantityBasedCommodity = get(this.props, 'contract.isStrictQuantityBasedCommodity');
  let tonnage = get(item, 'tonnage') || get(item, 'inferredTonnage');
  if (tonnage) return parseFloat(tonnage).toFixed(2) + ' ' + get(item, 'unit', isStrictQuantityBasedCommodity ? this.props.contract?.quantityUnit : 'MT');
}

shrunkTonnageValue(item) {
  const isStrictQuantityBasedCommodity = get(this.props, 'contract.isStrictQuantityBasedCommodity');
  let tonnage = get(item, 'shrunk_tonnage')
  let itemType = get(item, 'itemType');
  if (tonnage) return parseFloat(tonnage).toFixed(2) + ' ' + get(item, 'unit', isStrictQuantityBasedCommodity ? this.props.contract?.quantityUnit : 'MT');
  if (itemType != 'warehousestorageitem') return 'N/A';
}

noteHandler = val => {
  this.setState({ fields: { ...this.state.fields, note: val } });
};

openSideDraw() {
  this.commonOpenSideDrawer('openSideDrawer');
}

closeSideDraw() {
  const newState = { ...this.state };
  newState.openSideDrawer = false;
  this.setState(newState);
}

getPartyNgrs(party, partyId, updateNGRId=false, ngrId) {
  if (party == 'payer') {
    APIService.companies(partyId).ngrs().get()
      .then(items => {
        const newState = { ...this.state };
        newState.payerNgrs = items;
        newState.isFetchingNGR = true
        this.setState(newState,  () =>
          {
            if(updateNGRId){
              this.handlePayerNgrChange(ngrId);
            }
          });
      });
  }
  else if (party == 'payee') {
    APIService.companies(partyId).ngrs()
      .get()
      .then(items => {
        const newState = { ...this.state };
        newState.isFetchingNGR = true;
        remove(items, selectedNgr => { return selectedNgr && get(selectedNgr, 'ngrNumber').match(/UNKNOWN_/);});
        newState.payeeNgrs = items;
        this.setState(newState, () => {
          if(updateNGRId){
            this.handlePayeeNgrChange(ngrId);
          }
        });
      });
  }
}

commonOpenSideDrawer(type) {
  if (type !== 'ngrSideDrawerIsOpen') {
    this.closeNgrSideDrawer();
  }
  const sideDrawer = cloneDeep(DEFAULT_SIDE_DRAWERS_STATE);
  if (type) {
    sideDrawer[type] = true;
  }
  this.setState(sideDrawer);
}

handleConfirmDialogCancel() {
  this.setState({
    ...this.state,
    isConfirmDialogOpen: false,
  });
}

handleFormCancel = () => this.props.history.goBack()

handleItemClick = (item, checked, isSelectAll=false, actionItems='') => {
  const newState = {...this.state}

  var selectedItems = newState.selectedItems;
  var selectedCustomItems = newState.selectedCustomItems;
  var selectedStoragesItems = uniqBy(newState.selectedStoragesItems, 'description');
  var selectedInloadsItems = newState.selectedInloadsItems;
  var selectedOutloadsItems = newState.selectedOutloadsItems;
  var selectedThroughputInloadsItems = newState.selectedThroughputInloadsItems;
  var selectedThroughputOutloadsItems = newState.selectedThroughputOutloadsItems;
  var selectedTransfersItems = uniqBy(newState.selectedTransfersItems, 'description');
  var selectedStockSwapItems = uniqBy(newState.selectedStockSwapItems, 'description');
  var selectedRegradeReseasonItems = uniqBy(newState.selectedRegradeReseasonItems, 'description');
  var customItems = newState.customItems;
  var inloads = newState.inloads;
  var outloads = newState.outloads;
  var throughputInloads = newState.throughputInloads;
  var throughputOutloads = newState.throughputOutloads;
  var storages = newState.storages;
  var transfers = newState.transfers;
  var stockSwaps = newState.stockSwaps;
  var regradeReseasons = newState.regradeReseasons;

  const items = isSelectAll && actionItems ? actionItems : [item];
  forEach(items, item => {
    if(item.itemType === CUSTOM_ITEM_TYPE)
      this.updateSelectedItem(customItems, item, checked, CUSTOM_ITEM_TYPE);
    if(item.itemType === WAREHOUSE_STORAGE_ITEM_TYPE)
      this.updateSelectedItem(storages, item, checked, WAREHOUSE_STORAGE_ITEM_TYPE);
    if([WAREHOUSE_OUTLOAD_ITEM_TYPE, WAREHOUSE_INTLOAD_ITEM_TYPE, WAREHOUSE_THROUGHPUT_OUTLOAD_ITEM_TYPE, WAREHOUSE_THROUGHPUT_INLOAD_ITEM_TYPE].includes(item.itemType)) {
      this.updateSelectedItem(outloads, item, checked, WAREHOUSE_OUTLOAD_ITEM_TYPE, [inloads, throughputOutloads, throughputInloads]);
      this.updateSelectedItem(inloads, item, checked, WAREHOUSE_INTLOAD_ITEM_TYPE, [outloads, throughputOutloads, throughputInloads]);
      this.updateSelectedItem(throughputOutloads, item, checked, WAREHOUSE_THROUGHPUT_OUTLOAD_ITEM_TYPE, [throughputInloads, inloads, outloads]);
      this.updateSelectedItem(throughputInloads, item, checked, WAREHOUSE_THROUGHPUT_INLOAD_ITEM_TYPE, [throughputOutloads, outloads, inloads]);
    }
    if(item.itemType === WAREHOUSE_TRANSFER_ITEM_TYPE)
      this.updateSelectedItem(transfers, item, checked, WAREHOUSE_TRANSFER_ITEM_TYPE);
    if(item.itemType === STOCK_SWAP_ITEM_TYPE)
      this.updateSelectedItem(stockSwaps, item, checked, STOCK_SWAP_ITEM_TYPE);
    if(item.itemType === REGRADE_RESEASON_ITEM_TYPE)
      this.updateSelectedItem(regradeReseasons, item, checked, REGRADE_RESEASON_ITEM_TYPE);

    const isChargedAtOutload = this.props.user.company.warehouseChargedAt === 'outload'
    const isChargedAtTransferOrOutload = (this.props.user.company.warehouseChargedAt === 'outload/transfer' || this.props.user.company.warehouseChargedAt === 'outload_or_transfer_until_date')
    const isPayerGrower = get(this.state.selectedBilledToParty, 'typeId') === 1;
    if (checked) {
      if (item.itemType === CUSTOM_ITEM_TYPE)
        selectedCustomItems.push(item);
      if (item.itemType === WAREHOUSE_STORAGE_ITEM_TYPE)
        selectedStoragesItems.push(item);
      if (item.itemType === WAREHOUSE_INTLOAD_ITEM_TYPE) {
        selectedInloadsItems.push(item);
        if ((isChargedAtOutload || (isChargedAtTransferOrOutload && isPayerGrower))) {
          let outload = outloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
          selectedOutloadsItems.push(outload);
        }
        let throughput_inload = throughputInloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        let throughput_outload = throughputOutloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        if(throughput_inload)
          selectedThroughputInloadsItems.push(throughput_inload);
        if(throughput_outload)
          selectedThroughputOutloadsItems.push(throughput_outload);
      }
      if (item.itemType === WAREHOUSE_OUTLOAD_ITEM_TYPE) {
        selectedOutloadsItems.push(item);
        if ((isChargedAtOutload || (isChargedAtTransferOrOutload && isPayerGrower))) {
          let inload = inloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
          selectedInloadsItems.push(inload);
        }
        let throughput_outload = throughputOutloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        let throughput_inload = throughputInloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        if(throughput_outload)
          selectedThroughputOutloadsItems.push(throughput_outload);
        if(throughput_inload)
          selectedThroughputInloadsItems.push(throughput_inload);
      }
      if (item.itemType === WAREHOUSE_THROUGHPUT_INLOAD_ITEM_TYPE) {
        selectedThroughputInloadsItems.push(item);
        let throughput_outload = throughputOutloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        let outload = outloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        let inload = inloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        if(throughput_outload)
          selectedThroughputOutloadsItems.push(throughput_outload);
        if(outload)
          selectedOutloadsItems.push(outload);
        if(inload)
          selectedInloadsItems.push(inload);
      }
      if (item.itemType === WAREHOUSE_THROUGHPUT_OUTLOAD_ITEM_TYPE) {
        selectedThroughputOutloadsItems.push(item);
        let outload = outloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        let inload = inloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        let throughput_inload = throughputInloads.find(i => difference(i.load_ids, item.load_ids).length === 0);
        if(outload)
          selectedOutloadsItems.push(outload);
        if(inload)
          selectedInloadsItems.push(inload);
        if(throughput_inload)
          selectedThroughputInloadsItems.push(throughput_inload);
      }
      if (item.itemType === WAREHOUSE_TRANSFER_ITEM_TYPE)
        selectedTransfersItems.push(item);
      if (item.itemType === STOCK_SWAP_ITEM_TYPE)
        selectedStockSwapItems.push(item);
      if (item.itemType === REGRADE_RESEASON_ITEM_TYPE)
        selectedRegradeReseasonItems.push(item);
    } else {
      remove(selectedItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      remove(selectedCustomItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      remove(selectedStoragesItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      remove(selectedInloadsItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      remove(selectedOutloadsItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      remove(selectedThroughputInloadsItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      remove(selectedThroughputOutloadsItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      remove(selectedTransfersItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      remove(selectedStockSwapItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      remove(selectedRegradeReseasonItems, selectedItem => (selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType));
      if (item.itemType === WAREHOUSE_OUTLOAD_ITEM_TYPE && (isChargedAtOutload || (isChargedAtTransferOrOutload && isPayerGrower))){
        remove(selectedInloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
      }
      if (item.itemType === WAREHOUSE_INTLOAD_ITEM_TYPE && (isChargedAtOutload || (isChargedAtTransferOrOutload && isPayerGrower))){
        remove(selectedOutloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
      }
      if (item.itemType === WAREHOUSE_INTLOAD_ITEM_TYPE){
        remove(selectedThroughputOutloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
        remove(selectedThroughputInloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
      }
      if (item.itemType === WAREHOUSE_OUTLOAD_ITEM_TYPE){
        remove(selectedThroughputInloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
        remove(selectedThroughputOutloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
      }
      if (item.itemType === WAREHOUSE_THROUGHPUT_OUTLOAD_ITEM_TYPE){
        remove(selectedOutloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
        remove(selectedInloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
        remove(selectedThroughputInloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
      }
      if (item.itemType === WAREHOUSE_THROUGHPUT_INLOAD_ITEM_TYPE){
        remove(selectedOutloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
        remove(selectedThroughputInloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
        remove(selectedInloadsItems, selectedItem => difference(selectedItem.load_ids, item.load_ids).length === 0);
      }
    }
  });


  newState.selectedCustomItems = selectedCustomItems;
  newState.selectedStoragesItems = selectedStoragesItems;
  newState.selectedInloadsItems = selectedInloadsItems;
  newState.selectedOutloadsItems = selectedOutloadsItems;
  newState.selectedThroughputInloadsItems = selectedThroughputInloadsItems;
  newState.selectedThroughputOutloadsItems = selectedThroughputOutloadsItems;
  newState.selectedTransfersItems = selectedTransfersItems;
  newState.selectedStockSwapItems = selectedStockSwapItems;
  newState.selectedRegradeReseasonItems = selectedRegradeReseasonItems;
  newState.customItems = customItems;
  newState.inloads = inloads;
  newState.outloads = outloads;
  newState.throughputInloads = throughputInloads;
  newState.throughputOutloads = throughputOutloads;
  newState.storages = storages;
  newState.transfers = transfers;
  newState.stockSwaps = stockSwaps;
  newState.regradeReseasons = regradeReseasons;
  newState.selectedItems = selectedItems;
  this.setState(newState, () => {this.setSelectedItems();});
}

setSelectedItems = () => {
  const {selectedInloadsItems, selectedOutloadsItems, selectedStoragesItems,selectedRegradeReseasonItems, selectedTransfersItems, selectedCustomItems, selectedThroughputInloadsItems, selectedStockSwapItems, selectedThroughputOutloadsItems} = {...this.state};
  this.setState({
    selectedItems:[
      ...selectedCustomItems,
      ...selectedInloadsItems, ...selectedOutloadsItems,
      ...selectedStoragesItems, ...selectedStockSwapItems,
      ...selectedThroughputInloadsItems, ...selectedThroughputOutloadsItems,
      ...selectedTransfersItems, ...selectedRegradeReseasonItems
    ]})
}

updateSelectedItem(items, itemToBeUpdated, selected, itemType, counter_loads=null) {
  let item = {};
  let counter_load_item = {};
    const isChargedAtOutload = this.props.user.company.warehouseChargedAt === 'outload'
    const isChargedAtTransferOrOutload = (this.props.user.company.warehouseChargedAt === 'outload/transfer' || this.props.user.company.warehouseChargedAt === 'outload_or_transfer_until_date')
    const isPayerGrower = get(this.state.selectedBilledToParty, 'typeId') === 1;
  if (counter_loads)
    for(let j=0; j<counter_loads.length; j++){
      if (includes(['warehouseinloaditem', 'warehouseoutloaditem'], itemType) && (isChargedAtOutload || (isChargedAtTransferOrOutload && isPayerGrower)))
        counter_load_item = counter_loads[j].find(i => difference(i.load_ids, itemToBeUpdated.load_ids).length === 0);
      if (includes(['warehousethroughputinloaditem', 'warehousethroughputoutloaditem'], itemType))
        counter_load_item = counter_loads[j].find(i => difference(i.load_ids, itemToBeUpdated.load_ids).length === 0);
      if (counter_load_item) counter_load_item.invoice = selected;
    }
  item = items.find(i => i.itemId === itemToBeUpdated.itemId && itemToBeUpdated.itemType === itemType);
  if (item) item.invoice = selected;
  if (counter_load_item) counter_load_item.invoice = selected;
}

appendInvoiceConditions() {
  const newState = { ...this.state };
  if (isEmpty(newState.fields.invoiceConditions.value)){
    newState.fields.invoiceConditions.value = newState.selectedGeneralConditionText
  }
  else {
    newState.fields.invoiceConditions.value = newState.fields.invoiceConditions.value + '\n' + newState.selectedGeneralConditionText;
  }
  this.setState(newState);
}

onFieldBlur(event) {
  this.setFieldErrors(event.target.id);
}

getFieldErrors(key) {
  const errors = [];
  let value = '';
  let validators = [];
  if (this.state.fields[key]) {
    value = this.state.fields[key].value;
    validators = this.state.fields[key].validators;
  }
  if (validators) {
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
  }

  return errors;
}

setFieldErrors(key) {
  this.setState(state => ({
    ...state,
    fields: {
      ...state.fields,
      [key]: {
        ...state.fields[key],
        errors: this.getFieldErrors(key),
      },
    },
  }));
}

setAllFieldsErrors(callback) {
  const newState = { ...this.state };
  forEach(newState.fields, (value, key) => {
    newState.fields[key].errors = this.getFieldErrors(key);
  });

  if (this.state.displayPayeeBankForm) {
    applyValidatorsOn(newState.fields.payee);
  }
  this.setState(newState, callback);
  if (isEmpty(newState.fields.payee.ngrId.errors)) {
    this.setPayeeNgrErrors();
  }
}

handlePayeeBankChange = () => value => {
  const selectedBank = value ? this.props.banks.find(e => e.id === value) : { name: '', id: '' };
  const newState = { ...this.state };
  newState.fields.payee.bankName.value = selectedBank.name;
  newState.fields.payee.bankName.id = selectedBank.id;
  this.setState(newState, () => this.setPartyFieldErrors('payee.bankName'));
};

handlePayerBankChange = () => value => {
  const selectedBank = value ? this.props.banks.find(e => e.id === value) : { name: '', id: '' };
  const newState = { ...this.state };
  newState.fields.payer.bankName.value = selectedBank.name;
  newState.fields.payer.bankName.id = selectedBank.id;
  this.setState(newState);
};

setPartyFieldErrors = id => {
  const newState = { ...this.state };
  let errors = [];
  const validators = get(newState, `fields.${id}.validators`);
  const value = get(newState, `fields.${id}.value`);
  validators.forEach(validator => {
    if (validator.isInvalid(value)) {
      errors.push(validator.message);
    }
  });
  set(newState, `fields.${id}.errors`, errors);
  this.setState(newState);
};

openInvoiceConditionSideDrawer() {
  this.props.dispatch(clickAddGeneralConditionButton());
  this.commonOpenSideDrawer('invoiceConditionSideDrawerIsOpen');
}

closeInvoiceConditionSideDrawer() {
  this.setState({ invoiceConditionSideDrawerIsOpen: false });
}

handleConditionSelector(value, id, chosenItem) {
  var key = 'selectedGeneralConditionText';
  const newState = { ...this.state };

  if (chosenItem) {
    newState[key] = chosenItem.details;
    newState[id] = value;
  } else {
    newState[key] = undefined;
    newState[id] = undefined;
  }

  this.setState(newState);
}

handleAccountChangeForEntity(event, key) {
  const newState = { ...this.state };
  const id = event.target.id;
  const value = event.target.value
  newState.fields[key][id].value = value;
  if(id == 'bsbNumber' && value && isEqual(value.length, get(this.config, 'bank.bsbLength'))) {
    let bsbBank = find(this.props.banks, {bsbNumber: newState.fields[key][id].value});
    if(bsbBank) {
      newState.fields[key]['bankName'].id = bsbBank.id;
      newState.fields[key]['bankName'].value = bsbBank.name;
      newState.fields[key]['bankName'].errors = [];
    }
  }
  this.setState(newState, () => {
    if (key === 'payee') {
      this.setPartyFieldErrors(`${key}.${id}`);
    }
  });
}

openNgrSideDrawer(selectedEntity, ngrs, ngrId) {
  const { dispatch } = this.props;
  this.commonOpenSideDrawer();
  this.setUpdatedNgrToBrokerNgrField = false;
  dispatch(receiveCompanyNgrs(ngrs));
  dispatch(clickEditCompanyNgr(ngrId));
  this.setState({
    entityForNgrUpdate: (selectedEntity.company || selectedEntity)
  });
}

closeNgrSideDrawer() {
  const { dispatch } = this.props;
  dispatch(cancelEditNgr());
  dispatch(cancelEditCompanyNgr());
}

openPayeeSideDrawer() {
  this.props.handleAddEmployeeButtonClick();
  this.commonOpenSideDrawer('payeeSideDrawerIsOpen');
}

getAddBillToPartyContactButton() {
  const party = get(this.state.selectedBilledToParty, 'company') || this.state.selectedBilledToParty;
  return party && <AddCircleIconWithTooltip
                    id="addBillToContact"
                    onClick={() => this.openBillToContactSideDrawer(party)}
                    title="Add Bill To Contact"
                  />;
}

openBillToContactSideDrawer(party) {
  const selectedParty = get(party, 'company', party);
  const companyId = get(selectedParty, 'id');
  if (companyId) {
    const { dispatch } = this.props;
    dispatch(canCreateEmployee(companyId, 'company', () => {
      this.props.handleAddEmployeeButtonClick();
      this.commonOpenSideDrawer('billToSideDrawerIsOpen');
    }));
  }
}

closeBillToContactSideDrawer() {
  this.setState({ billToSideDrawerIsOpen: false });
}


getPayeeCompanyDetails() {
  return {
    'company': get(this.props, 'user.company'),
    'value': 'payee',
  };
}

getPayerPartyCompanyDetails() {
  return {
    'company': this.state.selectedBilledToParty,
    'value': 'payer',
  };
}

billToContactSideDrawer() {
  const billToParty = this.getPayerPartyCompanyDetails();
  const party = get(billToParty, 'company');
  const companyId = get(party, 'id');
  return this.props.isEmployeeSideDrawerOpened &&
    party &&
    <SideDrawer
      isOpen={this.state.billToSideDrawerIsOpen}
      title={getLabelForEmployeeForm(party)}
      onClose={this.closeBillToContactSideDrawer}
      app="officeEmployee"
      canCreate={true}
    >
      <CreateEmployee
        closeDrawer={this.closeBillToContactSideDrawer}
        canAccessAny={true}
        companyId={companyId}
        selectedCompany={party}
        establishmentDetails={billToParty}
        getContacts={this.getCompanyEmployees}
      />
    </SideDrawer>;
}

getPartyEmails() {
  return {
    billToParty: get(this.state.fields, 'payer.contactId.value.email'),
    warehouseCompany: get(this.state.fields, 'payee.contactId.value.email'),
  };
}

getPartyContacts() {
  return {
    billToParty: this.state.payerEmployees,
    warehouseCompany: this.state.payeeEmployees
  };
}

openCustomEmailDialog() {
  this.setState({ showCustomEmailDialog: true });
}

closeCustomEmailDialog(data, justClose) {
  if (justClose)
    this.setState({ showCustomEmailDialog: false });
  else if (this.state.showCustomEmailDialog) {
    let communicationData = {};
    if (!this.selectedInvoiceId) {
      if (data) {
        delete data.body;
        communicationData['communication'] = data;
      }
    } else {
    communicationData = this.getSubmitData();
    if (communicationData) {
      communicationData['communication'] = data;
    }}
    this.setState({ showCustomEmailDialog: false }, () => {
      if (this.selectedInvoiceId){
        this.props.editInvoice(communicationData, this.selectedInvoiceId, null);
      }
      else
        this.props.generateInvoice(this.props.invoiceId, null, communicationData);
    });
  }
}

alertPermissionError() {
  alertifyjs.alert(
    'Permission Denied',
    `<div className=""><p>You do not have permission to create ngr for the party because:</p><ul><li>You are not authorized.Please contact Company Admin</li></ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
    () => { },
  );
}

openAddNgrSideDrawer(ngrEntity, type) {
  ngrEntity.type = type;
  const companyId = get(ngrEntity, 'id');
  if (companyId && isCurrentUserBelongsToCompany(companyId)) {
    const { dispatch } = this.props;
    const selectedEntity = find(this.props.allCompanyParties, {id: companyId});
    dispatch(canCreateNgr(companyId, 'company', selectedEntity, () => {
      this.props.handleAddCompanyNgrButtonClick();
      this.props.handleAddNgrButtonClick();
      this.setState({ ngrEntity: ngrEntity }, () => this.commonOpenSideDrawer('ngrSideDrawerIsOpen'));
    }));
  }
  else {
    this.alertPermissionError();
  }
}

closeAddNgrSideDrawer() {
  this.setState({ ngrSideDrawerIsOpen: false });
}

closePayeeContactSideDrawer() {
  this.setState({ payeeSideDrawerIsOpen: false });
}


handleSubmit(e) {
  e.preventDefault();
  this.setAllFieldsErrors(() => {
    let isPayeeValid = true;
    const isFormInvalid = some(this.state.fields, field => {
      return field.errors.length > 0;
    });
    isPayeeValid = every(this.state.fields.payee, key => {
      return isEmpty(get(key, 'errors'));
    });
    if (this.state.selectedItems.length === 0) {
      alertifyjs.error('Please select at least one item to be invoiced');
      return;
    }
    if (!isFormInvalid && isPayeeValid) {
      this.props.dispatch(isLoading('invoiceDetail'));
      let submittableData = this.getSubmitData();
      if (isArray(get(submittableData, 'invoiceItems'))) {
        submittableData['invoiceItems'].forEach(item => delete item.unit);
      }
      if (this.selectedInvoiceId)
        this.props.createAmendInvoice(submittableData, response => this.setState({xeroCounterPartyExists: response.hasCounterPartyXeroContact}));
      else
        this.props.submit(submittableData, true, response => this.setState({xeroCounterPartyExists: response.hasCounterPartyXeroContact}));
      this.handlePreviewClick();
    }
  });
  this.focusOnFirstErrorField();
}

handlePreviewSubmit() {
  this.openCustomEmailDialog();
}

handlePreviewClick() {
  this.setState({
    ...this.state,
    preview: true,
  });
}

handlePreviewClose() {
  this.setState({
    ...this.state,
    preview: false,
  });
  this.props.deleteInvoice(this.props.invoiceId);
}


focusOnFirstErrorField() {
  const autoCompleteFields = ['paymentDueDate', 'tenure'];
  const nestedFields = ['payee.ngrId', 'payee.accountName', 'payee.accountNumber', 'payee.bsbNumber', 'payee.accountNumber'];

  for (let i = 0; i < this.fieldsOrder.length; i++) {
    const formField = this.fieldRef[this.fieldsOrder[i]];
    const field = this.state.fields[this.fieldsOrder[i]];

    if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
      if (this.fieldsOrder[i] === 'payee.bankName' && this.state.fields.payee.bankName.errors.length > 0){
        getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
        break;
      }
      if ((this.fieldsOrder[i] === 'payee.ngrId' && this.state.fields.payee.ngrId.errors.length > 0) ||
          (this.fieldsOrder[i] === 'payee.accountName' && this.state.fields.payee.accountName.errors.length > 0) ||
          (this.fieldsOrder[i] === 'payee.accountNumber' && this.state.fields.payee.accountNumber.errors.length > 0) ||
          (this.fieldsOrder[i] === 'payee.bsbNumber' && this.state.fields.payee.bsbNumber.errors.length > 0)
         ) {
        formField.current.focus();
        break;
      }
    } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
      if (field && field.errors.length > 0) {
        getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
        formField.current.focus();
        break;
      }
    } else if (field && field.errors.length > 0) {
      if (formField.current.node) {
        formField.current.node.previousSibling.focus();
        break;
      } else {
        formField.current.focus();
        break;
      }
    }
  }
}

getMonthTenure() {
  let [year, month] = this.state.fields.tenure.value.split('-').map(Number);
  let firstDate = new Date(year, month - 1, 1);
  let lastDate = new Date(year, month, 0);
  let firstDateString = firstDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  let lastDateString = lastDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  return `${firstDateString} - ${lastDateString}`;
}

getSubmitData() {
  const data = mapValues(this.state.fields, field => {
    return field.value;
  });
  data.raisedForType = 'warehouse';
  this.setPayerData(data);
  this.setPayeeData(data);

  data.invoiceItems = cloneDeep(this.state.selectedItems);
  data.invoiceItems.forEach(item => {
    if (item.itemType === 'customitem') {
      delete item['itemId'];
      delete item['id'];
      if (item['gst'] === 'N/A') {
        item['gst'] = 0;
      }
    }

    delete item['invoice'];
  });
  data.subTotal = this.state.subTotal;
  data.gst = this.state.gst;
  data.total = this.state.total;
  data.type = 'WarehouseFee';
  data.tenure = this.state.fields.tenure.value;
  data.paymentDueDate = this.state.fields.paymentDueDate.value
  if (!this.isWeeklyWarehouseInvoiceFrequency())
    data.tenure = this.getMonthTenure();
  data.tonnage = reduce(
    this.state.inloads,
    function (sum, item) {
      return sum + parseFloat(item.tonnage);
    },
    0,
  ) + reduce(
    this.state.outloads,
    function (sum, item) {
      return sum + parseFloat(item.tonnage);
    },
    0,
  ) +
    reduce(
      this.state.storages,
      function (sum, item) {
        return sum + parseFloat(item.tonnage);
      },
      0,
  ) +
  reduce(
    this.state.stockSwaps,
    function (sum, item) {
      return sum + parseFloat(item.tonnage);
    },
    0,
  ) +
  reduce(
    this.state.regradeReseasons,
    function (sum, item) {
      return sum + parseFloat(item.tonnage);
    },
    0,
  )
  data.note = omit(this.state.fields.note, ['errors']);
  return data;
}

setPayeeData(data) {
  data['payee'] = {};
  data['payee']['companyId'] = this.props.user.companyId;
  data['payee']['contactId'] = get(this.state.fields, 'payee.contactId.value.id');
  data['payee']['ngrId'] = get(this.state.fields, 'payee.ngrId.value');
  this.setEntityBankDetails(data, 'payee');
}

setEntityBankDetails(data, key) {
  data[key]['bankAccount'] = {};
  data[key]['bankAccount']['bank_id'] = get(find(this.props.banks, {name: this.state.fields[key].bankName.value}), 'id', '');
  data[key]['bankAccount']['accountName'] = this.state.fields[key].accountName.value;
  data[key]['bankAccount']['bsbNumber'] = this.state.fields[key].bsbNumber.value;
  data[key]['bankAccount']['accountNumber'] = this.state.fields[key].accountNumber.value;
}

setPayerData(data) {
  data['payer'] = {};
  data['payer']['companyId'] = this.state.selectedBilledToParty.id || get(this.state.fields, 'payer.companyId.value');
  data['payer']['contactId'] = get(this.state.fields, 'payer.contactId.value.id');
  data['payer']['ngrId'] = get(this.state.fields, 'payer.ngrId.value');
  this.setEntityBankDetails(data, 'payer');
}

handlePayeeNgrChange(id) {
  const newState = { ...this.state };
  this.closeNgrSideDrawer();
  if (id) {
    let payee = find(newState.payeeNgrs, { id: id });
    newState.fields.payee.ngrId.value = get(payee, 'id');
    newState.fields.payee.ngrId.validators = [required()];
    newState.displayPayeeBankForm = false;
    newState.fields.payee.accountNumber.value = get(payee, 'bankAccounts[0].accountNumber');
    newState.fields.payee.bankName.value = get(payee, 'bankAccounts[0].name');
    newState.fields.payee.bsbNumber.value = get(payee, 'bankAccounts[0].bsbNumber');
    newState.fields.payee.accountName.value = get(payee, 'bankAccounts[0].accountName');
  } else {
    newState.fields.payee.ngrId.validators = [];
    newState.displayPayeeBankForm = true;
    this.setPartyFieldEmpty(newState, 'payee');
  }
  this.setState(newState, () => this.setPayeeNgrErrors());
}

handlePayerNgrChange(id) {
  const newState = { ...this.state };
  this.closeNgrSideDrawer();
  if (id) {
    if(id === this.state.fields.payer.ngrId.value)
      return
    let payer = find(this.state.payerNgrs, { id: id });
    newState.fields.payer.ngrId.value = get(payer, 'id');
    newState.displayPayerBankForm = false;
    newState.fields.payer.accountNumber.value = get(payer, 'bankAccounts[0].accountNumber');
    newState.fields.payer.bankName.value = get(payer, 'bankAccounts[0].name');
    newState.fields.payer.bsbNumber.value = get(payer, 'bankAccounts[0].bsbNumber');
    newState.fields.payer.accountName.value = get(payer, 'bankAccounts[0].accountName');
    this.setState(
      newState, () => {
        if (this.selectedInvoiceId)
          this.fetchLoads
      });
  } else if(this.state.isSettingValuesForEdit) {
    return
  } else {
    this.setPartyFieldEmpty(newState, 'payer');
    newState.displayPayerBankForm = true;
    this.setState(
      newState, () => {
        if (this.selectedInvoiceId)
          this.fetchLoads
      });
  }
  this.setState(newState, this.setPayerNgrErrors());
}

setPartyFieldEmpty(newState, field) {
  newState.fields[`${field}`].ngrId.value = '';
  newState.fields[`${field}`].accountNumber.value = '';
  newState.fields[`${field}`].bankName.value = '';
  newState.fields[`${field}`].bsbNumber.value = '';
  newState.fields[`${field}`].accountName.value = '';
}
setPayerNgrErrors = () => {
  const newState = { ...this.state };
  this.getPayerNgrErrors(newState);
  this.setState(newState);
};

getPayerNgrErrors(newState) {
  newState.fields.payee.ngrId.errors = [];
  if (newState.fields.payer.ngrId.value) {
    if (
      isEmpty(newState.fields.payer.accountNumber.value) ||
        isEmpty(newState.fields.payer.bankName.value) ||
        isEmpty(newState.fields.payer.bsbNumber.value) ||
        isEmpty(newState.fields.payer.accountName.value))
      newState.fields.payer.ngrId.errors = isCurrentUserBelongsToCompany(newState.fields.payer.companyId.value)?[NGR_MISSING_DETAILS_ERROR]:[NGR_MISSING_DETAILS_ERROR_WITHOUT_ACCESS];
    else
      newState.fields.payer.ngrId.errors = [];
    newState.fields.payer.bankName.errors = [];
    newState.fields.payer.accountNumber.errors = [];
    newState.fields.payer.bsbNumber.errors = [];
    newState.fields.payer.accountName.errors = [];
  }
  else if(!isEmpty(newState.fields.payer.ngrId.validators))
    newState.fields.payer.ngrId.errors = ['This field cannot be blank'];
}

setPayeeNgrErrors = () => {
  const newState = { ...this.state };
  this.getPayeeNgrErrors(newState);
  this.setState(newState);
};

getPayeeNgrErrors(newState) {
  newState.fields.payee.ngrId.errors = [];
  if (newState.fields.payee.ngrId.value) {
    if (
      isEmpty(newState.fields.payee.accountNumber.value) ||
        isEmpty(newState.fields.payee.bankName.value) ||
        isEmpty(newState.fields.payee.bsbNumber.value) ||
        isEmpty(newState.fields.payee.accountName.value))
      newState.fields.payee.ngrId.errors = [NGR_MISSING_DETAILS_ERROR];
    else
      newState.fields.payee.ngrId.errors = [];
    newState.fields.payee.bankName.errors = [];
    newState.fields.payee.accountNumber.errors = [];
    newState.fields.payee.bsbNumber.errors = [];
    newState.fields.payee.accountName.errors = [];
  }
  else if(!isEmpty(newState.fields.payee.ngrId.validators))
    newState.fields.payee.ngrId.errors = ['This field cannot be blank'];
}

setDefaultGeneralCondition(condition) {
  this.closeInvoiceConditionSideDrawer();
  if (condition) {
    this.handleConditionSelector(condition.id, 'generalConditionsSelector', condition);
  }
}

// eslint-disable-next-line no-unused-vars
handleNgrCreation = (companyId, submitData) => {
  if(isEqual(this.state.ngrEntity.typeId, 1))
    this.setState({ payerNgrs: [...this.state.payerNgrs, submitData], selectedPayerNgr: submitData });
  else if(isEqual(this.state.ngrEntity.typeId, 4)) {
      const newState = { ...this.state };
      newState.payeeNgrs = [...this.state.payeeNgrs, submitData];
      newState.fields.payee.ngrId.value = get(submitData, 'id');
      this.setState(newState);
  }
}

handleWeekChange = (value, id) => {
  const newState = { ...this.state };
  set(newState, `${id}.value`, value);
  this.setFieldsEmpty(newState);
  this.setState(newState, this.fetchLoads)
}

isWeeklyWarehouseInvoiceFrequency = () => get(this.state.currentUserCompany, 'warehouseInvoiceFrequency') === 'weekly';


render() {
  const COLUMNS = [
    { key: 'invoice', header: 'Select Item', checkbox: true, onChange: 'handleItemClick' },
    { key: 'description', header: 'Description' },
    { valueFunction: this.tonnageValue, header: 'Tonnage' },
    { valueFunction: this.shrunkTonnageValue, header: 'Shrunk Tonnage' },
    { key: 'rate', header: 'Rate' },
    { key: 'subTotal', header: `Price (Ex ${getCountryLabel('gst')})` },
    { key: 'gst', header: `${getCountryLabel('gst')}` },
    { key: 'total', header: `Total (Inc ${getCountryLabel('gst')})` },
  ];
  const currency = getCountryCurrency()
  let payeeAccountNumber = this.state.fields.payee.accountNumber.value;
  const bankItems = this.props.banks && uniqBy(this.props.banks.map(e => ({ label: e.name, value: e.id })), 'label');
  const editPayeeNgrButton = (this.state.fields.payee.ngrId.value) ?
    <Button variant="outlined" onClick={() => this.openNgrSideDrawer(this.props.user.company, this.state.payeeNgrs, get(this.state.fields.payee.ngrId, 'value'))} className="edit-ngr-button">
      Update NGR Details
    </Button>
  : null;
  let selectedBilledToParty = get(this.state.selectedBilledToParty, 'company') || this.state.selectedBilledToParty;
  const editPayerNgrButton = get(this.state.fields.payer.ngrId, 'value') ?
    <Button variant="outlined" onClick={() => this.openNgrSideDrawer(this.state.selectedBilledToParty, this.state.payerNgrs, get(this.state.fields.payer.ngrId, 'value'))} className="edit-ngr-button">
      {(!isTransactionParticipated(selectedBilledToParty) && isCurrentUserBelongsToCompany(selectedBilledToParty?.id)) ? 'Update NGR Details' : 'View NGR Details'}
    </Button>
  : null;
  const addPayeeNgrButton = <Tooltip title="Add Payee NGR" placement="top">
                              <AddCircleIcon id="addPayeeNgr" onClick={() => this.openAddNgrSideDrawer(this.props.user.company, "Payee")}
                                             className="material-icons add-new-right"
                                             style={{ fontSize: '30px' }} />
                            </Tooltip>;
  const addPayerNgrButton = selectedBilledToParty &&
    <Tooltip title="Add Bill To NGR" placement="top">
      <AddCircleIcon id="addPayerNgr" onClick={() => this.openAddNgrSideDrawer(selectedBilledToParty, "Payer")}
                     className="material-icons add-new-right"
                     style={{ fontSize: '30px' }} />
    </Tooltip>;
  const isWeeklyWarehouseInvoiceFrequency = this.isWeeklyWarehouseInvoiceFrequency();
  const warehouseInvoiceWeekStart = get(this.state.currentUserCompany, 'warehouseInvoiceStartOfWeek');

  return (
    <div ref={this.formRef}>
      <form className='invoice-form' onSubmit={this.handleSubmit} noValidate>
        <div className='cardForm'>
          <div className='dom-for-allocation-loader hide'></div>
          <h4 className='cardForm-title'>General</h4>
          <div className='cardForm-content'>
            <div className='col-md-5'>
              <div className='col-md-12 padding-reset form-wrap'>
                <TextField
                  id='fields.identifier'
                  error={!isEmpty(this.state.fields.identifier.errors[0])}
                  placeholder='Please enter 14 digit unique number'
                  label='Invoice No'
                  inputRef={this.fieldRef['identifier']}
                  value={this.state.fields.identifier.value}
                  helperText={this.state.fields.identifier.errors[0]}
                  onChange={this.handleIdentifierChange}
                  onBlur={this.onFieldBlur}
                  fullWidth
                  inputProps={{
                    maxLength: 14,
                  }}
                  variant='standard'
                  disabled={Boolean(this.selectedInvoiceId)}
                />
              </div>
              <div className='col-md-12 padding-reset form-wrap'>
                <CommonAutoSelect
                  id='payer.companyId'
                  setRef={this.fieldRef['payer']}
                  label='Bill To'
                  dontAutoselectSingleItem={true}
                  items={this.state.payerParties}
                  value={this.state.fields.payer.companyId.value}
                  dataSourceConfig={{ text: 'displayName', value: 'id' }}
                  onBlur={this.onFieldBlur}
                  onChange={this.handlePayerChange}
                  errorText={get(this.state, 'fields.payer.companyId.errors[0]')}
                  selectedItem={this.state.selectedBilledToParty}
                  disabled={this.isEdit || this.selectedInvoiceId}
                />
              </div>
              <div className='col-md-12 padding-reset form-wrap'>
                <CommonDatePicker
                  id='fields.paymentDueDate'
                  setRef={this.fieldRef['paymentDueDate']}
                  floatingLabelText='Payment Due Date'
                  errorText={get(this.state, 'fields.paymentDueDate.errors[0]')}
                  value={this.state.fields.paymentDueDate.value}
                  onChange={this.setFieldValue}
                  disabled={!this.state.selectedBilledToParty}
                />
              </div>
            </div>
            <div className='col-md-5 col-md-offset-1'>
              <div className='col-md-12 padding-reset form-wrap'>
                {
                  isWeeklyWarehouseInvoiceFrequency ?
                  <CommonWeekSelector
                    id='fields.tenure'
                    label='Tenure'
                    errorText={get(this.state, 'fields.tenure.errors[0]')}
                    weekStart={warehouseInvoiceWeekStart}
                    onChange={this.handleWeekChange}
                    value={this.state.fields.tenure.value}
                    disabled={!this.state.selectedBilledToParty || this.isEdit || Boolean(this.selectedInvoiceId)}
                    numberOfWeeks={15}
                  />
                   :
                   <CommonDatePicker
                    id='fields.tenure'
                    setRef={this.fieldRef['tenure']}
                    floatingLabelText='Tenure'
                    errorText={get(this.state, 'fields.tenure.errors[0]')}
                    value={this.state.fields.tenure.value}
                    onChange={this.setFieldValue}
                    type='month'
                    disabled={!this.state.selectedBilledToParty || this.isEdit || Boolean(this.selectedInvoiceId)}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div className='cardForm'>
          <h4 className='cardForm-title'>Invoicing Parties</h4>
          <div className='cardForm-content'>
            <div className='col-md-5'>
              <div className='col-md-12 padding-reset form-wrap'>
                <CommonTextField id='payer' label='Bill To' value={get(this.state.selectedBilledToParty, 'displayName')} disabled />
              </div>
              <div className='col-md-12 padding-reset form-wrap'>
                <CommonAutoSelect
                  id='fields.payer.contactId'
                  setRef={this.fieldRef['payerContact']}
                  label='Contact'
                  items={uniqBy(this.state.payerEmployees, 'id')}
                  value={this.state.fields.payer.contactId.value}
                  onBlur={this.onFieldBlur}
                  onChange={this.handlePayerContactChange}
                  errorText={this.state.fields.payer.contactId.errors[0]}
                  selectedItem={this.state.fields.payer.contactId.value || this.state.selectedPayerContact}
                />
                {this.getAddBillToPartyContactButton()}
              </div>
              <div className='col-md-12 padding-reset form-wrap'>
                <CommonSelect
                  id='payer.ngrId'
                  label={this.state.displayPayerBankForm ? 'Enter Bank Details' : 'Filter by NGR'}
                  floatingLabelText={this.state.displayPayerBankForm ? 'Enter Bank Details' : 'Filter by NGR'}
                  value={get(this.state.selectedPayerNgr, 'id')}
                  items={this.state.payerNgrs}
                  selectConfig={{ text: 'ngrNumber', value: 'id' }}
                  fullWidth
                  onBlur={this.onFieldBlur}
                  onChange={this.handlePayerNgrChange}
                  errorText={this.state.fields.payer.ngrId.errors[0]}
                  includeEmptyOption={isCurrentUserBelongsToCompany(this.state.selectedBilledToParty?.id)}
                  emptyOptionText='Enter Bank Details'
                  dontAutoselectSingleItem
                  disabled={this.isEdit}
                />
                {addPayerNgrButton}
                {editPayerNgrButton}
              </div>
              {this.state.displayPayerBankForm ? (
                <div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='bsbNumber'
                      label={`${getCountryLabel('bsb')} Number (Optional)`}
                      value={this.state.fields.payer.bsbNumber.value}
                      onChange={event => this.handleAccountChangeForEntity(event, 'payer')}
                      placeholder={`Please enter ${get(this.config, 'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
                      type='number'
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, get(this.config, 'bank.bsbLength'));
                      }}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <AutoComplete
                      id='bankName'
                      label='Bank Name (Optional)'
                      placeholder='Bank Name (Optional)'
                      options={bankItems}
                      fullWidth
                      errorText={this.state.fields.payer.bankName.errors[0]}
                      onBlur={this.onFieldBlur}
                      value={this.state.fields.payer.bankName.value}
                      onChange={this.handlePayerBankChange()}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='accountName'
                      label='Account Name (Optional)'
                      value={this.state.fields.payer.accountName.value}
                      onChange={event => this.handleAccountChangeForEntity(event, 'payer')}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='accountNumber'
                      label='Account Number (Optional)'
                      value={this.state.fields.payer.accountNumber.value}
                      type='number'
                      onChange={event => this.handleAccountChangeForEntity(event, 'payer')}
                      placeholder={`Please enter ${get(this.config, 'bank.accountNumberMinimumLength')}-${get(this.config, 'bank.accountNumberMaximumLength')} digits Account number`}
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, get(this.config, 'bank.accountNumberMaximumLength'));
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className='col-md-5 col-md-offset-1'>
              <div className='col-md-12 padding-reset form-wrap'>
                <CommonTextField id='payee' label='Warehouse Company' value={this.props.user.company.name} disabled />
              </div>
              <div className='col-md-12 padding-reset form-wrap'>
                <CommonAutoSelect
                  id='fields.payee.contactId'
                  setRef={this.fieldRef['payeeContact']}
                  label='Contact'
                  items={this.state.payeeEmployees}
                  value={this.state.fields.payee.contactId.value}
                  onBlur={this.onFieldBlur}
                  onChange={this.handlePayeeContactChange}
                  errorText={this.state.fields.payee.contactId.errors[0]}
                  selectedItem={this.state.fields.payee.contactId.value}
                />
                <AddCircleIconWithTooltip id='addPayeeContact' onClick={() => this.openPayeeSideDrawer()} title='Add Payee Contact' />
              </div>
              <div className='col-md-12 padding-reset form-wrap'>
                <CommonSelect
                  id='payee.ngrId'
                  floatingLabelText={this.state.displayPayeeBankForm ? 'Enter Bank Details' : 'NGR'}
                  items={this.state.payeeNgrs}
                  selectConfig={{ text: 'ngrNumber', value: 'id' }}
                  onBlur={this.onFieldBlur}
                  fullWidth
                  setRef={this.fieldRef['payee.ngrId']}
                  onChange={this.handlePayeeNgrChange}
                  errorText={this.state.fields.payee.ngrId.errors[0]}
                  value={get(this.state.fields.payee.ngrId, 'value')}
                  includeEmptyOption={true}
                  emptyOptionText='Enter Bank Details'
                />
                {addPayeeNgrButton}
                {editPayeeNgrButton}
              </div>
              {this.state.displayPayeeBankForm ? (
                <div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='bsbNumber'
                      label={`${getCountryLabel('bsb')} Number (Optional)`}
                      value={this.state.fields.payee.bsbNumber.value}
                      onChange={event => this.handleAccountChangeForEntity(event, 'payee')}
                      placeholder={`Please enter ${get(this.config, 'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
                      setRef={this.fieldRef['payee.bsbNumber']}
                      type='number'
                      helperText={this.state.fields.payee.bsbNumber.errors[0]}
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, get(this.config, 'bank.bsbLength'));
                      }}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <AutoComplete
                      id='bankName'
                      label='Bank Name'
                      placeholder='Bank Name'
                      options={bankItems}
                      fullWidth
                      setRef={this.fieldRef['payee.bankName']}
                      errorText={this.state.fields.payee.bankName.errors[0]}
                      onBlur={this.onPartyFieldBlur}
                      value={this.state.fields.payee.bankName.value}
                      onChange={this.handlePayeeBankChange()}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='accountName'
                      label='Account Name'
                      value={this.state.fields.payee.accountName.value}
                      setRef={this.fieldRef['payee.accountName']}
                      helperText={this.state.fields.payee.accountName.errors[0]}
                      onChange={event => this.handleAccountChangeForEntity(event, 'payee')}
                    />
                  </div>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonTextField
                      id='accountNumber'
                      label='Account Number'
                      value={this.state.fields.payee.accountNumber.value}
                      onChange={event => this.handleAccountChangeForEntity(event, 'payee')}
                      placeholder={`Please enter ${get(this.config, 'bank.accountNumberMinimumLength')}-${get(this.config, 'bank.accountNumberMaximumLength')} digits Account number`}
                      helperText={this.state.fields.payee.accountNumber.errors[0]}
                      setRef={this.fieldRef['payee.accountNumber']}
                      type='number'
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, get(this.config, 'bank.accountNumberMaximumLength'));
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className='cardForm'>
          <h4 className='cardForm-title'>Items To Be Invoiced</h4>
          <div style={{ position: 'relative' }}>
            <AddButton
              disabled={!this.state.fields.payer.companyId.value}
              variant="contained"
              label='Custom Item'
              primary={true}
              className={'btn-green'}
              onClick={this.openSideDraw}
              style={{ float: 'right', marginRight: '20px', marginTop: '0px', position: 'absolute', right: '0px', top: '-50px' }}
            />
          </div>
          <div>
            <SideDrawer isOpen={this.state.openSideDrawer} title='Add Item' onClose={this.closeSideDraw}>
              <WarehouseInvoiceCustomItemForm closeDrawer={this.closeSideDraw} addItem={this.handleAddItem} currency={currency} />
            </SideDrawer>
          </div>

          <div>
            <InvoiceTable
              columns={COLUMNS}
              customItems={this.state.customItems}
              inloads={this.state.inloads}
              outloads={this.state.outloads}
              throughputInloads={this.state.throughputInloads}
              throughputOutloads={this.state.throughputOutloads}
              storages={this.state.storages}
              transfers={this.state.transfers}
              stockSwaps={this.state.stockSwaps}
              regradeReseasons={this.state.regradeReseasons}
              handleItemClick={this.handleItemClick}
              hideFreightItems='true'
              currency={currency}
            />
            {some(this.state.customItems, item => item.gst === 'N/A') && <div>* {getCountryLabel('gst')} is not applicable</div>}
          </div>
        </div>

        <div className='cardForm'>
          <h4 className='cardForm-title'>Invoice Conditions</h4>
          <div className='cardForm-content'>
            <div className='col-md-5 form-wrap'>
              <CommonAutoSelect
                items={this.props.generalConditionsAll}
                id='generalConditionsSelector'
                value={this.state.generalConditionsSelector}
                label='Invoice'
                onChange={this.handleConditionSelector}
                dontAutoselectSingleItem
              />
              <AddCircleIconWithTooltip
                id='addInvoiceCondition'
                title='Add Invoice Condition'
                onClick={() => this.openInvoiceConditionSideDrawer()}
              />
              <CommonTextField
                className='top15 text-black'
                id='invoiceConditionViewer'
                value={this.state.selectedGeneralConditionText}
                fullWidth
                multiline={true}
                rows={1}
                disabled={true}
                rowsMax={1}
              />
            </div>
            <div className='cardForm-content col-md-1 arrow-button'>
              <IconButton
                disabled={!isNumber(this.state.generalConditionsSelector)}
                onClick={this.appendInvoiceConditions}
                size="large">
                <ArrowForward color='primary' />
              </IconButton>
            </div>
            <div className='cardForm-content col-md-5'>
              <div className='field-label'>Conditions (Optional)</div>
              <TextField
                className='text-area'
                id='invoiceConditions'
                value={this.state.fields.invoiceConditions.value}
                fullWidth
                multiline={true}
                onChange={this.setFieldValue}
                rows={6}
              />
            </div>
          </div>
        </div>
        <div className='cardForm'>
          <h4 className='cardForm-title'>Internal Notes (Optional)</h4>
          <div className='cardForm-content col-md-5'>
            <Notes
              title=''
              handler={this.noteHandler}
              note={this.state.fields.note}
              disabled={false}
            />
          </div>
        </div>
        <div className='cardForm'>
          <h4 className='cardForm-title'>Summary</h4>
          <div className='summary-section' style={{ marginLeft: '11px', marginBottom: '10px' }}>
            Against the Invoice ({this.state.fields.identifier.value}) raised by {this.props.user.company.name} ,{' '}
            {get(this.state.selectedBilledToParty, 'name')} agrees to pay {formatPrice(this.state.total, 'N/A', currency)} to {this.props.user.company.name} on
            the mentioned account number #{payeeAccountNumber} by {moment(this.state.fields.paymentDueDate.value).format(this.state.countryFormats.dateDisplay)}.
          </div>
          <div className='cardForm-content' style={{ marginLeft: '30%' }}>
            <div className='col-md-12 padding-reset form-wrap'>
              <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                <div className='col-md-6 padding-reset left'>
                  <b>Sub-total (Ex {getCountryLabel('gst')})</b>
                </div>{' '}
                <div className='col-md-6 padding-reset right'>{`${formatPrice(this.state.subTotal, 'N/A', currency)}`}</div>
              </div>
              <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                <div className='col-md-6 padding-reset left'>
                  <b>{getCountryLabel('gst')}</b>
                </div>{' '}
                <div className='col-md-6 padding-reset right'>{`${formatPrice(this.state.gst, 'N/A', currency)}`}</div>
              </div>
              <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                <div className='col-md-6 padding-reset left'>
                  <b>Total</b>
                </div>{' '}
                <div className='col-md-6 padding-reset right'>{`${formatPrice(this.state.total, 'N/A', currency)}`}</div>
              </div>
            </div>
          </div>

          <div className='col-md-12 cardForm-action' style={{ marginLeft: '25px' }}>
            <CommonButton label='Cancel' default={true} variant='outlined' onClick={this.handleFormCancel} />
            <CommonButton type='submit' label='Continue and Review' primary={true} variant="contained" />
          </div>
        </div>
        <Dialog open={Boolean(this.state.isConfirmDialogOpen)} onClose={this.handleConfirmDialogCancel} scroll='paper'>
          <DialogTitle>All the values entered by you will be lost.</DialogTitle>
          <DialogContent>Are you sure want to cancel?</DialogContent>
          <DialogActions>
            <CommonButton key='no' label='No' color="default" variant="outlined" onClick={this.handleConfirmDialogCancel} />
            <CommonButton key='yes' label='Yes' primary={true} onClick={this.handleConfirmDialogOk} variant='flat' />
          </DialogActions>
        </Dialog>
        <Dialog open={Boolean(this.state.preview)} onClose={this.handlePreviewClose} scroll='paper' fullScreen>
          <DialogTitle>Invoice Preview</DialogTitle>
          <DialogContent>
            <InvoicePreview ref={el => (this.componentRef = el)} isWarehouseInvoice/>
          </DialogContent>
          <DialogActions style={{paddingBottom: "40px"}}>
            <CommonButton label='Back' key='closeButton' default onClick={this.handlePreviewClose} variant='flat' />
            <CommonButton label='Submit' key='submitButton' primary={true} onClick={this.handlePreviewSubmit} variant='flat' />
          </DialogActions>
        </Dialog>
      </form>

      <SideDrawer isOpen={Boolean(this.props.isUpdateFormDisplayed)} title='Edit NGR' size='big' onClose={this.closeNgrSideDrawer}>
        <UpdateCompanyNgr
          companyId={get(this.state.entityForNgrUpdate, 'id')}
          company={this.state.entityForNgrUpdate}
          canAccessAny={canAccessAny(this.state.entityForNgrUpdate)}
          bankAccountDetailsMandatory={true}
        />
      </SideDrawer>
      {this.state.ngrEntity && this.props.isCreateNgrFormDisplayed &&
       <SideDrawer
         isOpen={Boolean(this.state.ngrSideDrawerIsOpen)}
         title="Add NGR"
         size="big"
         onClose={this.closeAddNgrSideDrawer}
       >
         <CreateCompanyNgr companyId={this.state.ngrEntity.id} company={this.state.ngrEntity} canAccessAny={true} closeDrawer={this.closeAddNgrSideDrawer} onSuccess={this.handleNgrCreation} bankAccountDetailsMandatory={true} />
       </SideDrawer>
      }

      {this.props.isEmployeeSideDrawerOpened && <SideDrawer
                                                  isOpen={Boolean(this.state.payeeSideDrawerIsOpen)}
                                                  title={getLabelForEmployeeForm(this.props.user.company)}
                                                  onClose={this.closePayeeContactSideDrawer}
                                                  app="officeEmployee"
                                                  canCreate={true}
                                                >
                                                  <CreateEmployee
                                                    closeDrawer={this.closePayeeContactSideDrawer}
                                                    canAccessAny={true}
                                                    companyId={get(this.props, 'user.company.id')}
                                                    selectedCompany={get(this.props, 'user.company')}
                                                    selectedFarm={null}
                                                    establishmentDetails={this.getPayeeCompanyDetails()}
                                                    getContacts={this.getCompanyEmployees}
                                                  />
                                                </SideDrawer>
      }
      {this.state.ngrEntity &&
       get(this.state.ngrEntity, 'establishmentContentType', get(this.state.ngrEntity, 'entity')) === 'company' &&
       this.props.isCreateNgrFormDisplayed && (
         <SideDrawer isOpen={Boolean(this.state.ngrSideDrawerIsOpen)} title='Add NGR' size='big' onClose={this.closeAddNgrSideDrawer}>
           <CreateCompanyNgr
             companyId={this.state.ngrEntity.id}
             company={this.state.ngrEntity}
             canAccessAny={true}
             closeDrawer={this.closeAddNgrSideDrawer}
             onSuccess={this.handleNgrCreation}
             bankAccountDetailsMandatory={true}
           />
         </SideDrawer>
       )}

      {this.state.ngrEntity &&
       get(this.state.ngrEntity, 'establishmentContentType', get(this.state.ngrEntity, 'entity')) === 'farm' &&
       this.props.isCreateFormDisplayed && (
         <SideDrawer isOpen={Boolean(this.state.ngrSideDrawerIsOpen)} title='Add NGR' size='big' onClose={this.closeAddNgrSideDrawer}>
           <CreateCompanyNgr
             company={this.state.ngrEntity}
             farmId={this.state.ngrEntity.id}
             canAccessAny={true}
             closeDrawer={this.closeAddNgrSideDrawer}
             onSuccess={this.handleNgrCreation}
             bankAccountDetailsMandatory={true}
           />
         </SideDrawer>
       )}
      {this.props.isEmployeeSideDrawerOpened && (
        <SideDrawer
          isOpen={Boolean(this.state.payeeSideDrawerIsOpen)}
          title={getLabelForEmployeeForm(this.props.user.company)}
          onClose={this.closeBrokerContactSideDrawer}
          app='officeEmployee'
          canCreate={true}
        >
          <CreateEmployee
            closeDrawer={this.closePayeeContactSideDrawer}
            canAccessAny={true}
            companyId={get(this.props, 'user.company.id')}
            selectedCompany={get(this.props, 'user.company')}
            selectedFarm={null}
            establishmentDetails={this.getPayeeCompanyDetails()}
            getContacts={this.getCompanyEmployees}
          />
        </SideDrawer>
      )}

      {this.billToContactSideDrawer()}
      {this.props.isGeneralConditionSideDrawerOpened && (
        <SideDrawer
          isOpen={Boolean(this.state.invoiceConditionSideDrawerIsOpen)}
          title='Add Invoice Condition'
          onClose={this.closeInvoiceConditionSideDrawer}
          app='condition'
        >
          <CreateGeneralCondition
            selectedConditionType='invoice'
            closeDrawer={this.closeInvoiceConditionSideDrawer}
            onSuccess={this.setDefaultGeneralCondition}
          />
        </SideDrawer>
      )}
      {
        get(this.props.invoiceCreateResponse, 'defaultSubject') &&
          this.state.showCustomEmailDialog && (
            <CustomEmailDialog
              parties={['billToParty', 'warehouseCompany']}
              selectedParties={['billToParty']}
              title='Email PDF copies to'
              subject={this.props.invoiceCreateResponse.defaultSubject}
              noBody
              open
              onClose={this.closeCustomEmailDialog}
              partyEmails={this.getPartyEmails()}
              partyContacts={this.getPartyContacts()}
              currentUserCompany={this.state.currentUserCompany}
              xero={this.state.currentUserCompany.xeroEnabled}
              xeroCounterPartyExists={this.state.xeroCounterPartyExists}
              disableAcceptanceRequired={Boolean(this.selectedInvoiceId)}
            />
          )
      }
    </div>
  );
}
}

const mapDispatchToProps = dispatch => {
  return {
    handleAddEmployeeButtonClick: () => {
      dispatch(clickAddEmployeeButton());
    },
    createAmendInvoice: (data, successCallback) => {
      dispatch(createAmendInvoice(data, false, true, successCallback));
    },
    editInvoice: (data, invoiceId, contractId) => {
      dispatch(editInvoice(data, invoiceId, contractId));
    },
    handleAddCompanyNgrButtonClick: () => dispatch(clickAddCompanyNgrButton()),
    handleAddNgrButtonClick: () => dispatch(clickAddNgrButton()),
    submit: (data, warehouseInvoice, successCallback) => {
      dispatch(createInvoice(data, false, warehouseInvoice, successCallback));
    },
    deleteInvoice: (invoiceId) => {
      dispatch(deleteInvoice(invoiceId));
    },
    generateInvoice: (invoiceId, contractId, data) => {
      dispatch(generateInvoice(invoiceId, contractId, null, data));
    },
    dispatch,
  };
};

const mapStateToProps = state => {
  const banks = state.main.banks || [];
  return {
    user: state.main.user.user,
    allCompanyParties: state.companies.companies.companyParties,
    isEmployeeSideDrawerOpened: state.companySettings.employees.isCreateFormDisplayed || state.companies.farmEmployees.isCreateFormDisplayed,
    token: state.main.user.token,
    generalConditionsAll: state.companies.conditions.generalConditions,
    invoiceId: state.companies.invoices.selectedInvoiceId,
    selectedInvoice: state.companies.invoices.selectedInvoice,
    isUpdateFormDisplayed: state.companies.companyNgrs.isUpdateFormDisplayed,
    isUpdateFormForBillToDisplayed: state.companies.ngrs.isUpdateFormDisplayed,
    updatedBillToNgr: state.companies.ngrs.updatedNgr,
    updatedNgr: state.companies.companyNgrs.updatedNgr,
    banks,
    serverErrors: state.companies.invoices.serverErrors,
    isCreateNgrFormDisplayed: state.companies.companyNgrs.isCreateFormDisplayed,
    isCreateFormDisplayed: state.companies.ngrs.isCreateFormDisplayed,
    isGeneralConditionSideDrawerOpened: state.companies.conditions.isCreateFormDisplayed,
    invoiceCreateResponse: state.companies.invoices.invoiceCreateResponse,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseInvoiceForm);
