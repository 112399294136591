import React, { useEffect } from 'react';
import { Button, TextField, Autocomplete, Checkbox, Tooltip } from '@mui/material';
import CommonDatePicker from '../common/CommonDatePicker';
import { useState } from 'react';
import CommonSelect from '../common/select/CommonSelect';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import { get, map, remove, uniqBy, compact, filter, forEach, find, some, isEmpty, has, startCase} from 'lodash';
import APIService from '../../services/APIService';
import { useDispatch } from 'react-redux';
import { currentUser, getDateTimeInUTC, getDateTimeFromUTC, generateIdentifier, downloadFileFromBlob, getSeasons, getCountryLabel, getCurrentCountry, currentUserCompany, isCompanyGrower, isSystemCompany, isObserver, getCountryDisplayUnit } from "../../common/utils";
import { createRegradedStock, emptyStorages, updateRegradedStocks, updateStorageStocks } from '../../actions/companies/storages';
import { isLoading, forceStopLoader } from '../../actions/main'
import InputAdornment from '@mui/material/InputAdornment';
import { positiveDecimalFilter } from '../../common/input-filters';
import moment from 'moment';
import CommonTimePicker from '../common/CommonTimePicker';
import alertifyjs from 'alertifyjs';
import CommonTextField from '../common/CommonTextField';
import { MESSAGES, required, valueBetween } from '../../common/validators';
import CustomEmailDialog from '../common/CustomEmailDialog';
import isEqual from 'lodash/isEqual';
import CommonListingButton from '../common/CommonListingButton';
import { COMMODITIES, FIELD, OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STOCK_UPDATE_LOAD, OPTION_TYPE_STORAGE_EMPTY_LOAD, OPTION_TYPE_STORAGE_UPDATE_LOAD, STRICT_COMMODITY_BASED_UNITS } from '../../common/constants';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';
import SpecParametersValue from '../common/SpecParametersValue';
import set from 'lodash/set';
import ContentRemove from '@mui/icons-material/Remove';
import SideDrawer from '../common/SideDrawer';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';

let UpdateStorageFilters = props => {
  const [identifierState, setIdentifierState] = React.useState({})
  const displayUnit = getCountryDisplayUnit()
  const [site, setSite] = React.useState({});
  let [combinedStates, setCombinedStates] = useState({
    'commodity_id__in': [get(props.item, 'stocks.commodityId') || get(props.item, 'commodityId')],
    'farm_id': get(props.item, 'farmId', get(props.item, 'stocks.farmId')),
    'storage_id__in': [props.item?.storageId || get(props.item, 'id')],
    'ngr_id__in': [props.item?.ngrId || get(props.item, 'stocks.ngrId')],
    'season__in': props.isStoragesView || props.isSiteLoadsView ? [] : [props.item?.season || get(props.item, 'stocks.season')],
    'grade_id__in': props.isStoragesView || props.isSiteLoadsView ? [] : [props.item?.gradeId || get(props.item, 'stocks.gradeId')],
    'variety_id__in': props.isStoragesView || props.isSiteLoadsView ? [] : (props.item?.varietyId || get(props.item, 'stocks.varietyId')) ? [props.item?.varietyId || get(props.item, 'stocks.varietyId')] : [],
    'company_id__in': compact([props.item?.ngrCompanyId || get(props.item, 'stocks.ngr.companyId')]),
    'current_tonnage': props.item?.tonnage || get(props.item, 'stocks.currentTonnage'),
    'tonnage': '',
    'recommodity_id': get(props.item, 'stocks.commodityId') || get(props.item, 'commodityId'),
    'regrade_id': '',
    'reseason': '',
    'reseasoned_date': '',
    'reseasoned_time': '',
    'identifier': props.regradingStock ? generateIdentifier('regraded') : generateIdentifier('load'),
    'comment': '',
    'differential': '',
    'specs': {},
    'quantity': '',
    'stocks_before_date': '',
    'stocks_before_time': '',
  });

  let [combinedErrors, setCombinedErrors] = useState({
    'farm': '',
    'storage': '',
    'ngr': '',
    'tonnage': '',
    'commodity': '',
    'grade': '',
    'variety': '',
    'regrade': '',
    'reseason': '',
    'season': '',
    'identifier': '',
    'reseasoned_date': '',
    'reseasoned_time': '',
    'differential': '',
    'quantity': '',
    'stocks_before_date': '',
    'stocks_before_time': '',
  });
  const dispatch = useDispatch();

  let [allLocations, setAllLocations] = useState([]);

  let [commoditySpecs, setCommoditySpecs] = useState([]);

  let [allStorages, setAllStorages] = useState({});

  let [farmStorages, setFarmStorages] = useState([]);

  let [allSeason, setSeason] = useState([]);

  let [allGrade, setGrades] = useState([]);
  let [regradeGrades, setRegradeGrades] = useState([]);

  let [allNgr, setNgrs] = useState([]);

  let [allCompanies, setAllCompanies] = useState({});

  let [allCommodities, setAllCommodities] = useState({});
  let [interchangeableCommodities, setInterchangeableCommodities] = useState({});

  let [showEmailDialog, setShowEmailDialog] = useState(false);
  let [payloadData, setPayloadData] = useState(null);
  let [partyEmails, setPartyEmails] = useState({});
  let [partyContacts, setPartyContacts] = useState({});

  let [gotOncePartyContacts, setGotOncePartyContacts] = useState(false);
  let [regradedLoad, setRegradedLoad] = useState(false);
  let [load, setLoad] = useState({});
  let [isWarehouseInvoiced, setIsWarehouseInvoiced] = useState(false);

  let [manageOwnership, setManageOwnership] = useState(false);
  let [storagesLoads, setStoragesLoads] = useState([]);

  let [manageStorages, setManageStorages] = useState(false);
  let [stockLoads, setStockLoads] = useState([]);

  let getSeason = () => setSeason(map(getSeasons(), season => ({id: season, name: season})));

  const fetchSite = () => {
    const siteId = combinedStates?.farm_id
    if(siteId && siteId !== site?.id)
      APIService.farms(siteId).get(null, null, {properties: 'is_variety_mandatory,user_type_for_variety_mandatory,load_type_for_variety_mandatory'}).then(response => setSite(response))
  }

  let getAllCompanies = async () => {
    const user = currentUser();
    let DirectoryCompanies = await APIService.companies().appendToUrl(`directory/names/?excludeGroups=true`).get(props.token);
    DirectoryCompanies = uniqBy([...DirectoryCompanies, { id: user.companyId, name: user.company.name, typeId: user.company.typeId }], 'id');
    setAllCompanies(DirectoryCompanies);
  };

  let refreshManageOwnershipStorageLoads = () => {
    if (manageOwnership || manageStorages)
      handleSubmit(true);
  };

  let onLocationChange = async (val, id) => {
    if (val !== combinedStates[id]) {
      setCombinedStates({ ...combinedStates, [id]: val });
      if (val) {
        let farm = find(allLocations, {id: val});
        if (farm) {
          const user = currentUser();
          if(farm?.stocksManagement && farm.brokerCompanyId !== user.companyId && farm.companyId !== user.companyId)
            setCombinedErrors({ ...combinedErrors, 'farm': 'Stocks for this site can only be updated by site employees' });
          else
            setCombinedErrors({ ...combinedErrors, 'farm': '' });
        }
      }
    }
  };

  let onFieldChange = (id, selectedItems) => {
    if (id == 'storage_id__in') {
      setCombinedStates({ ...combinedStates, 'storage_id__in': map(selectedItems, 'id') });
      if (isEmpty(selectedItems))
        setCombinedErrors({ ...combinedErrors, 'storage': 'Field cannot be blank' });
      else
        setCombinedErrors({ ...combinedErrors, 'storage': '' });
    }

    if (id == 'ngr_id__in') {
      setCombinedStates({ ...combinedStates, 'ngr_id__in': map(selectedItems, 'id') });
      if (isEmpty(selectedItems))
        setCombinedErrors({ ...combinedErrors, 'ngr': 'Field cannot be blank' });
      else
        setCombinedErrors({ ...combinedErrors, 'ngr': '' });
    };
  };

  let onTonnageChange = event => {
    let _tonnage = event.target.value
    setCombinedStates({ ...combinedStates, 'tonnage': _tonnage });
    if (isEmpty(_tonnage)) {
      setCombinedErrors({ ...combinedErrors, 'tonnage': 'Field cannot be blank' });
    }
    else if (props.regradingStock && includes(["0", "0.0", "0.", "0.00"], _tonnage))
        setCombinedErrors({ ...combinedErrors, 'tonnage': `${getCountryLabel('tonnage')} cannot be 0.` });
    else if (_tonnage === ".")
      setCombinedErrors({ ...combinedErrors, 'tonnage': `Enter valid ${getCountryLabel('tonnage').toLowerCase()}.` });
    else {
      if (props.regradingStock && Number(_tonnage) > availableTonnage.toFixed(2)) {
        setCombinedErrors({ ...combinedErrors, 'tonnage': `Cannot be greater than existing ${getCountryLabel('tonnage').toLowerCase()}` });
      } else {
        setCombinedErrors({ ...combinedErrors, 'tonnage': '' });
      }
    }
    setQuantityErrors(combinedStates.quantity, _tonnage);
  };

  let setQuantityErrors = (quantity, tonnage) => {
    if (props.updateStorage && hasQuantityBasedCommodity(get(combinedStates, 'commodity_id__in.0'))) {
      if (Number(tonnage) > availableTonnage.toFixed(2) && Number(quantity) < get(props.item, 'quantity')) {
        setCombinedErrors({ ...combinedErrors, 'quantity': 'Quantity cannot be less than current quantity'});
      }
      else if (Number(tonnage) < availableTonnage.toFixed(2) && Number(quantity) > get(props.item, 'quantity')) {
        setCombinedErrors({ ...combinedErrors, 'quantity': 'Quantity cannot be greater than current quantity'});
      }
      else {
        setCombinedErrors({ ...combinedErrors, 'quantity': '' });
      }
    }
  }

  let onQuantityChange = event => {
    let quantity = event.target.value;
    setCombinedStates({ ...combinedStates, 'quantity': quantity });
    if (combinedStates.tonnage)
      setQuantityErrors(quantity, combinedStates.tonnage);
  }

  let getAllowedLength = value => {
    return isEmpty(value) ? 4 : (value.charAt(0) == '-' ? 5 : 4);
  };

  let onDifferentialChange = event => {
    if (isEmpty(event.target.value))
      setCombinedErrors({ ...combinedErrors, 'differential': 'Field cannot be blank' });
    else
      setCombinedErrors({ ...combinedErrors, 'differential': '' });
      const value = event.target.value;
      const arr = value.split('.');
      if(arr.length <= 2) {
        if (1 in arr) {
          if (arr[1].length <= 2)
            setCombinedStates({ ...combinedStates, 'differential': event.target.value });
        }
        else if (arr[0].length <= getAllowedLength(arr[0])) {
          setCombinedStates({ ...combinedStates, 'differential': event.target.value });
        }
      }
  };

  let onCommodityChange = async (id, selectedItems) => {
    setCombinedStates({ ...combinedStates, 'commodity_id__in': map(selectedItems, 'id') });
    let grades = [];
    forEach(selectedItems, val => {
      grades = [...grades, ...val.grades];
    });
    setGrades(grades);
  };

  let onInterchangeableCommodityChange = val => {
    setCombinedStates({...combinedStates, 'recommodity_id': val})
    const commodity = find(allCommodities, {id: val});
    if (commodity) {
      setRegradeGrades([...commodity.grades]);
    }
  };

  let onReseasonedDateTimeChange = async (val, id) => {
    setCombinedStates({ ...combinedStates, [id]: val });
    if (props.regradingStock) {
      if (isEmpty(val) && !val)
        setCombinedErrors({ ...combinedErrors, [id]: 'Field cannot be blank' });
      else
        setCombinedErrors({ ...combinedErrors, [id]: '' });
    };
  };

  let onStocksBeforeDateTimeChange = async (val, id) => {
    setCombinedStates({ ...combinedStates, [id]: val });
    if (props.emptyStorage) {
      if (isEmpty(val) && !val)
        setCombinedErrors({ ...combinedErrors, [id]: 'Field cannot be blank' });
      else
        setCombinedErrors({ ...combinedErrors, [id]: '' });
    };
  };

  let onRegradeChange = async(id, selectedItems) => {
    let val = get(selectedItems, 'id');
    if (val !== get(combinedStates, 'regrade_id.0')) {
      setCombinedStates({ ...combinedStates, ['regrade_id']: [val] });
    }
    if (!props.isStoragesView && props.regradingStock){
      if (isEmpty(val) && !val)
        setCombinedErrors({ ...combinedErrors, 'regrade': 'Field cannot be blank' });
      else
        setCombinedErrors({ ...combinedErrors, 'regrade': '' });
    };
  };

  let onUpdateStockFieldsChange = async (val, id) => {
    val != undefined ? setCombinedStates({ ...combinedStates, [id]: [val] }) : setCombinedStates({ ...combinedStates, [id]: [] });
    if (id == 'commodity_id__in' && val) {
      let grades = [];
      const selectedCommodities = get(combinedStates, 'commodity_id__in')
      let commodity = filter(allCommodities, obj => includes(selectedCommodities, obj));
      commodity.forEach(i => {
        grades = [...i.grades];
      });
      setGrades(grades);
      let commoditySpecs = get(commodity, '0.specs', []);
      setCommoditySpecs(commoditySpecs);
      if (props.updateStorage && props.isStoragesView)
        setCommoditySpecSummary(val, get(combinedStates.storage_id__in, '0'), commoditySpecs);
    }

    if (id == 'storage_id__in') {
      if (!val)
        setCombinedErrors({ ...combinedErrors, 'storage': 'Field cannot be blank' });
      else {
        setCombinedErrors({ ...combinedErrors, 'storage': '' });
        if (props.updateStorage && props.isStoragesView)
          setCommoditySpecSummary(get(combinedStates.commodity_id__in, '0'), val, commoditySpecs);
      }
    }
    if (id == 'variety_id__in') {
      let companyIds = compact(combinedStates.company_id__in || []);
      if(props.isStoragesView)
        companyIds = [currentUserCompany().id]
      let isMandatory = await isVarietyMandatoryBasedOnUserSettingAndStockOwner([...companyIds])
      if (!val && isMandatory)
        setCombinedErrors({ ...combinedErrors, 'variety': 'This field can not be blank' });
      else
        setCombinedErrors({ ...combinedErrors, 'variety': '' });
    }

    if (id == 'ngr_id__in' && !val) {
      if (isEmpty(val))
        setCombinedErrors({ ...combinedErrors, 'ngr': 'Field cannot be blank' });
      else
        setCombinedErrors({ ...combinedErrors, 'ngr': '' });
    };

    if (id == 'season__in' && !props.isStoragesView && props.updateStorage){
      if (isEmpty(val))
        setCombinedErrors({ ...combinedErrors, 'season': 'Field cannot be blank' });
      else
        setCombinedErrors({ ...combinedErrors, 'season': '' });
    };

    if (id == 'season__in' && val)
      setCombinedErrors({ ...combinedErrors, 'season': '' });

    if (id == 'grade_id__in' && !props.isStoragesView && props.updateStorage){
      if (isEmpty(val) && !val)
        setCombinedErrors({ ...combinedErrors, 'grade': 'Field cannot be blank' });
      else
        setCombinedErrors({ ...combinedErrors, 'grade': '' });
    };

    if (id == 'grade_id__in' && val)
      setCombinedErrors({ ...combinedErrors, 'grade': '' });

    if (id == 'reseason' && !props.isStoragesView && props.regradingStock){
      if (isEmpty(val))
        setCombinedErrors({ ...combinedErrors, 'reseason': 'Field cannot be blank' });
      else
        setCombinedErrors({ ...combinedErrors, 'reseason': '' });
    };
  };

  useEffect(() => {
    setAllGrades();
  }, [allCommodities, combinedStates.commodity_id__in, combinedStates.recommodity_id]);

  let getAllCommodities = async () => {
    let commodityData = await APIService.commodities().get(props.token, null, { includes: 'grade', simplify_names: true, countryId: getCurrentCountry().id });
    commodityData.map(val => {
      val.grades.map(val2 => {
        return { name: val2.name, parent: val.id, id: val2.id };
      });
    });
    setAllCommodities(commodityData);
    let commodity = filter(commodityData, obj => obj.id == get(combinedStates, 'commodity_id__in.0'));

    let commoditySpecs = get(commodity, '0.specs', [])
    setCommoditySpecs(commoditySpecs);
    if (props.updateStorage && props.isStoragesView)
      setCommoditySpecSummary(get(combinedStates, 'commodity_id__in.0'), get(combinedStates, 'storage_id__in.0'), commoditySpecs)

    if (!isEmpty(combinedStates.commodity_id__in)) {
      let grades = [];
      commodity.forEach(val => {
        grades = [...val.grades];
      });
      setGrades(grades);
    }
  };

  let setCommoditySpecSummary = (commodityId, storageId, commoditySpecs) => {
    if (commodityId && storageId) {
      APIService.storages(storageId)
        .appendToUrl(`loads/minimal/?commodity_id=${commodityId}&summary=true&only_specs_summary=true`)
        .get()
        .then(resp => {
          let specsSummary = get(resp, 'specs');
          let specs = getCommoditySpec(commoditySpecs);
          Object.keys(specsSummary).forEach(spec => {
            set(specs, `${spec.toUpperCase()}.value`, parseFloat((specsSummary[spec]).toFixed(2)))
          })
          setCombinedStates({...combinedStates, 'specs': specs});
        })
    }
  }

  useEffect(() => {
    let specs = getCommoditySpec(commoditySpecs)
    if(!regradedLoad)
      setCombinedStates({...combinedStates, 'specs': specs});
  }, [commoditySpecs])

  useEffect(() => {
    fetchSite()
    if (manageOwnership)
      setStoragesLoads([]);
    else if (manageStorages)
      refreshManageOwnershipStorageLoads();
  }, [combinedStates?.farm_id])

  useEffect(() => {
    if (props.updateStorage)
      refreshManageOwnershipStorageLoads()
  }, [combinedStates?.storage_id__in, combinedStates?.commodity_id__in, combinedStates?.grade_id__in, combinedStates?.season__in])

  useEffect(() => {
    if(commoditySpecs && load) {
      let specs = getCommoditySpec(commoditySpecs)
      if(!regradedLoad)
        setCombinedStates({...combinedStates, 'specs': specs});
    }
  }, [commoditySpecs, load])

  let handleSpecsChange = (specCode, specValue) => {
    let specs = {...combinedStates.specs}, errors = [];
    set(specs, `${specCode}.value`, specValue)
    setCombinedStates({
      ...combinedStates,
      specs
    })

    errors = setFieldErrors(`specs.${specCode}`);
    set(specs, `${specCode}.errors`, errors)
    setCombinedStates({
      ...combinedStates,
      specs
    })
  }

  let validateSpecs = () => {
    let specs = {...combinedStates.specs}, isSpecsInvalid = false;
    forEach(combinedStates.specs, (specValue, specCode) => {
      let errors = [];
      errors = setFieldErrors(`specs.${specCode}`);
      if(!isEmpty(errors))
        isSpecsInvalid = true;
      set(specs, `${specCode}.errors`, errors)
    })
    setCombinedStates({
      ...combinedStates,
      specs
    })
    return isSpecsInvalid;
  }

  let setFieldErrors = path => {
    const errors = [];
    const value = get(combinedStates, `${path}.value`);
    const validators = get(combinedStates, `${path}.validators`, []);
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  let getCommoditySpec = commoditySpecs => {
    const modelSpecs = {};
    if (!isEmpty(commoditySpecs) && !props.emptyStorage) {
      forEach(orderBy(commoditySpecs, 'order'), (spec) => {
        let validators = [valueBetween(spec.min, spec.max, true)];
        if (get(combinedStates, 'commodity_id__in.0') === COMMODITIES.CANOLA && includes(['COIL', 'IMPU'], spec.code)) {
          validators.push(required());
        }
        modelSpecs[spec.code] = {
          ...FIELD,
          value: get(load.specs, `${spec.code.toLowerCase()}`, ''),
          validators,
        };
      });
    }
    return modelSpecs;
  }

  let getStorageIdName = storage => {
    return {
      id: storage.id,
      name: storage.name
    };
  };

  let getAllStorages = () => {
    const { farm_id } = combinedStates;
    if (!isEmpty(get(allStorages, farm_id))) {
      setFarmStorages(get(allStorages, farm_id));
    }
    else {
      APIService
        .farms(farm_id)
        .storages()
        .appendToUrl('home/?no_stocks&no_aggregations&no_relations')
        .get(props.token)
        .then(storages => {
          let data = map(storages, getStorageIdName);
          setAllStorages({ [farm_id]: data });
          setFarmStorages(data);
        });
    }
  };

  let getInterChangeableCommodities = () => {
    APIService.commodities(combinedStates.commodity_id__in[0])
      .appendToUrl('interchangeable-commodities/')
      .get()
      .then(data => {
        setInterchangeableCommodities(data);
      })
  }

  useEffect(() => {
    setCombinedStates({
      ...combinedStates,
      'farm_id': get(props.item, 'farmId', get(props.item, 'stocks.farmId')),
      'storage_id__in': [props.item?.storageId || get(props.item, 'id')],
      'ngr_id__in': [props.item?.ngrId || get(props.item, 'stocks.ngrId')],
      'company_id__in': compact([props.item?.ngrCompanyId || get(props.item, 'stocks.ngr.companyId')]),
      'commodity_id__in': [get(props.item, 'stocks.commodityId') || get(props.item, 'commodityId')],
    });
  }, [props.item]);

  useEffect(() => {
    getAllCommodities();
    getSeason();
    getAllCompanies();
    if (!props.isEdit) {
      const startDateTime = getDateTimeFromUTC(Date().toLocaleString());
      setCombinedStates({
        ...combinedStates,
        'reseasoned_date': startDateTime.date, 'reseasoned_time': startDateTime.time
      })
    }
    if(props.regradingStock && props.isEdit)
      initialSetup();
  }, []);

  let initialSetup = () => {
    APIService.loads().appendToUrl(`identifier/${get(props.item, 'regradeIdentifier')}/regrade/`)
      .get(props.token)
      .then(item => {
        const startDateTime = getDateTimeFromUTC(get(item, 'dateTime'));
        setCombinedStates({
          ...combinedStates,
          'commodity_id__in': [get(item, 'commodityId')],
          'recommodity_id': get(item, 'recommodityId'),
          'farm_id': get(item, 'farmId'),
          'season__in': [get(item, 'season')],
          'grade_id__in': [get(item, 'gradeId')],
          'tonnage': get(item, 'estimatedNetWeight') ? get(item, 'estimatedNetWeight').toString() : "",
          'regrade_id': [get(item, 'regradeId')],
          'reseason': [get(item, 'reseason')],
          'reseasoned_date': startDateTime.date,
          'reseasoned_time': startDateTime.time,
          'identifier': get(props.item, 'regradeIdentifier'),
          'differential': get(item, 'differential'),
          'comment': get(item, 'comment'),
          'quantity': get(item, 'quantity') ? get(item, 'quantity').toString() : "",
        });
        APIService.farms(get(item, 'farmId')).get().then(farm => setAllLocations([farm]));
        if(props.isEdit && props.regradingStock) {
          APIService.loads().appendToUrl('regrade-reseason/'+ get(props.item, 'extras.identifier', 
            get(props.item, 'regradeIdentifier'))+'/')
            .get().then(regradedLoad => {
              setRegradedLoad(regradedLoad);
              setIsWarehouseInvoiced(get(regradedLoad, 'isWarehouseInvoiced'));
            });
        }
        setLoad(item);
      });
  };

  const setAllGrades = () => {
    if (!isEmpty(allCommodities)) {
      if (get(combinedStates.commodity_id__in, '0')) {
        const commodity = find(allCommodities, {id: get(combinedStates.commodity_id__in, '0')});
        setGrades([...commodity.grades])
      }
      if (combinedStates.recommodity_id) {
        const recommodity = find(allCommodities, {id: combinedStates.recommodity_id});
        setRegradeGrades([...recommodity.grades])
      }
    }
  }

  useEffect(() => {
    if (combinedStates.farm_id)
      getAllStorages();
    if (props.regradingStock)
      getInterChangeableCommodities();
    if (props.locations) {
      let { locations } = { ...props };
      let copyLocations = locations.slice();
      remove(copyLocations, val => val.id === 'all');
      setAllLocations(copyLocations);
    }
    if (combinedStates.company_id__in) {
      let farmIds = compact(combinedStates.farm_id || []);
      let companyIds = compact(combinedStates.company_id__in || []);
      getNgrs(farmIds, companyIds);
    }
  }, [combinedStates.farm_id, combinedStates.company_id__in, allCompanies, props.locations]);

  let onCompanyChange = async (id, selectedItems) => {
    setCombinedStates({ ...combinedStates, 'company_id__in': map(selectedItems, 'id') });
    let farmIds = compact(combinedStates.farm_id || []);
    let companyIds = compact(map(selectedItems, 'id') || []);
    getNgrs(farmIds, companyIds);
  };

  let getNgrs = async (farmIds, companyIds) => {
    let ngrs = [];
    if (!isEmpty([...companyIds, ...farmIds])) {
      const companyQueryString = map(companyIds, id => { return `company_ids=${id}`; }).join('&');
      const farmQueryString = map(farmIds, id => { return `farm_ids=${id}`; }).join('&');
      ngrs = await APIService.ngrs().appendToUrl(`?${companyQueryString}&${farmQueryString}`).get(props.token);
    }
    setNgrs(ngrs);
  };


  let handleDelete = () => {
    if (get(regradedLoad, 'inload.id') && get(regradedLoad, 'outload.id')) {
      alertifyjs.confirm(
        'Warning',
        `<div className=""><ul>Are you sure you want to delete?</ul></div>`,
        () => {
          APIService.storages().appendToUrl(`silo-to-silo-transfer/?load_ids=${regradedLoad.inload.id}&load_ids=${regradedLoad.outload.id}`).delete().then(
            alertifyjs.success('Loads Deleted', 1, () => {
              localStorage.setItem('stocks', JSON.stringify({}));
              window.location.reload();
              props.toggleDialog();
            })
          );
        },
        () => { }
      );
    }
  };

  let isVarietyMandatoryBasedOnUserSettingAndStockOwner = ids => {
    let isMandatory = false;
    forEach(compact([...ids, (props.isEdit ? (props.item?.ownerId || props.item?.stockOwnerId) : null)]), companyId => {
      const { isVarietyMandatory, userTypeForVarietyMandatory } = site
      if(isVarietyMandatory) {
        const company = find(allCompanies, {id: companyId})
        let isGrower = company && isCompanyGrower(company)
        let isGrowerType = isGrower && userTypeForVarietyMandatory &&['growers'].some(type =>  userTypeForVarietyMandatory.includes(type))
        let isNonGrowerType = !isGrower && userTypeForVarietyMandatory && ['non_growers'].some(type => userTypeForVarietyMandatory.includes(type))
        if (isVarietyMandatory && (isGrowerType || isNonGrowerType))
          isMandatory = true;
        else
          isMandatory = isMandatory || false;
      }
    })
    return isMandatory;
  }

  let handleSubmit = async (readOnly = false) => {
    let combinedStatesCopy = Object.assign({}, combinedStates);
    let isSubmitting = false;
    if (!(props.regradingStock || props.emptyStorage || props.updateStorage)) {
      delete combinedStatesCopy.comment
    }
    let field_errors = {
      tonnage: '',
      season: '',
      grade: '',
      commodity: '',
      regrade: '',
      variety: '',
      reseason: '',
      reseasoned_date: '',
      reseasoned_time: '',
      identifier: '',
      differential: '',
    }
    if (props.regradingStock) {
      const utcDateTime = getDateTimeInUTC(combinedStatesCopy.reseasoned_date, combinedStatesCopy.reseasoned_time);
      combinedStatesCopy.reseasoned_date_time = utcDateTime.dateTime;
    }
    if (combinedStatesCopy.stocks_before_date && combinedStatesCopy.stocks_before_time) {
      const utcDateTime = getDateTimeInUTC(combinedStatesCopy.stocks_before_date, combinedStatesCopy.stocks_before_time);
      combinedStatesCopy.stocks_before_date_time = utcDateTime.dateTime;
    }
    if (combinedStatesCopy.identifier && !isEqual(get(props.item, 'extras.identifier', get(props.item, 'regradeIdentifier', '')), combinedStatesCopy.identifier)) {
      const _identifier = combinedStatesCopy.identifier
      if(has(identifierState, _identifier)) {
        if(identifierState[_identifier])
          field_errors.identifier = 'Identifier already exists'
      } else {
        let loadIdentifierUrl = `identifier/${_identifier}/`;
        if(props.regradingStock)
          loadIdentifierUrl = loadIdentifierUrl += 'regrade/';
        await APIService.loads().appendToUrl(loadIdentifierUrl).get().then(
          res => {
            if (res) field_errors.identifier = 'Identifier already exists'
            setIdentifierState({...identifierState, [_identifier]: Boolean(res)})
          })
      }
    }
    if (isEmpty(combinedStatesCopy.identifier))
      field_errors.identifier = MESSAGES.REQUIRED;
    delete combinedStatesCopy.company_id__in;
    if (!props.regradingStock) {
      delete combinedStatesCopy.regrade_id;
      delete combinedStatesCopy.reseason;
      delete combinedStatesCopy.reseasoned_date;
      delete combinedStatesCopy.reseasoned_time;
      delete combinedStatesCopy.recommodity_id;
    }
    let companyIds = compact(combinedStates.company_id__in || []);
    if(props.isStoragesView)
      companyIds = [currentUserCompany().id]
    let isMandatory = isVarietyMandatoryBasedOnUserSettingAndStockOwner([...companyIds])
    const isSpecsInvalid = validateSpecs()
    if(!isSpecsInvalid) {
      let specs = {}
      forEach(combinedStates.specs, (specValue, specCode) => {
        set(specs, `${specCode.toUpperCase()}`, specValue.value);
      })
      combinedStatesCopy.specs = specs;
    } else
      return false
    if (props.isStoragesView) {
      delete combinedStatesCopy.ngr_id__in;
      delete combinedStatesCopy.current_tonnage;
      if (!props.regradingStock)
        delete combinedStatesCopy.recommodity_id;
      if (isEmpty(combinedStatesCopy.storage_id__in))
        setCombinedErrors({ ...combinedErrors, 'storage': 'Field cannot be blank' });
      else if ((isEmpty(combinedStatesCopy.tonnage) || isEmpty(combinedStatesCopy.commodity_id__in)) && props.updateStorage) {
        isEmpty(combinedStatesCopy.tonnage) ? setCombinedErrors({ ...combinedErrors, 'tonnage': 'Field cannot be blank' }) :
          setCombinedErrors({ ...combinedErrors, 'commodity': 'Field cannot be blank' });
      } else if(isEmpty(combinedStatesCopy.identifier)) {
        setCombinedErrors({ ...combinedErrors, 'identifier': MESSAGES.REQUIRED });
      }
      else if(props.updateStorage && isEmpty(combinedStatesCopy.variety_id__in) && isMandatory) {
        setCombinedErrors({ ...combinedErrors, 'variety': MESSAGES.REQUIRED });
      }
      else if (props.emptyStorage && combinedStatesCopy.stocks_before_date && !combinedStatesCopy.stocks_before_time) {
        setCombinedErrors({ ...combinedErrors, 'stocks_before_time': MESSAGES.REQUIRED });
      }
      else if (props.emptyStorage && !combinedStatesCopy.stocks_before_date && combinedStatesCopy.stocks_before_time) {
        setCombinedErrors({ ...combinedErrors, 'stocks_before_date': MESSAGES.REQUIRED });
      }
      else {
        delete combinedStatesCopy.stocks_before_date;
        delete combinedStatesCopy.stocks_before_time;
        if (readOnly && typeof readOnly !== 'object') {
          dispatch(isLoading('UpdateStorageFiltersLoader'))
          let queryParams = '?read_only=true';
          if (props.emptyStorage)
            queryParams += '&empty=true'
          APIService.storages()
            .appendToUrl(`stocks/${queryParams}`)
            .put(combinedStatesCopy)
            .then(response => {
              dispatch(forceStopLoader());
              if (get(response, 'errors')) {
                alertifyjs.error(get(response, 'errors'), 3);
                setManageOwnership(false);
              }
              else {
                let loads = get(response, 'loads')
                if (!isEmpty(loads)) {
                  const data = loads.map((obj, index) => ({
                    ...obj,
                    id: index + 1,
                    remainingTonnage: obj.estimatedNetWeight,
                    ngrDistribution: [{'id': 1, 'stockOwnerId': undefined, 'ngrId': undefined, 'tonnage': undefined, 'errors': ''}]
                  }));
                  setStoragesLoads(data)
                }
              }
            });
        }
        else if (manageOwnership) {
          let canProceed = true;
          storagesLoads.forEach(storagesLoad => {
            storagesLoad.ngrDistribution.forEach(obj => {
              if (obj.errors)
                canProceed = false;
              else {
                const isAnyFieldPresent = obj.stockOwnerId || obj.ngrId || obj.tonnage;
                if (isAnyFieldPresent) {
                  if (!obj.stockOwnerId || !obj.ngrId || !obj.tonnage) {
                    obj.errors = 'This field is required';
                    canProceed = false
                  }
                }
              }
            })
          })
          if (canProceed) {
            let loadsData = []
            let identifier = combinedStatesCopy.identifier || generateIdentifier('load');
            let identifierSuffix = 0;
            let optionType = props.emptyStorage ? OPTION_TYPE_STORAGE_EMPTY_LOAD : OPTION_TYPE_STORAGE_UPDATE_LOAD;
            storagesLoads.forEach(storagesLoad => {
              let loadObj = {
                'commodityId': storagesLoad.commodityId, 'date_time': storagesLoad.dateTime, 'option_type': optionType,
                'grade_id': storagesLoad.gradeId, 'season': storagesLoad.season, 'source': storagesLoad.source,
                'created_by_id': storagesLoad.createdById, 'updated_by_id': storagesLoad.updatedById, 'skip_shrinkage': storagesLoad.skipShrinkage,
                'storage_id': storagesLoad.storageId, 'variety_id': storagesLoad.varietyId, 'specs': storagesLoad.specs || {},
                'extras': {'identifier': identifier, 'comment': ''}, 'identifier': identifier + `_${identifierSuffix}`, 'type': storagesLoad.type
              }
              if (storagesLoad.type === 'inload' && combinedStatesCopy.specs)
                loadObj['specs'] = combinedStatesCopy.specs;
              let totalDistributedTonnage = 0;
              storagesLoad.ngrDistribution.forEach(obj => {
                if (obj.stockOwnerId && obj.ngrId && obj.tonnage)  {
                  loadsData.push({
                    ...loadObj,
                    'ngr_id': obj.ngrId,
                    'identifier': identifier + `_${identifierSuffix}`,
                    'estimated_net_weight': parseFloat(parseFloat(obj.tonnage).toFixed(2))
                  });
                  totalDistributedTonnage += parseFloat(obj.tonnage);
                  identifierSuffix += 1;
                }
              });
              if (storagesLoad.estimatedNetWeight - totalDistributedTonnage > 0) {
                loadsData.push({
                  ...loadObj,
                  'ngr_id': storagesLoad.ngrId,
                  'identifier': identifier + `_${identifierSuffix}`,
                  'estimated_net_weight': parseFloat((storagesLoad.estimatedNetWeight - totalDistributedTonnage).toFixed(2))
                });
                identifierSuffix += 1;
              }
            })
            dispatch(isLoading('UpdateStorageFiltersLoader'));
            let data = {
              'loads': loadsData,
              'identifier': identifier,
              'storage_ids': combinedStatesCopy.storage_id__in,
              'commodity_ids': combinedStatesCopy.commodity_id__in,
              'grade_ids': combinedStatesCopy.grade_id__in,
              'variety_ids': combinedStatesCopy.variety_id__in,
              'ngr_ids': combinedStatesCopy.ngr_id__in,
              'seasons': combinedStatesCopy.season__in,
              'tonnage': combinedStatesCopy.tonnage || null,
              'quantity': combinedStatesCopy.quantity || null,
              'specs': combinedStatesCopy.specs,
              'status': 'completed',
              'comment': combinedStatesCopy.comment
            }
            APIService.stocks()
              .appendToUrl(`meta/`)
              .post(data)
              .then(response => {
                dispatch(forceStopLoader());
                if (get(response, '0.id')) {
                  props.toggleDialog();
                  alertifyjs.success("Storages have been emptied successfully", 3, () => window.location.reload());
                }
                else
                  alertifyjs.error('Something went wrong!');
              });
          }
          else
            setStoragesLoads([...storagesLoads]);
        }
        else if (props.emptyStorage) {
          dispatch(isLoading('UpdateStorageFiltersLoader'))
          dispatch(emptyStorages(combinedStatesCopy))
        }
        else if (props.updateStorage) {
          dispatch(isLoading('UpdateStorageFiltersLoader'))
          dispatch(updateStorageStocks(combinedStatesCopy))
        }
        if (!readOnly)
          props.toggleDialog();
      }
    }
    else {
      delete combinedStatesCopy.storage_id__in;
      if (isEmpty(combinedStatesCopy.ngr_id__in))
        field_errors.ngr = MESSAGES.REQUIRED;
      else if ((isEmpty(combinedStatesCopy.tonnage) || isEmpty(compact(combinedStatesCopy.commodity_id__in)) || isEmpty(compact(combinedStatesCopy.grade_id__in)) || isEmpty(compact(combinedStatesCopy.season__in))) && (props.updateStorage || props.regradingStock)) {
        if (!combinedStatesCopy.tonnage)
          field_errors.tonnage = MESSAGES.REQUIRED;
        if (isEmpty(combinedStatesCopy.season__in))
          field_errors.season = MESSAGES.REQUIRED;
        if (isEmpty(compact(combinedStatesCopy.grade_id__in)))
          field_errors.grade = MESSAGES.REQUIRED;
        if (isEmpty(compact(combinedStatesCopy.commodity_id__in)))
          field_errors.commodity = MESSAGES.REQUIRED;
      }
      if(props.updateStorage && isEmpty(combinedStatesCopy.variety_id__in) && isMandatory)
          field_errors.variety = MESSAGES.REQUIRED;
      if (props.regradingStock) {
        if (!combinedStatesCopy.tonnage)
          field_errors.tonnage = MESSAGES.REQUIRED;
        else if (parseFloat(combinedStatesCopy.tonnage) > availableTonnage.toFixed(2))
          field_errors.tonnage = `Cannot be greater than existing ${getCountryLabel('tonnage').toLowerCase()}`;
        else if (combinedErrors.tonnage)
          field_errors.tonnage = combinedErrors.tonnage;
        if (isEmpty(combinedStatesCopy.regrade_id))
          field_errors.regrade = MESSAGES.REQUIRED;
        if(isEmpty(combinedStatesCopy.variety_id__in) && isMandatory)
          field_errors.variety = MESSAGES.REQUIRED;
        if (isEmpty(combinedStatesCopy.reseason))
          field_errors.reseason = MESSAGES.REQUIRED;
        if (isEmpty(combinedStatesCopy.reseasoned_date))
          field_errors.reseasoned_date = MESSAGES.REQUIRED;
        if (isEmpty(combinedStatesCopy.reseasoned_time) || combinedStatesCopy.reseasoned_time === 'Invalid date')
          field_errors.reseasoned_time = MESSAGES.REQUIRED;
        if (isEmpty(combinedStatesCopy.differential))
          field_errors.differential = MESSAGES.REQUIRED;
        delete combinedStatesCopy.reseasoned_time;
        delete combinedStatesCopy.reseasoned_date;
      }
      setCombinedErrors(() => {
        let newErrors = {
          ...combinedErrors, tonnage: field_errors.tonnage, regrade: field_errors.regrade,
          reseason: field_errors.reseason, commodity: field_errors.commodity,
          season: field_errors.season, grade: field_errors.grade,
          reseasoned_time: field_errors.reseasoned_time, reseasoned_date: field_errors.reseasoned_date,
          identifier: field_errors.identifier, differential: field_errors.differential, variety: field_errors.variety
        }
        let isSpecsInvalid = false;
        if(get(combinedStates, 'commodity_id__in.0') === COMMODITIES.CANOLA) {
          isSpecsInvalid = validateSpecs()
          if(!isSpecsInvalid) {
            let specs = {}
            forEach(combinedStates.specs, (specValue, specCode) => {
              set(specs, `${specCode.toUpperCase()}`, specValue.value);
            })
            combinedStatesCopy.specs = specs;
          }
        }
        let isInvalid = some(newErrors, error => !isEmpty(error)) || isSpecsInvalid;
        if (!isInvalid && !isSubmitting) {
          delete combinedStatesCopy.current_tonnage;
          if (props.isEdit) {
            if (props.regradingStock) {
              if(isRegradeReseasonValid()) {
                const partyContacts = getPartyContacts();
                setPartyContacts(partyContacts)
                openEmailDialog(combinedStatesCopy)
                dispatch(forceStopLoader())
              }
            }
          }
          else {
            if (readOnly && typeof readOnly !== 'object') {
              dispatch(isLoading('UpdateStorageFiltersLoader'))
              let queryParams = '?read_only=true';
              if (props.emptyStorage)
                queryParams += '&empty=true'
              APIService.storages()
                .appendToUrl(`stocks/${queryParams}`)
                .put(combinedStatesCopy)
                .then(response => {
                  dispatch(forceStopLoader());
                  if (get(response, 'errors')) {
                    alertifyjs.error(get(response, 'errors'), 3);
                    setManageStorages(false);
                  }
                  else {
                    let loads = get(response, 'loads')
                    if (!isEmpty(loads)) {
                      const loadsWithIds = loads.map((obj, index) => {
                        return {
                          ...obj,
                          id: index + 1,
                          remainingTonnage: obj.estimatedNetWeight,
                          storageDistribution: [{'id': 1, 'storageId': undefined, 'tonnage': undefined, 'errors': ''}]
                        }
                      });
                      const resultLoads = loadsWithIds.map(obj => {
                        const matchingElement = loadsWithIds.find(
                          el =>
                            el.id !== obj.id &&
                            el.commodityId === obj.commodityId &&
                            el.gradeId === obj.gradeId &&
                            el.season === obj.season &&
                            el.estimatedNetWeight === obj.estimatedNetWeight &&
                            el.type !== obj.type
                        );
                        const counterLoadId = matchingElement ? matchingElement.id : null;
                        const isCounterLoad = obj.ngrId === null;
                        return {
                          ...obj,
                          counterLoadId,
                          isCounterLoad,
                        };
                      });
                      setStockLoads(resultLoads)
                    }
                  }
                });
            }
            else if (manageStorages) {
              let canProceed = true;
              stockLoads.forEach(stockLoad => {
                stockLoad.storageDistribution.forEach(obj => {
                  if (obj.errors)
                    canProceed = false;
                  else {
                    const isAnyFieldPresent = obj.storageId || obj.tonnage;
                    if (isAnyFieldPresent) {
                      if (!obj.storageId || !obj.tonnage) {
                        obj.errors = 'This field is required';
                        canProceed = false
                      }
                    }
                  }
                })
              })
              if (canProceed) {
                let loadsData = []
                let identifier = combinedStatesCopy.identifier || generateIdentifier('load');
                let identifierSuffix = 0;
                let optionType = props.emptyStorage ? OPTION_TYPE_STOCK_EMPTY_LOAD : OPTION_TYPE_STOCK_UPDATE_LOAD;
                let specs = {}
                let storageIds = [];
                stockLoads.forEach(stockLoad => {
                  let loadObj = {
                    'commodityId': stockLoad.commodityId, 'date_time': stockLoad.dateTime, 'option_type': optionType,
                    'grade_id': stockLoad.gradeId, 'season': stockLoad.season, 'source': stockLoad.source,
                    'created_by_id': stockLoad.createdById, 'updated_by_id': stockLoad.updatedById, 'skip_shrinkage': stockLoad.skipShrinkage,
                    'ngr_id': stockLoad.ngrId, 'variety_id': stockLoad.varietyId, 'specs': stockLoad.specs || {},
                    'extras': {'identifier': identifier, 'comment': ''}, 'type': stockLoad.type
                  }
                  specs = loadObj['specs'];
                  if (!stockLoad.isCounterLoad) {
                    let totalDistributedTonnage = 0;  
                    stockLoad.storageDistribution.forEach(obj => {
                      if (obj.storageId && obj.tonnage)  {
                        loadsData.push({
                          ...loadObj,
                          'storage_id': obj.storageId,
                          'identifier': identifier + `_${identifierSuffix}`,
                          'estimated_net_weight': parseFloat(parseFloat(obj.tonnage).toFixed(2))
                        });
                        totalDistributedTonnage += parseFloat(obj.tonnage);
                        identifierSuffix += 1;
                        storageIds.push(obj.storageId);
                      }
                    });
                    if (stockLoad.estimatedNetWeight - totalDistributedTonnage > 0) {
                      loadsData.push({
                        ...loadObj,
                        'storage_id': stockLoad.storageId,
                        'identifier': identifier + `_${identifierSuffix}`,
                        'estimated_net_weight': parseFloat((stockLoad.estimatedNetWeight - totalDistributedTonnage).toFixed(2))
                      });
                      identifierSuffix += 1;
                    }
                  }
                  else {
                    let relatedLoad = find(stockLoads, {id: stockLoad.counterLoadId});
                    if (relatedLoad) {
                      const totalDistributedTonnage = relatedLoad.storageDistribution.reduce((sum, storageDistributionObj) => {
                        const tonnage = parseFloat(storageDistributionObj.tonnage);
                          return !isNaN(tonnage) ? sum + tonnage : sum;
                        }, 0);
                      if (relatedLoad.estimatedNetWeight - totalDistributedTonnage > 0) {
                        loadsData.push({
                          ...loadObj,
                          'storage_id': stockLoad.storageId,
                          'identifier': identifier + `_${identifierSuffix}_only_storage`,
                          'estimated_net_weight': parseFloat(parseFloat(relatedLoad.estimatedNetWeight - totalDistributedTonnage).toFixed(2))
                        });
                        identifierSuffix += 1;
                      }
                    }
                  }
                })
                let data = {
                  'loads': loadsData,
                  'identifier': identifier,
                  'storage_ids': storageIds,
                  'commodity_ids': combinedStatesCopy.commodity_id__in,
                  'grade_ids': combinedStatesCopy.grade_id__in,
                  'variety_ids': combinedStatesCopy.variety_id__in,
                  'ngr_ids': combinedStatesCopy.ngr_id__in,
                  'seasons': combinedStatesCopy.season__in,
                  'tonnage': combinedStatesCopy.tonnage || null,
                  'quantity': combinedStatesCopy.quantity || null,
                  'specs': specs,
                  'status': 'completed',
                  'comment': combinedStatesCopy.comment
                }
                dispatch(isLoading('UpdateStorageFiltersLoader'));
                APIService.stocks()
                  .appendToUrl('meta/')
                  .post(data)
                  .then(response => {
                    dispatch(forceStopLoader());
                    if (get(response, '0.id')) {
                      props.toggleDialog();
                      alertifyjs.success("Stock updated successfully", 3, () => window.location.reload());
                    }
                    else
                      alertifyjs.error('Something went wrong!');
                  });
              }
              else
                setStockLoads([...stockLoads]);
            }
            else if(props.emptyStorage) {
              dispatch(emptyStorages(combinedStatesCopy));
              isSubmitting = true;
              props.toggleDialog();
            }
            else {
              if(props.regradingStock) {
                if(isRegradeReseasonValid()) {
                  const partyContacts = getPartyContacts();
                  setPartyContacts(partyContacts)
                  openEmailDialog(combinedStatesCopy)
                  dispatch(forceStopLoader())
                }
              } else {
                dispatch(updateStorageStocks(combinedStatesCopy));
                props.toggleDialog();
              }
            }
          }
        }
        return newErrors;
      })
    }
  };

  const isRegradeReseasonValid = () => {
    if(isEqual(get(combinedStates, 'grade_id__in.0') , get(combinedStates, 'regrade_id.0')) && isEqual(get(combinedStates, 'season__in.0', ''), get(combinedStates, 'reseason.0')) && isEqual(get(combinedStates, 'commodity_id__in.0') , get(combinedStates, 'recommodity_id'))) {
      alertifyjs.error("Actual and Updated Commodity, Grade and Season can't be same");
      return false
    }
    return true;
  }

  let handleIdentifierChange = event => {
    if (isEmpty(event.target.value))
      setCombinedErrors({ ...combinedErrors, 'identifier': MESSAGES.REQUIRED });
    else
      setCombinedErrors({ ...combinedErrors, 'identifier': '' });
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      setCombinedStates({ ...combinedStates, 'identifier': event.target.value })
    }
  }

  const getEmailPopupParties = () => ['stock_owner', 'site']

  const getPartyEmails = () => partyEmails

  const getEmailPopupPartiesCompanyIds = () => {
    const ids = {};
    ids.stock_owner = get(props.item, 'stocks.ngr.companyId') || get(props.item, 'stockOwnerId') || get(props.item, 'ownerId') || props.item?.ngrCompanyId;
    ids.site = get(props.item, 'companyId') || get(props.item, 'farmCompanyId') || find(props.locations, {id: props.item.farmId})?.companyId
    return ids;
  }

  const getPartyContacts = async () => {
    if (gotOncePartyContacts)
      return;

    setGotOncePartyContacts(true);
    let partyCompanyIds = getEmailPopupPartiesCompanyIds();
    const parties = getEmailPopupParties();
    const contacts = {};
    const emails = {};
    let companyIds = compact(Object.values(partyCompanyIds));
    const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
    const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get();

    forEach(parties, party => {
      contacts[party] = filter(employees, { companyId: partyCompanyIds[party] });
      if(contacts[party])
        emails[party] = get(contacts[party][0], 'email');
    });

    setPartyEmails(emails);
    return contacts;
  }

  const openEmailDialog = (data) => {
    setPayloadData(data);
    setShowEmailDialog(true);
  };

  const getRegradeReseasonEntityName = () => {
    let entityName = '';
    if(!isEqual(get(combinedStates, 'grade_id__in[0]'), get(combinedStates, 'regrade_id[0]')))
      entityName += 'Regrade'
    if(!isEqual(get(combinedStates, 'season__in[0]'), get(combinedStates, 'reseason[0]')))
      entityName += ' Reseason'

    return compact(entityName.split(' ')).join(' & ');
  }

  const getFootertext = () => {
    if(props.item)
      return getRegradeReseasonEntityName() + ' PDF will be automatically sent as part of the email'
    return '';
  }

  const getEmailSubject = () => {
    let companyName = get(props.item, 'farmCompanyName') || get(props.item, 'companyName') || find(props.owners, {id: props.item.ngrCompanyId})?.name;
    let siteName = get(props.item, 'name') || get(props.item, 'siteName') || get(find(allLocations, { id: combinedStates.farm_id }), 'name', '')
    let entityName = getRegradeReseasonEntityName()
    let name = '';
    if(companyName)
      name += `${companyName} | `
    if(props.isEdit)
      name = '[Amendment] ' + name

    const identifier = get(combinedStates, 'identifier').toUpperCase()
    return name + `${siteName} | ${entityName} #${identifier}`;
  }

  const closeEmailDialog = (communicationData, justClose) => {
    if (justClose) {
      setGotOncePartyContacts(false);
      setShowEmailDialog(false);
    } else if (showEmailDialog) {
      let data = payloadData;
      if (communicationData) {
        data['communication'] = communicationData;
      }
      setShowEmailDialog(false);
      if(props.isEdit) {
        if (props.regradingStock) {
          dispatch(updateRegradedStocks(data, get(props.item, 'extras.identifier', get(props.item, 'regradeIdentifier'))))
          props.toggleDialog();
        }
      } else {
        dispatch(createRegradedStock(data))
        props.toggleDialog();
      }
    }
  }

  const initiateDownloadPdf = () => {
    let queryParams = '?download=true';
    APIService.loads().appendToUrl('regrade-reseason/'+ get(props.item, 'extras.identifier', get(props.item, 'regradeIdentifier'))+'/'+queryParams).requestUsingFetch('GET')
    .then(response => {
      if(isEqual(get(response, 'status'), 200))
        return response.blob()
    })
    .then(response => {
      if(response) {
        const fileName = getRegradeReseasonEntityName() +  moment().utc() + '.pdf';
        downloadFileFromBlob(response, fileName);
      }
    })
  }


  const getSelectedCommodity = commodityId => find(allCommodities, {id: commodityId})

  let hasQuantityBasedCommodity = commodityId => Boolean(commodityId && getSelectedCommodity(commodityId)?.isQuantityBased)

  let quantityUnit = commodityId => get(getSelectedCommodity(commodityId), 'unit');

  let tonnageOfPrevGrade = (regradedLoad?.totalTonnage + props.item?.tonnage)
  let availableTonnage = props.isEdit? tonnageOfPrevGrade : (props.item?.tonnage || get(props.item, 'stocks.currentTonnage'))

  let fetchLoads = event => {
    let id = event.target.id;
    let canProceed = true;
    if (id === 'manageOwnership') {
      if (event.target.checked && (isEmpty(combinedStates.grade_id__in) || isEmpty(combinedStates.season__in))) {
        canProceed = false;
        let combinedErrorsCopy = Object.assign({}, combinedErrors);
        if (isEmpty(combinedStates.grade_id__in))
          combinedErrorsCopy.grade = 'Field cannot be blank';
        if (isEmpty(combinedStates.season__in))
          combinedErrorsCopy.season = 'Field cannot be blank';
        setCombinedErrors({...combinedErrorsCopy});
      }
      else if (props.emptyStorage && combinedStates.stocks_before_date && !combinedStates.stocks_before_time) {
        canProceed = false;
        let combinedErrorsCopy = Object.assign({}, combinedErrors);
        combinedErrorsCopy.stocks_before_time = 'Field cannot be blank';
        setCombinedErrors({...combinedErrorsCopy});
      }
      else if (props.emptyStorage && !combinedStates.stocks_before_date && combinedStates.stocks_before_time) {
        canProceed = false;
        let combinedErrorsCopy = Object.assign({}, combinedErrors);
        combinedErrorsCopy.stocks_before_date = 'Field cannot be blank';
        setCombinedErrors({...combinedErrorsCopy});
      }
      else
        setManageOwnership(event.target.checked);
    }
    else if (id === 'manageStorages')
      setManageStorages(event.target.checked);
    if (event.target.checked) {
      if (canProceed)
        handleSubmit(true);
    }
    else {
      setStoragesLoads([]);
      setStockLoads([]);
    }
  }

  let updateStorageLoads = async (value, storageLoadRow, ngrDistributionRow, property, pullNgrs) => {
    const promises = [];
  
    storagesLoads.forEach(obj => {
      if (obj.id === storageLoadRow.id) {
        obj.ngrDistribution.forEach(ngrDistributionObj => {
          if (ngrDistributionObj.id === ngrDistributionRow.id) {
            let currentValue = get(ngrDistributionObj, property)
            set(ngrDistributionObj, property, value);
            let tonnageLabel = includes(STRICT_COMMODITY_BASED_UNITS, obj?.priceUnit) ? 'quantity' : 'tonnage';
            if (value && (ngrDistributionObj.stockOwnerId && ngrDistributionObj.ngrId && ngrDistributionObj.tonnage) && !includes(ngrDistributionObj.errors, tonnageLabel))
              set(ngrDistributionObj, 'errors', '');
            if (!ngrDistributionObj.stockOwnerId && !ngrDistributionObj.ngrId && !ngrDistributionObj.tonnage && !includes(ngrDistributionObj.errors, tonnageLabel))
              set(ngrDistributionObj, 'errors', '');
            if (property === 'tonnage') {
              let remainingTonnage = currentValue ? obj.remainingTonnage + parseFloat(currentValue) : obj.remainingTonnage
              obj.remainingTonnage = value ? parseFloat((remainingTonnage - parseFloat(value)).toFixed(2)) : remainingTonnage
              if (obj.remainingTonnage < 0)
                set(ngrDistributionObj, 'errors', `Nominated ${tonnageLabel} cannot be greater than total ${tonnageLabel}`);
              else if (includes(ngrDistributionObj.errors, tonnageLabel))
                set(ngrDistributionObj, 'errors', '');
            }
            if (property === 'ngrId') {
              if (value) {
                let query_params = `commodity_ids=${obj.commodityId}&ngr_id=${value}`;
                if (obj.gradeId)
                  query_params += `&grade_id=${obj.gradeId}`
                if (obj.season)
                  query_params += `&season=${obj.season}`
                if (combinedStates.farm_id)
                  query_params += `&farm_ids=${combinedStates.farm_id}`
                const promise = APIService.loads().appendToUrl(`stocks/tonnage/?${query_params}`).get();
                promises.push(promise);
                promise.then(response => {
                  ngrDistributionObj.availableTonnage = parseFloat(get(response, 'tonnage').toFixed(2));
                });
              }
              else
                ngrDistributionObj.availableTonnage = 0;
            }
            if (value && pullNgrs) {
              const promise = APIService.companies(value).appendToUrl('ngrs/minimal/').get();
              promises.push(promise);
              promise.then(response => {
                ngrDistributionObj.ngrs = response;
              });
            }
            if (!value && property === 'stockOwnerId')
              ngrDistributionObj.ngrs = [];
          }
        });
      }
    });
  
    await Promise.all(promises);
  
    setStoragesLoads([...storagesLoads]);
  };

  let setStockOwner = (value, storageLoadRow, ngrDistributionRow) => updateStorageLoads(value, storageLoadRow, ngrDistributionRow, 'stockOwnerId', true);

  let setStockOwnerNgr = (value, storageLoadRow, ngrDistributionRow) => updateStorageLoads(value, storageLoadRow, ngrDistributionRow, 'ngrId', false);

  let setNgrDistributionTonnage = (value, storageLoadRow, ngrDistributionRow) => updateStorageLoads(value, storageLoadRow, ngrDistributionRow, 'tonnage', false);

  let addNgrDistribution = (storageLoadRow) => {
    storagesLoads.forEach(obj => {
      if (obj.id === storageLoadRow.id)
        obj.ngrDistribution.push({'id': obj.ngrDistribution.length + 1, 'stockOwnerId': undefined, 'ngrId': undefined, 'tonnage': undefined})
    });
    setStoragesLoads([...storagesLoads]);
  }

  let handleRemoveNgrDistributions = (storageLoadRow, ngrDistributionRow) => {
    storagesLoads.forEach(storagesLoad => {
      if (storagesLoad.id === storageLoadRow.id) {
        storagesLoad.ngrDistribution = storagesLoad.ngrDistribution.filter(obj => obj.id !== ngrDistributionRow.id).map((obj, index) => ({ ...obj, id: index + 1 }))
        const totalTonnage = storagesLoad.ngrDistribution.reduce((sum, ngrDistributionObj) => {
          const tonnage = parseFloat(ngrDistributionObj.tonnage);
            return !isNaN(tonnage) ? sum + tonnage : sum;
          }, 0);
        storagesLoad.remainingTonnage = parseFloat((storagesLoad.estimatedNetWeight - totalTonnage).toFixed(2));
        storagesLoad.ngrDistribution.forEach(obj => {
          if (storagesLoad.remainingTonnage > 0 && includes(obj.error, 'tonnage'))
            obj.error = ''
        })
      }
    });
    setStoragesLoads([...storagesLoads]);
  }

  let updateStockLoads = async (value, stockLoadRow, storageDistributionRow, property) => {
    const promises = [];
    stockLoads.forEach(obj => {
      if (obj.id === stockLoadRow.id) {
        obj.storageDistribution.forEach(storageDistributionObj => {
          if (storageDistributionObj.id === storageDistributionRow.id) {
            let currentValue = get(storageDistributionObj, property)
            set(storageDistributionObj, property, value);
            let tonnageLabel = includes(STRICT_COMMODITY_BASED_UNITS, obj?.priceUnit) ? 'quantity' : 'tonnage';
            if (value && (storageDistributionObj.storageId && storageDistributionObj.tonnage) && !includes(storageDistributionObj.errors, tonnageLabel))
              set(storageDistributionObj, 'errors', '');
            if (!storageDistributionObj.storageId && !storageDistributionObj.tonnage && !includes(storageDistributionObj.errors, tonnageLabel))
              set(storageDistributionObj, 'errors', '');
            if (property === 'tonnage') {
              let remainingTonnage = currentValue ? obj.remainingTonnage + parseFloat(currentValue) : obj.remainingTonnage
              obj.remainingTonnage = value ? parseFloat((remainingTonnage - parseFloat(value)).toFixed(2)) : remainingTonnage
              if (obj.remainingTonnage < 0)
                set(storageDistributionObj, 'errors', `Nominated ${tonnageLabel} cannot be greater than total ${tonnageLabel}`);
              else if (includes(storageDistributionObj.errors, tonnageLabel))
                set(storageDistributionObj, 'errors', '');
            }
            if (property === 'storageId') {
              if (value) {
                const promise = APIService.loads().appendToUrl(`stocks/tonnage/?commodity_ids=${obj.commodityId}&storage_id=${value}`).get();
                promises.push(promise);
                promise.then(response => {
                  storageDistributionObj.availableTonnage = parseFloat(get(response, 'tonnage').toFixed(2));
                });
              }
              else
                storageDistributionObj.availableTonnage = 0;
            }
          }
        });
      }
    });
    await Promise.all(promises);
    setStockLoads([...stockLoads]);
  }

  let setStorage = (value, stockLoadRow, storageDistributionRow) => updateStockLoads(value, stockLoadRow, storageDistributionRow, 'storageId', true);

  let setStorageDistributionTonnage = (value, stockLoadRow, storageDistributionRow) => updateStockLoads(value, stockLoadRow, storageDistributionRow, 'tonnage', false);

  let addStorageDistribution = (stockLoadRow) => {
    stockLoads.forEach(obj => {
      if (obj.id === stockLoadRow.id)
        obj.storageDistribution.push({'id': obj.storageDistribution.length + 1, 'storageId': undefined, 'tonnage': undefined})
    });
    setStockLoads([...stockLoads]);
  }

  let handleRemoveStorageDistributions = (stockLoadRow, storageDistributionRow) => {
    stockLoads.forEach(stockLoad => {
      if (stockLoad.id === stockLoadRow.id) {
        stockLoad.storageDistribution = stockLoad.storageDistribution.filter(obj => obj.id !== storageDistributionRow.id).map((obj, index) => ({ ...obj, id: index + 1 }))
        const totalTonnage = stockLoad.storageDistribution.reduce((sum, storageDistributionObj) => {
          const tonnage = parseFloat(storageDistributionObj.tonnage);
            return !isNaN(tonnage) ? sum + tonnage : sum;
          }, 0);
        stockLoad.remainingTonnage = parseFloat((stockLoad.estimatedNetWeight - totalTonnage).toFixed(2));
        stockLoad.storageDistribution.forEach(obj => {
          if (stockLoad.remainingTonnage > 0 && includes(obj.error, 'tonnage'))
            obj.error = ''
        });
      }
    });
    setStockLoads([...stockLoads]);
  }

  let handleMultiGradeChange = (id, selectedItems) => {
    let gradeIds = map(selectedItems, 'id');
    setCombinedStates({ ...combinedStates, 'grade_id__in': gradeIds })
    if (gradeIds && !isEmpty(gradeIds)) {
      setCombinedErrors({ ...combinedErrors, 'grade': '' });
    }
  }

  let handleMultiSeasonChange = (id, selectedItems) => {
    let seasons = map(selectedItems, 'id');
    setCombinedStates({ ...combinedStates, 'season__in': seasons })
    if (seasons && !isEmpty(seasons)) {
      setCombinedErrors({ ...combinedErrors, 'season': '' });
    }
  }

  let getToCommodityLabel = () => {
    const toolTipMessage = "Some commodities can be reclassified using this feature. This is only available for selective commodity pairs."
    return (<Tooltip title={toolTipMessage} placement='top'><span>To Commodity</span></Tooltip>);
  };

  let handleStocksBeforeDateTimeBlur = () => {
    if (combinedStates.stocks_before_date && combinedStates.stocks_before_time)
      refreshManageOwnershipStorageLoads();
  }

  useEffect(() => {
    handleStocksBeforeDateTimeBlur();
  }, [combinedStates.stocks_before_date, combinedStates.stocks_before_time])

  return (
    <div>
          <SideDrawer
            isOpen={props.isOpen}
            title={props.regradingStock ? 'Regrade/Reseason Stock' : props.emptyStorage ? 'Empty Stock' : props.updateStorage ? 'Update Stock' : 'Match Web/Mobile Stocks'}
            onClose={props.toggleDialog}
            size="big"
          >
          <div className="col-sm-12 padding-reset">
            {
                (props.regradingStock && isWarehouseInvoiced) &&
                <span style={{fontSize: '14px', color: 'rgba(0, 0, 0, 0.5)'}}>
                  <i>(this regrade reseason is already invoiced, editing is restricted.)</i>
                </span>
            }
            {
              (props.regradingStock || props.updateStorage) &&
              <div className="col-sm-12 padding-reset" style={{ marginTop: '20px' }}>
                <TextField
                  id='identifier'
                  label='Identifier'
                  value={combinedStates.identifier}
                  onChange={handleIdentifierChange}
                  error={!isEmpty(combinedErrors.identifier)}
                  helperText={combinedErrors.identifier}
                  variant='standard'
                  inputProps={{
                    maxLength: 14,
                  }}
                  fullWidth
                  disabled={props.regradingStock && isWarehouseInvoiced}
                />
              </div>
            }
            <CommonSelect
              id="farm_id"
              items={allLocations}
              value={combinedStates.farm_id}
              onChange={onLocationChange}
              floatingLabelText="Farm/Site"
              selectConfig={{ text: 'name', value: 'id' }}
              disabled={props.regradingStock}
              style={{ marginTop: '20px' }}
              errorText={combinedErrors.farm}
            />
          </div>
          {props.isStoragesView &&
           <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
             {
               props.updateStorage ?
               <CommonSelect
                 id="storage_id__in"
                 items={farmStorages}
                 value={get(combinedStates, 'storage_id__in.0', '')}
                 onChange={onUpdateStockFieldsChange}
                 floatingLabelText="Storages"
                 selectConfig={{ text: 'name', value: 'id' }}
               /> :
               <CommonMultiSelect
                 id="storage_id__in"
                 items={farmStorages}
                 selectedItems={combinedStates.storage_id__in}
                 onBlur={() => refreshManageOwnershipStorageLoads()}
                 displayField="name"
                 onChange={onFieldChange}
                 placeholder="Select Storage..."
                 error={Boolean(combinedErrors.storage)}
                 helperText={combinedErrors.storage}
                 label="Storages"
                 selectAll
                 clearAll
               />
             }
           </div>
          }
          {
            !props.isStoragesView && !props.regradingStock &&
            <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
              {
                props.updateStorage ?
                <CommonSelect
                  id="company_id__in"
                  items={allCompanies}
                  value={get(combinedStates, 'company_id__in.0', '')}
                  onChange={onUpdateStockFieldsChange}
                  floatingLabelText="Stock Owners"
                  selectConfig={{ text: 'name', value: 'id' }}
                /> :
                <CommonMultiSelect
                  id="company_id__in"
                  items={allCompanies}
                  selectedItems={combinedStates.company_id__in}
                  onBlur={() => refreshManageOwnershipStorageLoads()}
                  displayField="name"
                  onChange={onCompanyChange}
                  placeholder="Select NGR Company..."
                  label="Stock Owners"
                  selectAll
                  clearAll
                />
              }
              {
                props.updateStorage ?
                <CommonSelect
                  id="ngr_id__in"
                  items={allNgr}
                  value={get(combinedStates, 'ngr_id__in.0', '')}
                  onChange={onUpdateStockFieldsChange}
                  floatingLabelText="Ngrs"
                  selectConfig={{ text: 'ngrNumber', value: 'id' }}
                />
                :
                <div className="col-sm-12 padding-reset">
                  <CommonMultiSelect
                    id="ngr_id__in"
                    items={allNgr}
                    selectedItems={combinedStates.ngr_id__in}
                    onBlur={() => refreshManageOwnershipStorageLoads()}
                    displayField="ngrNumber"
                    onChange={onFieldChange}
                    placeholder="Select NGR.."
                    label="Ngrs"
                    error={Boolean(combinedErrors.ngr)}
                    helperText={combinedErrors.ngr}
                    selectAll
                    clearAll
                  />
                </div>
              }
            </div>
          }
          {
            props.updateStorage || props.regradingStock ?
            <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
              <CommonSelect
                id="commodity_id__in"
                items={allCommodities}
                value={get(combinedStates, 'commodity_id__in.0', '')}
                onChange={onUpdateStockFieldsChange}
                floatingLabelText="Commodity"
                selectConfig={{ text: 'displayName', value: 'id' }}
                errorText={combinedErrors.commodity}
                disabled={props.regradingStock}
              />
              {
                props.isStoragesView ?
                <div className='col-sm-12 padding-reset'>
                  <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
                    <CommonSelect
                      id="grade_id__in"
                      items={allGrade || ''}
                      value={get(combinedStates, 'grade_id__in.0', '')}
                      onChange={onUpdateStockFieldsChange}
                      floatingLabelText="Grade"
                      selectConfig={{ text: 'name', value: 'id' }}
                      errorText={combinedErrors.grade}
                    />
                  </div>
                  <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
                    <VarietyAutoComplete
                        id="variety_id__in"
                        onChange={onUpdateStockFieldsChange}
                        label={"Variety"}
                        commodityId={get(combinedStates, 'commodity_id__in.0', '')}
                        varietyId={get(combinedStates, 'variety_id__in.0', '')}
                        dependsOnCommodity={true}
                        errorText={combinedErrors.variety}
                    />
                  </div>
                  <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>

                    <CommonSelect
                      id="season__in"
                      items={allSeason}
                      value={get(combinedStates, 'season__in.0', '')}
                      onChange={onUpdateStockFieldsChange}
                      floatingLabelText="Season"
                      selectConfig={{ text: 'name', value: 'name' }}
                      errorText={combinedErrors.season}
                    />
                  </div>
                </div> :
                <div className='col-sm-12 padding-reset'>
                  <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
                    <CommonSelect
                      id="grade_id__in"
                      items={allGrade || []}
                      value={get(combinedStates, 'grade_id__in.0', '')}
                      onChange={onUpdateStockFieldsChange}
                      floatingLabelText="Grade"
                      selectConfig={{ text: 'name', value: 'id' }}
                      errorText={combinedErrors.grade}
                      disabled={props.regradingStock}
                    />
                  </div>
                  <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
                    <VarietyAutoComplete
                      id="variety_id__in"
                      onChange={onUpdateStockFieldsChange}
                      label="Variety"
                      commodityId={get(combinedStates, 'commodity_id__in.0', '')}
                      varietyId={get(combinedStates, 'variety_id__in.0', '')}
                      dependsOnCommodity={true}
                      errorText={combinedErrors.variety}
                    />
                  </div>
                  <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
                    <CommonSelect
                      id="season__in"
                      items={allSeason}
                      value={get(combinedStates, 'season__in.0', '')}
                      onChange={onUpdateStockFieldsChange}
                      floatingLabelText="Season"
                      selectConfig={{ text: 'name', value: 'name' }}
                      errorText={combinedErrors.season}
                      disabled={props.regradingStock}
                    />
                  </div>
                </div>

              }
            </div> :
            <div>
              <div className="col-sm-12 padding-reset" style={{marginTop: '10px'}}>
                <CommonMultiSelect
                  id="commodity_id__in"
                  items={allCommodities}
                  selectedItems={combinedStates.commodity_id__in}
                  onBlur={() => refreshManageOwnershipStorageLoads()}
                  displayField="displayName"
                  onChange={onCommodityChange}
                  placeholder="Select Commodity..."
                  label="Commodity"
                  selectAll
                  clearAll
                />
              </div>
              {(props.emptyStorage || !props.isStoragesView) &&
               <div>
                 <div className="col-sm-12 padding-reset" style={{marginTop: '10px'}}>
                   <CommonMultiSelect
                     id="grade__in"
                     items={allGrade}
                     selectedItems={combinedStates.grade_id__in}
                     displayField="name"
                     onChange={(id, selectedItems) => handleMultiGradeChange(id, selectedItems)}
                     onBlur={() => refreshManageOwnershipStorageLoads()}
                     error={Boolean(combinedErrors.grade)}
                     helperText={combinedErrors.grade}
                     placeholder="Select Grade..."
                     label="Grade"
                     selectAll
                     clearAll
                   />
                 </div>

                 <div className="col-sm-12 padding-reset" style={{marginTop: '10px'}}>
                   <CommonMultiSelect
                     id="season__in"
                     items={allSeason}
                     selectedItems={combinedStates.season__in}
                     displayField="name"
                     onChange={(id, selectedItems) => handleMultiSeasonChange(id, selectedItems)}
                     onBlur={() => refreshManageOwnershipStorageLoads()}
                     error={Boolean(combinedErrors.season)}
                     helperText={combinedErrors.season}
                     placeholder="Select Season..."
                     label="Season"
                     selectAll
                     clearAll
                   />
                 </div>
               </div>}
            </div>
          }
          {
            props.regradingStock &&
            <div className='col-md-12 padding-reset' style={{marginTop: '10px'}}>
              <CommonSelect
                id="recommodity_id"
                items={interchangeableCommodities}
                value={combinedStates.recommodity_id}
                selectConfig={{ text: 'displayName', value: 'id' }}
                onChange={onInterchangeableCommodityChange}
                floatingLabelText={getToCommodityLabel()}
              />
            </div>
          }

          {props.regradingStock &&
           <div className='col-sm-12 form-wrap padding-reset' style={{marginTop: '10px'}}>
             <Autocomplete
               fullWidth
               onChange={onRegradeChange}
               errorText={combinedErrors.regrade}
               label="Regrade To"
               id="regrade_id"
               options={regradeGrades || []}
               value={find(regradeGrades, {id: get(combinedStates, 'regrade_id.0')}) || null}
              renderInput={(params) =>
                <TextField {...params} InputLabelProps={{ shrink: true }} label="Regrade To" variant="standard" fullWidth
                  error={!isEmpty(combinedErrors.regrade)}
                  helperText={combinedErrors.regrade} />}
              getOptionLabel={option => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return get(option, 'displayName', get(option, 'name'));
                  }
                  return get(option, 'displayName', get(option, 'name'));
              }}
               disabled={props.regradingStock && isWarehouseInvoiced}
             />
           </div>
          }
          {
            props.regradingStock &&
            <div className='col-md-12 padding-reset' style={{marginTop: '10px'}}>
              <CommonSelect
                id="reseason"
                items={allSeason}
                value={get(combinedStates, 'reseason[0]')}
                onChange={onUpdateStockFieldsChange}
                floatingLabelText="Reseason To"
                selectConfig={{ text: 'name', value: 'name' }}
                errorText={combinedErrors.reseason}
                disabled={props.regradingStock && isWarehouseInvoiced}
              />
            </div>
          }
          {
            props.regradingStock &&
            <div>
              <div className='col-md-6 form-wrap no-left-padding' style={{marginTop: '10px'}}>
                <CommonDatePicker
                  id="reseasoned_date"
                  floatingLabelText='Regraded/Reseasoned Date'
                  onChange={onReseasonedDateTimeChange}
                  value={combinedStates.reseasoned_date}
                  minDate={moment().subtract(5, 'years').format('YYYY/MM/DD')}
                  maxDate={moment().format('YYYY/MM/DD')}
                  errorText={combinedErrors.reseasoned_date}
                  disabled={props.regradingStock && isWarehouseInvoiced}
                />
              </div>
              <div className='col-md-6 form-wrap no-right-padding' style={{marginTop: '10px'}}>
                <CommonTimePicker
                  id='reseasoned_time'
                  floatingLabelText='Regraded/Reseasoned Time'
                  onChange={onReseasonedDateTimeChange}
                  value={combinedStates.reseasoned_time}
                  errorText={combinedErrors.reseasoned_time}
                  disabled={props.regradingStock && isWarehouseInvoiced}
                />
              </div>
            </div>
          }

          {
            props.regradingStock &&
            <div>
              <div className='col-md-6 form-wrap no-left-padding' style={{marginTop: '10px'}}>
                <CommonTextField
                  id='tonnage'
                  label={`Available ${getCountryLabel('tonnage')} (${displayUnit})`}
                  fullWidth
                  value={availableTonnage.toFixed(2)}
                  style={{ float: 'left' }}
                  disabled
                  variant='standard'
                />
              </div>
              <div className='col-md-6 form-wrap no-right-padding' style={{marginTop: '10px'}}>
                <TextField
                  id='tonnage'
                  label={getCountryLabel('tonnage')}
                  placeholder={getCountryLabel('tonnage')}
                  value={combinedStates.tonnage || ''}
                  fullWidth
                  error={Boolean(combinedErrors.tonnage)}
                  helperText={combinedErrors.tonnage}
                  onChange={onTonnageChange}
                  onBlur={() => refreshManageOwnershipStorageLoads()}
                  style={{ float: 'left' }}
                  onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                        {displayUnit}
                      </InputAdornment>
                    ),
                  }}
                  variant='standard'
                  disabled={props.regradingStock && isWarehouseInvoiced}
                />
              </div>
            </div>
          }
          {
            (props.regradingStock || props.updateStorage) && hasQuantityBasedCommodity(get(combinedStates, 'commodity_id__in.0')) &&
            <div>
              <div className={props.regradingStock ? 'col-md-6 form-wrap no-left-padding': 'col-sm-12 padding-reset'} style={{marginTop: '10px', float: 'inherit'}}>
                <TextField
                    id='quantity'
                    label='Quantity'
                    placeholder='Quantity'
                    value={combinedStates.quantity || ''}
                    fullWidth
                    error={Boolean(combinedErrors.quantity)}
                    helperText={combinedErrors.quantity}
                    onChange={onQuantityChange}
                    style={{ float: 'left' }}
                    onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                          {quantityUnit(get(combinedStates, 'commodity_id__in.0'))}
                        </InputAdornment>
                      ),
                    }}
                    variant='standard'
                    disabled={props.regradingStock && isWarehouseInvoiced}
                />
              </div>
            </div>
          }
          {
            props.updateStorage &&
            <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
              <TextField
                id='tonnage'
                label={getCountryLabel('tonnage')}
                placeholder={getCountryLabel('tonnage')}
                  value={combinedStates.tonnage || ''}
                  fullWidth
                  error={Boolean(combinedErrors.tonnage)}
                  helperText={combinedErrors.tonnage}
                  onChange={onTonnageChange}
                  onBlur={() => refreshManageOwnershipStorageLoads()}
                  style={{ float: 'left' }}
                  onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                        {displayUnit}
                      </InputAdornment>
                    ),
                  }}
                  variant='standard'
                />
            </div>
          }
          {
            (props.updateStorage || props.regradingStock) &&
            <div className='col-md-12 padding-reset' style={{margin: '10px 0px'}}>
              <SpecParametersValue
                commoditySpecs={commoditySpecs}
                fieldsSpecs={combinedStates.specs}
                onChange={handleSpecsChange}
                className="col-sm-4 form-wrap"
                style={{paddingLeft: '0px'}}
                errorTexts={mapValues(combinedStates.specs, spec => get(spec, 'errors[0]', ''))}
              />
            </div>
          }

          {
            props.regradingStock &&
            <div className='col-md-12 padding-reset' style={{marginTop: '10px'}}>
              <TextField
                id='differential'
                label={`Regrade/Reseason Differential (Per ${displayUnit})`}
                placeholder={`Regrade/Reseason Differential (Per ${displayUnit})`}
                value={combinedStates.differential}
                fullWidth
                error={Boolean(combinedErrors.differential)}
                helperText={combinedErrors.differential}
                onChange={onDifferentialChange}
                style={{ float: 'left' }}
                variant='standard'
                type='number'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' style={{color: 'rgb(162,162,162)'}}>
                      $
                    </InputAdornment>
                  ),
                }}
                disabled={props.regradingStock && isWarehouseInvoiced}
              />
            </div>
          }
          {props.emptyStorage &&
          <div className="col-md-12 padding-reset" style={{paddingTop: '50px'}}>
            <div className="col-md-6 padding-reset">
              <CommonDatePicker
                id="stocks_before_date"
                floatingLabelText="Stocks Before Date"
                onChange={onStocksBeforeDateTimeChange}
                errorText={combinedErrors.stocks_before_date}
                value={combinedStates.stocks_before_date}
                maxDate={new Date()}
                variant='outlined'
              />
            </div>
            <div className="col-md-6" style={{paddingRight: '0px'}}>
              <CommonTimePicker
                id="stocks_before_time"
                floatingLabelText="Stocks Before Time"
                onChange={onStocksBeforeDateTimeChange}
                errorText={combinedErrors.stocks_before_time}
                value={combinedStates.stocks_before_time}
                variant='outlined'
              />
            </div>
          </div>
          }
          { (props.regradingStock || props.emptyStorage || props.updateStorage) &&
            <div className="col-md-12 padding-reset">
              <CommonTextField
                id="comment"
                label="Comment (Optional)"
                value={combinedStates.comment}
                onChange={(event) => setCombinedStates({ ...combinedStates, 'comment': event.target.value })}
                multiline={true}
                rows={2}
                rowsMax={2}
              />
            </div>}
          { ((window.MANAGE_OWNERSHIP_STORAGES || isSystemCompany() || isObserver()) && props.isStoragesView && (props.emptyStorage || props.updateStorage)) &&
            <div className="col-md-12 padding-reset" style={{marginTop: "10px"}}>
              <Checkbox
                id='manageOwnership'
                color='primary'
                checked={Boolean(manageOwnership)}
                onChange={fetchLoads}
                style={{paddingLeft: "0px"}}
                disabled={props.updateStorage && !combinedStates.tonnage}
              /> Manage Ownership
            </div>
          }
          {manageOwnership && !isEmpty(storagesLoads) &&
          <div>
            <span>Nominate ownership in following loads:</span><br/>
            {
              map(storagesLoads, row => {
                let ngrDistribution = row.ngrDistribution;
                let heading = `${startCase(row.type)} | ${row.storageName} | ${row.commodityName}`
                if (row.gradeName)
                  heading += ` | ${row.gradeName}`;
                if (row.season)
                  heading += ` | ${row.season}`;
                heading += ` | ${row.tonnageDisplayValue}`;
                let remainingTonnageLabel = `${row.remainingTonnage || 0} ${row.priceUnit}`;
                let allocatedTonnageLabel = `${row.estimatedNetWeight - row.remainingTonnage || 0} ${row.priceUnit}`;
                let tonnageDetail = `(Allocated: ${allocatedTonnageLabel} | Remaining: ${remainingTonnageLabel})`
                return (
                  <div style={{marginTop: '20px'}}>
                  <div className='col-md-12' style={{paddingLeft: '0px'}}>{heading}</div>
                  <div className='col-md-12' style={{paddingLeft: '0px'}}>{tonnageDetail}</div>
                  {
                    map(ngrDistribution, (ngrDistributionRow, index) => {
                      let ngrs = get(ngrDistributionRow, 'ngrs', []);
                      let tonnageLabel = includes(STRICT_COMMODITY_BASED_UNITS, row?.priceUnit) ? 'Quantity' : 'Tonnage';
                      return (
                        <React.Fragment>
                        <div className='col-md-12' style={{paddingLeft: '0px', marginTop: '15px', paddingRight: '0px'}}>
                          <div className='col-md-4' style={{paddingLeft: '0px', paddingRight: '10px'}}>
                            <CommonAutoSelect
                              id="stockOwner"
                              label="Stock Owner"
                              value={ngrDistributionRow.stockOwnerId}
                              dataSourceConfig={{text: 'name', value: 'id'}}
                              onChange={(value) => setStockOwner(value, row, ngrDistributionRow)}
                              errorText={ngrDistributionRow.stockOwnerId ? '' : includes(ngrDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : ngrDistributionRow.errors}
                              items={allCompanies}
                              variant='outlined'
                              size='small'
                            />
                          </div>
                          <div className='col-md-3' style={{paddingLeft: '0px', paddingRight: '0px'}}>
                            <CommonAutoSelect
                              id="stockOwnerNgr"
                              label="Ngr"
                              value={ngrDistributionRow.ngrId}
                              dataSourceConfig={{text: 'ngrNumber', value: 'id'}}
                              onChange={(value) => setStockOwnerNgr(value, row, ngrDistributionRow)}
                              errorText={ngrDistributionRow.ngrId ? '' : includes(ngrDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : ngrDistributionRow.errors}
                              items={ngrs}
                              variant='outlined'
                              size='small'
                            />
                          </div>
                          <div className='col-md-2' style={{paddingLeft: '10px', paddingRight: '0px'}}>
                            <CommonTextField
                              id="tonnage"
                              label={tonnageLabel}
                              value={ngrDistributionRow.tonnage}
                              onChange={(event) => setNgrDistributionTonnage(event.target.value, row, ngrDistributionRow)}
                              helperText={ngrDistributionRow.tonnage && !includes(ngrDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : ngrDistributionRow.errors}
                              onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
                              variant='outlined'
                              size='small'
                            />
                          </div>
                          <div className='col-md-2' style={{paddingLeft: '10px', paddingRight: '0px'}}>
                            <CommonTextField
                              id="availableTonnage"
                              label={`Available ${tonnageLabel}`}
                              value={get(ngrDistributionRow, 'availableTonnage') || 0}
                              variant='outlined'
                              size='small'
                              lockIconStyle={{ top: '10px' }}
                              disabled
                            />
                          </div>
                          <div className='col-md-1' style={{paddingLeft: '0px', paddingRight: '0px'}}>
                          <Button
                              className='btn-minus'
                              mini
                              id='ngrDistributions'
                              variant='fab'
                              secondary={true}
                              onClick={() => handleRemoveNgrDistributions(row, ngrDistributionRow)}
                              disabled={ngrDistribution.length === 1}
                            >
                              <ContentRemove />
                            </Button>
                          </div>
                        </div>
                          {index === ngrDistribution.length - 1 &&
                            <Button
                              id='ngrs'
                              variant='outlined'
                              onClick={() => addNgrDistribution(row)}
                              style={{ float: 'left', marginTop: '15px', marginBottom: '30px' }}
                            >Add Ownership</Button>
                          }
                        </React.Fragment>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
          }
          { ((window.MANAGE_OWNERSHIP_STORAGES || isSystemCompany() || isObserver()) && !props.isStoragesView && (props.emptyStorage || props.updateStorage)) &&
            <div className="col-md-12 padding-reset" style={{marginTop: "10px"}}>
              <Checkbox
                id='manageStorages'
                color='primary'
                checked={Boolean(manageStorages)}
                onChange={fetchLoads}
                style={{paddingLeft: "0px"}}
                disabled={props.updateStorage && !combinedStates.tonnage}
              /> Manage Storages
            </div>
          }
          {manageStorages && !isEmpty(stockLoads) &&
          <div>
            <span>Nominate storages in following loads:</span><br/>
            {
              map(stockLoads.filter(obj => !obj.isCounterLoad), row => {
                let storageDistribution = row.storageDistribution;
                let heading = `${startCase(row.type)} | ${row.commodityName}`
                if (row.ngrNumber)
                  heading += ` | ${row.ngrNumber}`;
                if (row.gradeName)
                  heading += ` | ${row.gradeName}`;
                if (row.season)
                  heading += ` | ${row.season}`;
                heading += ` | ${row.tonnageDisplayValue}`;
                let remainingTonnageLabel = `${row.remainingTonnage || 0} ${row.priceUnit}`;
                let allocatedTonnageLabel = `${row.estimatedNetWeight - row.remainingTonnage || 0} ${row.priceUnit}`;
                let tonnageDetail = `(Allocated: ${allocatedTonnageLabel} | Remaining: ${remainingTonnageLabel})`
                return (
                  <div style={{marginTop: '20px'}}>
                  <div className='col-md-12' style={{paddingLeft: '0px'}}>{heading}</div>
                  <div className='col-md-12' style={{paddingLeft: '0px'}}>{tonnageDetail}</div>
                  {
                    map(storageDistribution, (storageDistributionRow, index) => {
                      let tonnageLabel = includes(STRICT_COMMODITY_BASED_UNITS, row?.priceUnit) ? 'Quantity' : 'Tonnage';
                      return (
                        <React.Fragment>
                        <div className='col-md-12' style={{paddingLeft: '0px', marginTop: '15px', paddingRight: '0px'}}>
                          <div className='col-md-5' style={{paddingLeft: '0px', paddingRight: '10px'}}>
                            <CommonAutoSelect
                              id="storage"
                              label="Storage"
                              value={storageDistributionRow.storageId}
                              dataSourceConfig={{text: 'name', value: 'id'}}
                              onChange={(value) => setStorage(value, row, storageDistributionRow)}
                              errorText={storageDistributionRow.storageId ? '' : includes(storageDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : storageDistributionRow.errors}
                              items={farmStorages}
                              variant='outlined'
                              size='small'
                            />
                          </div>
                          <div className='col-md-3' style={{paddingLeft: '10px', paddingRight: '10px'}}>
                            <CommonTextField
                              id="tonnage"
                              label={tonnageLabel}
                              value={storageDistributionRow.tonnage}
                              onChange={(event) => setStorageDistributionTonnage(event.target.value, row, storageDistributionRow)}
                              helperText={storageDistributionRow.tonnage && !includes(storageDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : storageDistributionRow.errors}
                              onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
                              variant='outlined'
                              size='small'
                            />
                          </div>
                          <div className='col-md-3' style={{paddingLeft: '10px', paddingRight: '0px'}}>
                            <CommonTextField
                              id="availableTonnage"
                              label={`Available ${tonnageLabel}`}
                              value={get(storageDistributionRow, 'availableTonnage') || 0}
                              variant='outlined'
                              size='small'
                              lockIconStyle={{ top: '10px' }}
                              disabled
                            />
                          </div>
                          <div className='col-md-1' style={{paddingLeft: '20px', paddingRight: '0px'}}>
                          <Button
                              className='btn-minus'
                              mini
                              id='storageDistributions'
                              variant='fab'
                              secondary={true}
                              onClick={() => handleRemoveStorageDistributions(row, storageDistributionRow)}
                              disabled={storageDistribution.length === 1}
                            >
                              <ContentRemove />
                            </Button>
                          </div>
                        </div>
                          {index === storageDistribution.length - 1 &&
                            <Button
                              id='storages'
                              variant='outlined'
                              onClick={() => addStorageDistribution(row)}
                              style={{ float: 'left', marginTop: '15px', marginBottom: '30px' }}
                            >Add Storage</Button>
                          }
                        </React.Fragment>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
          }
          { props.isEdit && props.regradingStock &&
              <CommonListingButton
                showDownLoadIcon
                title='Download PDF'
                name='Download PDF'
                variant='outlined'
                style={{marginLeft: '0px', marginRight: '10px'}}
                defaultHandler={initiateDownloadPdf}
              />
          }
          <div style={{float: "right", marginTop: "50px"}}>
          <Button type='button' onClick={props.toggleDialog} variant='outlined' color='default'>
            Cancel
          </Button>
          { props.isEdit && props.regradingStock &&
            <Button type='button'
              variant='outlined'
              onClick={handleDelete}
              disabled={props.regradingStock && isWarehouseInvoiced}
            >
              Delete
            </Button>
          }
          <Button style={{marginLeft: '5px'}} type='button' onClick={handleSubmit} color='primary' variant='outlined' disabled={isEmpty(allCompanies) || (props.regradingStock && isWarehouseInvoiced) || (manageOwnership && isEmpty(storagesLoads) || (manageStorages && isEmpty(stockLoads)))}>
            Save
          </Button>
          </div>
        </SideDrawer>
      {showEmailDialog &&
        <CustomEmailDialog
          parties={getEmailPopupParties()}
          title="Email PDF copies to"
          partyEmails={getPartyEmails()}
          partyContacts={partyContacts}
          subject={getEmailSubject()}
          noBody
          open={showEmailDialog}
          onClose={closeEmailDialog}
          disableAcceptanceRequired
          footer={getFootertext()}
        />
      }
    </div>
  );
};

export default UpdateStorageFilters;
